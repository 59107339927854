import { type IIconProps } from "./types";

const ArrowLeftIcon = ({ color = "#2A3A65", ...props }: IIconProps) => {
  return (
    <svg
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.94116 0.880981L0.505127 7.27698L6.94116 13.672"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
