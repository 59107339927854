import { useQuery } from "react-query";

import { queryKeys } from "../../data";
import { detailsItemGetRequest } from "../../requests/items";
import { type IDetailsItemGetReq } from "../../requests/types";

const useDetailsItemQuery = (params: IDetailsItemGetReq) => {
  return useQuery(
    [queryKeys.detailsItem, params.id.toString()],
    async () => await detailsItemGetRequest(params)
  );
};

export default useDetailsItemQuery;
