import { useQuery } from "react-query";

import { localStorageKeys, queryKeys } from "../../data";
import { settingsUserGet } from "../../requests";
import { type IReqGet } from "../../requests/types";

const useSettingUsersQuery = (req: IReqGet) => {
  return useQuery(queryKeys.users, async () => await settingsUserGet(req), {
    enabled:
      typeof localStorage.getItem(localStorageKeys.apiAuthorization) ===
      "string",
  });
};

export default useSettingUsersQuery;
