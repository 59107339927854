import { type IIconProps } from "./types";

const OnlineUsersIcon = ({ color = "#ffffff", ...props }: IIconProps) => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4554 16.0403C10.0038 15.6671 5.53887 16.5836 1.59418 18.6802C1.26714 18.854 0.993573 19.1135 0.802795 19.4309C0.612018 19.7484 0.511226 20.1117 0.51123 20.4821V25.0021C0.51123 25.285 0.566914 25.5651 0.675171 25.8264C0.783428 26.0878 0.942118 26.3253 1.14215 26.5253C1.34218 26.7253 1.57965 26.884 1.841 26.9923C2.10236 27.1005 2.38252 27.1562 2.66541 27.1562H14.3896C12.3036 26.2297 10.5642 24.6668 9.42053 22.6914C9.23089 22.3636 9.13104 21.9915 9.13104 21.6128C9.13104 21.234 9.23089 20.8619 9.42053 20.5341C10.5765 18.5374 12.3408 16.9628 14.4554 16.0403Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2276 0.994141C11.9495 0.994139 10.7 1.37316 9.63724 2.08327C8.57449 2.79338 7.74619 3.80268 7.25706 4.98355C6.76792 6.16442 6.63996 7.46382 6.88932 8.71743C7.13868 9.97104 7.75414 11.1226 8.65794 12.0264C9.56174 12.9302 10.7132 13.5456 11.9668 13.795C13.2204 14.0444 14.5198 13.9164 15.7007 13.4272C16.8816 12.9381 17.8909 12.1098 18.6011 11.047C19.3112 9.98428 19.6902 8.73482 19.6902 7.45665C19.6902 5.74269 19.0093 4.09892 17.7973 2.88696C16.5854 1.67501 14.9416 0.994141 13.2276 0.994141Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7761 21.3044C19.7152 21.3044 19.6557 21.3225 19.6051 21.3563C19.5545 21.3901 19.5151 21.4382 19.4918 21.4944C19.4685 21.5506 19.4624 21.6125 19.4742 21.6722C19.4861 21.7319 19.5154 21.7867 19.5585 21.8298C19.6015 21.8728 19.6563 21.9021 19.716 21.914C19.7757 21.9259 19.8376 21.9198 19.8938 21.8965C19.95 21.8732 19.9981 21.8338 20.0319 21.7831C20.0657 21.7325 20.0838 21.673 20.0838 21.6122C20.0838 21.5718 20.0758 21.5317 20.0604 21.4944C20.0449 21.4571 20.0222 21.4231 19.9937 21.3946C19.9651 21.366 19.9312 21.3433 19.8939 21.3279C19.8565 21.3124 19.8165 21.3044 19.7761 21.3044ZM17.6219 21.6122C17.6219 21.1861 17.7482 20.7696 17.9849 20.4154C18.2216 20.0611 18.5581 19.785 18.9517 19.622C19.3453 19.4589 19.7784 19.4163 20.1963 19.4994C20.6142 19.5825 20.9981 19.7877 21.2993 20.0889C21.6006 20.3902 21.8057 20.7741 21.8889 21.1919C21.972 21.6098 21.9293 22.0429 21.7662 22.4365C21.6032 22.8302 21.3271 23.1666 20.9728 23.4033C20.6186 23.64 20.2021 23.7663 19.7761 23.7663C19.4932 23.7664 19.213 23.7106 18.9517 23.6024C18.6903 23.4941 18.4528 23.3355 18.2528 23.1354C18.0528 22.9354 17.8941 22.6979 17.7858 22.4366C17.6776 22.1752 17.6219 21.8951 17.6219 21.6122Z"
        fill={color}
      />
      <path
        d="M19.4683 21.6124C19.4683 21.5516 19.4863 21.4921 19.5201 21.4415C19.554 21.3909 19.602 21.3514 19.6583 21.3281C19.7145 21.3048 19.7764 21.2987 19.8361 21.3106C19.8958 21.3225 19.9506 21.3518 19.9936 21.3948C20.0366 21.4379 20.0659 21.4927 20.0778 21.5524C20.0897 21.6121 20.0836 21.674 20.0603 21.7302C20.037 21.7864 19.9976 21.8345 19.947 21.8683C19.8964 21.9021 19.8369 21.9202 19.776 21.9202C19.7356 21.9202 19.6956 21.9122 19.6583 21.8968C19.6209 21.8813 19.587 21.8586 19.5584 21.83C19.5298 21.8015 19.5072 21.7675 19.4917 21.7302C19.4762 21.6929 19.4683 21.6529 19.4683 21.6124Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4791 21.6124C14.184 22.6549 15.1337 23.5086 16.2451 24.0988C17.3565 24.6889 18.5958 24.9975 19.8542 24.9975C21.1126 24.9975 22.3518 24.6889 23.4632 24.0988C24.5746 23.5086 25.5244 22.6549 26.2292 21.6124C25.5244 20.5699 24.5746 19.7162 23.4632 19.1261C22.3518 18.5359 21.1126 18.2273 19.8542 18.2273C18.5958 18.2273 17.3565 18.5359 16.2451 19.1261C15.1337 19.7162 14.184 20.5699 13.4791 21.6124ZM11.5911 21.1502C12.4288 19.7001 13.6334 18.4959 15.0839 17.6588C16.5343 16.8216 18.1795 16.3809 19.8542 16.3809C21.5289 16.3809 23.1741 16.8216 24.6246 17.6588C26.075 18.4959 27.2796 19.7001 28.1173 21.1502C28.1985 21.2907 28.2413 21.4501 28.2413 21.6124C28.2413 21.7747 28.1985 21.9341 28.1173 22.0746C27.2796 23.5248 26.075 24.7289 24.6246 25.5661C23.1741 26.4032 21.5289 26.844 19.8542 26.844C18.1795 26.844 16.5343 26.4032 15.0839 25.5661C13.6334 24.7289 12.4288 23.5248 11.5911 22.0746C11.5098 21.9342 11.467 21.7747 11.467 21.6124C11.467 21.4501 11.5099 21.2907 11.5911 21.1502Z"
        fill={color}
      />
    </svg>
  );
};

export default OnlineUsersIcon;
