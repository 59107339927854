import { forwardRef } from "react";

import styles from "./styles.module.scss";
import { type IChooseBoxProps } from "./types";

const ChooseBox = forwardRef(
  (
    {
      className = "",
      activeItemsId,
      handleClick,
      items,
      ...props
    }: IChooseBoxProps,
    ref
  ) => {
    return (
      <div
        className={`${className} ${styles.wrapper}`}
        ref={ref as any}
        {...props}
      >
        {items.map((item, index) => (
          <div
            className={`${styles.item} ${
              activeItemsId?.some((i) => i === item.id) ? styles.itemActive : ""
            }`}
            key={item.id}
            onClick={() => {
              handleClick(item.id, index);
            }}
          >
            {item.name}
          </div>
        ))}
      </div>
    );
  }
);

export default ChooseBox;
