import { useMutation } from "react-query";

import { finishItemPostRequest } from "../../requests";
import { type IItemFinishPostReq } from "../../requests/types";

const useFinishItemMutation = () => {
  return useMutation(
    async (payload: IItemFinishPostReq) => await finishItemPostRequest(payload)
  );
};

export default useFinishItemMutation;
