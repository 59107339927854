import { type IIconProps } from "./types";

const CloseIcon = ({ color = "#fff", ...props }: IIconProps) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.848216 1.27574C1.18315 0.92538 1.64343 0.722381 2.128 0.711407C2.61257 0.700432 3.08175 0.882369 3.4322 1.2172C3.44856 1.23294 3.46444 1.24885 3.48017 1.26518L7.34211 5.12712L11.225 1.24412C11.5685 0.91108 12.0292 0.72642 12.5075 0.730083C12.9859 0.733747 13.4435 0.92539 13.7818 1.26365C14.1201 1.60192 14.3117 2.05964 14.3154 2.53801C14.319 3.01637 14.1344 3.47701 13.8013 3.82042L9.91841 7.70335L13.7779 11.5628C14.1181 11.9077 14.308 12.373 14.3063 12.8574C14.3047 13.3418 14.1115 13.8059 13.769 14.1484C13.4265 14.4909 12.9624 14.6841 12.478 14.6858C11.9936 14.6874 11.5282 14.4974 11.1833 14.1573L7.32392 10.2979L3.44099 14.1809C3.09759 14.5139 2.637 14.6985 2.15864 14.6949C1.68028 14.6912 1.22249 14.4996 0.884227 14.1613C0.545965 13.823 0.354321 13.3653 0.350658 12.8869C0.346994 12.4086 0.531654 11.948 0.864696 11.6046L4.74763 7.7216L0.885692 3.85966C0.713532 3.69249 0.575972 3.49302 0.480907 3.27269C0.385841 3.05235 0.335108 2.81542 0.331615 2.57548C0.328121 2.33554 0.37191 2.09726 0.460521 1.87425C0.549131 1.65124 0.680997 1.44785 0.848216 1.27574Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseIcon;
