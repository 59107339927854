import Typography from "../typography";
import styles from "./styles.module.scss";
import { type ICountBadgeProps } from "./types";

const CountBadge = ({ count, notShowOnZeroValue, color }: ICountBadgeProps) => {
  if (notShowOnZeroValue && count === 0) {
    return null;
  }
  return (
    <Typography
      variant="text1"
      color="dark1"
      component="span"
      className={styles.wrapper}
      style={{
        backgroundColor: `var(--color-${color})`,
      }}
    >
      {count}
    </Typography>
  );
};

export default CountBadge;
