import { type IGroupUser } from "../requests/types";

const handleSortUserByOnlineStatus = (users: IGroupUser[]): IGroupUser[] => {
  const onlineUsers: IGroupUser[] = [];
  const offlineUsers: IGroupUser[] = [];

  users.forEach((user) => {
    if (user.user.online) {
      onlineUsers.push(user);
    } else {
      offlineUsers.push(user);
    }
  });

  return [...onlineUsers, ...offlineUsers];
};

export default handleSortUserByOnlineStatus;
