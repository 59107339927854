import { toast } from "react-toastify";

import { Avatar, Button, CountBadge } from "../../components";
import { useGlobalContext } from "../../context";
import {
  ChatIcon,
  CheckIcon,
  NotificationIcon,
  SearchBoxIcon,
} from "../../icons";
import {
  useChatUsersQuery,
  useSettingUsersQuery,
  useUserNotificationsQuery,
  useUserQuery,
} from "../../queries";
import styles from "./styles.module.scss";

const SmallMainSidebar = () => {
  const { handleChangeSidebarType } = useGlobalContext();
  const usersQuery = useSettingUsersQuery({ limit: 10000, offset: 0 });
  const userQuery = useUserQuery();
  const userNotificationsQuery = useUserNotificationsQuery({
    limit: 10000,
    offset: 0,
  });
  const chatUsersQuery = useChatUsersQuery();

  const findUser = usersQuery.data?.response?.users?.find(
    (u) => u.id === userQuery?.data?.user?.id
  );

  const unseenChatsCount =
    chatUsersQuery?.data?.response?.unread_chat_count ?? 0;
  const unseenNotificationsCount =
    userNotificationsQuery?.data?.response?.unseen_notifications_count ?? 0;

  return (
    <div className={styles.wrapper}>
      <Avatar profile={userQuery?.data?.user?.profile} />

      <div
        className={styles.searchBox}
        onClick={() => {
          handleChangeSidebarType("main");

          setTimeout(() => {
            document.getElementById("search-field")?.focus();
          }, 50);
        }}
      >
        <SearchBoxIcon />
      </div>

      <div className={styles.buttons}>
        <Button
          type="button"
          buttonSize="large2"
          onClick={() => {
            if (
              !Array.isArray(findUser?.groups) ||
              (findUser?.groups?.length as number) < 1
            ) {
              toast("برای ساخت مورد جدید باید در یک گروه عضو شوید", {
                type: "info",
                toastId: "create-new-item",
              });
              return;
            }

            handleChangeSidebarType("create-item");
          }}
        >
          <CheckIcon />
          مورد
        </Button>

        {findUser?.can_make_notification && (
          <Button
            type="button"
            buttonSize="large2"
            onClick={() => {
              if (
                !Array.isArray(findUser?.groups) ||
                (findUser?.groups?.length as number) < 1
              ) {
                toast("برای ساخت اعلان جدید باید در یک گروه عضو شوید", {
                  type: "info",
                  toastId: "create-new-item",
                });
                return;
              }

              handleChangeSidebarType("create-announcement");
            }}
          >
            <CheckIcon />
            اعلان
          </Button>
        )}
      </div>

      <div className={styles.features}>
        <div
          className={styles.item}
          onClick={() => {
            handleChangeSidebarType("notifications");
          }}
        >
          <CountBadge
            notShowOnZeroValue
            color="gold1"
            count={unseenNotificationsCount}
          />

          <NotificationIcon />
        </div>

        <div
          className={styles.item}
          onClick={() => {
            handleChangeSidebarType("chats");
          }}
        >
          <CountBadge
            color="gold1"
            count={unseenChatsCount}
            notShowOnZeroValue
          />

          <ChatIcon />
        </div>
      </div>
    </div>
  );
};

export default SmallMainSidebar;
