import { useEffect } from "react";
import { useQueryClient } from "react-query";

import { ChatSoundSrc, ItemSoundSrc, NotificationSoundSrc } from "../assets";
import { socketConfig } from "../configs";
import { useGlobalContext } from "../context";
import { queryKeys } from "../data";
import { useUserQuery } from "../queries";

const useSocketInitializer = () => {
  const userQuery = useUserQuery();
  const queryClient = useQueryClient();
  const { activeChatId, sidebarType } = useGlobalContext();
  const userId = userQuery.data?.user?.id;

  const handlePlaySound = (sound: string) => {
    document.body.click();

    const audio = new Audio(sound);
    void audio.play();
  };

  useEffect(() => {
    if (typeof userId !== "number") return;

    const pusher = socketConfig();

    const onlineUsersSubscription = pusher.subscribe("online-users");
    const chatMessagesSubscription = pusher.subscribe(`chat-message.${userId}`);
    const userNotificationsSubscription = pusher.subscribe(
      `user-notification.${userId}`
    );
    const binds = {
      onlineEvents: "App\\Events\\OnlineUserEvent",
      newMessage: "App\\Events\\NewMessageEvent",
      sendMessage: "App\\Events\\SeenMessageEvent",
      userNotification: "App\\Events\\UserNotificationEvent",
      privateNotification: `App\\Events\\PrivateUserNotificationEvent`,
    };

    onlineUsersSubscription.bind(binds.onlineEvents, () => {
      void queryClient.refetchQueries({ queryKey: queryKeys.user });
      void queryClient.refetchQueries({ queryKey: queryKeys.users });
      void queryClient.refetchQueries({ queryKey: queryKeys.chatUsers });
    });

    chatMessagesSubscription.bind(binds.newMessage, (data: any) => {
      handlePlaySound(ChatSoundSrc);

      if (
        activeChatId === data?.chat?.sender?.id &&
        sidebarType === "details-chat"
      ) {
        void queryClient.refetchQueries({
          queryKey: [queryKeys.chatMessages, data.chat.sender.id],
        });
      }

      void queryClient.refetchQueries({
        queryKey: queryKeys.chatUsers,
      });
    });

    chatMessagesSubscription.bind(binds.sendMessage, (data: any) => {
      if (activeChatId === data.sender_id && sidebarType === "details-chat") {
        void queryClient.refetchQueries({
          queryKey: [queryKeys.chatMessages, data.sender_id],
        });
      }

      void queryClient.refetchQueries({
        queryKey: queryKeys.chatUsers,
      });
    });

    userNotificationsSubscription.bind(binds.userNotification, () => {
      void queryClient.refetchQueries({
        queryKey: queryKeys.userNotifications,
      });
    });

    userNotificationsSubscription.bind(
      binds.privateNotification,
      (data: any) => {
        if (
          data.user_notification.type === "مورد" &&
          data.user_notification.status.key === "waiting"
        ) {
          handlePlaySound(ItemSoundSrc);
        }

        if (data.user_notification.type === "اعلان") {
          handlePlaySound(NotificationSoundSrc);
        }

        void queryClient.refetchQueries({
          queryKey: queryKeys.userNotifications,
        });
      }
    );

    return () => {
      onlineUsersSubscription.unbind(binds.onlineEvents);
      chatMessagesSubscription.unbind(binds.newMessage);
      chatMessagesSubscription.unbind(binds.sendMessage);
      userNotificationsSubscription.unbind(binds.userNotification);
      userNotificationsSubscription.unbind(binds.privateNotification);

      pusher.unsubscribe("online-users");
      pusher.unsubscribe("chat-message");
      pusher.unsubscribe("online-users");
    };
  }, [userId, activeChatId, sidebarType]);
};

export default useSocketInitializer;
