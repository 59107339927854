import { useQuery } from "react-query";

import { queryKeys } from "../../data";
import { subjectsGetRequest } from "../../requests";
import { type ISubjectsGetReq } from "../../requests/types";

export const useSubjectsQuery = (data: ISubjectsGetReq) => {
  return useQuery(
    [queryKeys.subjects, data.type],
    async () => await subjectsGetRequest(data)
  );
};

export default useSubjectsQuery;
