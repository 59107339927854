import { apiUrls } from "../data";
import requestService from "../services/request";
import { type IUploadFilePostReq, type IUploadFilePostRes } from "./types";

export const uploadFilePostReq = async (
  payload: IUploadFilePostReq
): Promise<IUploadFilePostRes> => {
  const formData = new FormData();
  formData.append("file", payload.file);
  formData.append("type", payload.type);

  const { data } = await requestService.post(apiUrls.uploadFile, formData);

  return data;
};
