const normalizeFilteringField = (value: any): any => {
  if (value === "") return undefined;
  if (
    Array.isArray(value) &&
    value.filter((i) => typeof i !== "undefined").length < 1
  ) {
    return undefined;
  }
  if (value === null) return undefined;

  return value;
};

export default normalizeFilteringField;
