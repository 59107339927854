import { forwardRef } from "react";

import styles from "./styles.module.scss";
import { type ICardProps } from "./types";

const Card = forwardRef(
  ({ className = "", children, ...props }: ICardProps, ref) => {
    return (
      <div
        className={`${className} ${styles.card}`}
        ref={ref as any}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export default Card;
