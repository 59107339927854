import { ErrorMessage } from "@hookform/error-message";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { useGlobalContext } from "../../context";
import { CheckIcon } from "../../icons";
import Button from "../button";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type ICustomerListProps } from "./types";

const CustomerList = ({ className = "", ...props }: ICustomerListProps) => {
  const methods = useFormContext();
  const { handleSetCustomers } = useGlobalContext();
  const key = "customer_phones";
  const { fields, append } = useFieldArray({
    control: methods.control,
    name: key,
  });

  useEffect(() => {
    return () => {
      handleSetCustomers([]);
    };
  }, []);

  return (
    <div className={`${styles.wrapper} ${className}`} {...props}>
      <div className={styles.fields}>
        {fields.map((field, index) => {
          const fieldKey = `${key}.${index}.phoneNumber`;
          const { onChange, ...register } = methods.register(fieldKey);

          return (
            <div key={field.id}>
              <input
                {...register}
                style={{ direction: "ltr" }}
                onChange={(event) => {
                  void onChange(event);

                  const customers = methods.watch(key) as any;

                  const filteredCustomers = customers
                    .map((c: any) => c.phoneNumber as string)
                    .filter(
                      (pn: any) => typeof pn === "string" && pn.trim() !== ""
                    );

                  handleSetCustomers(filteredCustomers);
                }}
              />

              <ErrorMessage
                name={`${key}.${index}.phoneNumber`}
                errors={methods.formState.errors}
                render={({ message }) => (
                  <Typography
                    variant="text2"
                    className={styles.error}
                    color="danger1"
                  >
                    {message}
                  </Typography>
                )}
              />
            </div>
          );
        })}
      </div>

      <div>
        <Button
          buttonSize="medium1"
          type="button"
          color="info1"
          onClick={() => {
            append({ phoneNumber: "" });
            append({ phoneNumber: "" });
          }}
        >
          <CheckIcon width={15} height={15} />
        </Button>
      </div>
    </div>
  );
};

export default CustomerList;
