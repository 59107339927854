import { forwardRef, useState } from "react";
import { BsChevronDown } from "react-icons/bs";

import Card from "../card";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type ICollapseCard } from "./types";

const CollapseCard = forwardRef(
  (
    {
      className = "",
      children,
      contentClassName = "",
      ...props
    }: ICollapseCard,
    ref
  ) => {
    const [isShow, setIsShow] = useState<boolean>(false);

    return (
      <Card
        className={`${className} ${styles.wrapper}`}
        ref={ref as any}
        {...props}
      >
        <div
          className={styles.header}
          onClick={() => {
            setIsShow((prevState) => !prevState);
          }}
        >
          <Typography variant="subtitle1">نمایش فیلتر ها</Typography>

          <BsChevronDown
            size={20}
            style={{ transform: isShow ? "rotate(180deg)" : "" }}
          />
        </div>

        <div
          className={`${styles.content} ${
            isShow ? styles.contentShow : ""
          } ${contentClassName}`}
        >
          {children}
        </div>
      </Card>
    );
  }
);

export default CollapseCard;
