import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";

import { Button, TextEditor, Typography } from "../../components";
import { useGlobalContext } from "../../context";
import { queryKeys } from "../../data";
import { useTextEditorUploader } from "../../hooks";
import { useAnswerItemMutation } from "../../queries";
import styles from "./styles.module.scss";

const yupSchema = yup.object({
  file1: yup.mixed().nullable(),
  file2: yup.mixed().nullable(),
  file3: yup.mixed().nullable(),
  file4: yup.mixed().nullable(),
  voice: yup.mixed().nullable(),
  description: yup.string().required("توضیحات را وارد کنید"),
});

const AnswerItemSidebar = () => {
  const { handleChangeSidebarType, handleSetActiveItem, activeItem } =
    useGlobalContext();
  const [textEditorHeight, setTextEditorHeight] = useState<number>(233);
  useState<boolean>(false);
  const queryClient = useQueryClient();
  const answerItemMutation = useAnswerItemMutation();

  const methods = useForm({
    defaultValues: {
      description: "",
      file1: null,
      file2: null,
      file3: null,
      file4: null,
      voice: null,
    },
    resolver: yupResolver(yupSchema),
  });
  const { handleUpload, isUploading } = useTextEditorUploader(methods as any);

  const handleSubmit = async () => {
    const values = methods.getValues();

    const resultUploader = await handleUpload();

    answerItemMutation.mutate(
      {
        item_id: activeItem,
        description: values.description,
        file_1: resultUploader?.file_1?.token ?? null,
        file_2: resultUploader?.file_2?.token ?? null,
        file_3: resultUploader?.file_3?.token ?? null,
        file_4: resultUploader?.file_4?.token ?? null,
        voice: resultUploader?.voice?.token ?? null,
      },
      {
        onSuccess() {
          toast("پاسخ با موفقیت ثبت شد", { type: "success" });

          void queryClient.refetchQueries({ queryKey: queryKeys.items });
          void queryClient.refetchQueries({
            queryKey: [queryKeys.detailsItem, activeItem.toString()],
          });

          handleSetActiveItem(-1);

          handleChangeSidebarType("main");
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <div
            style={{
              height: `calc(100vh - 15px - ${textEditorHeight}px)`,
              overflow: "auto",
            }}
          >
            <div className={styles.header}>
              <Typography component="h3" variant="title">
                پاسخ
              </Typography>

              <Button
                buttonSize="medium2"
                type="button"
                color="secondary1"
                radius="high"
                onClick={() => {
                  handleSetActiveItem(-1);
                  handleChangeSidebarType("main");
                }}
              >
                بستن
              </Button>
            </div>
          </div>

          <div className={styles.textEditor}>
            <TextEditor
              height={textEditorHeight}
              setHeight={setTextEditorHeight}
              isLoading={answerItemMutation.isLoading || isUploading}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AnswerItemSidebar;
