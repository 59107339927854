import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

import { type IGlobalContext, type IGlobalState } from "./types";

const initialState: IGlobalState = {
  isShowSidebar: false,
  isShowMenubar: false,
  isLargeSidebar: false,
  activeItem: -1,
  activeAnnouncement: -1,
  activeChatId: -1,
  sidebarType: "small-main",
  activeAnnouncementSubjectEditId: -1,
  activeGroupEditId: -1,
  activeItemSubjectEditId: -1,
  activeUserEditId: -1,
  customers: [],
};

const initialContext: IGlobalContext = {
  ...initialState,
  handleSetActiveItem() {},
  handleHideMenubar() {},
  handleHideSidebar() {},
  handleShowMenubar() {},
  handleShowSidebar() {},
  handleLargeSidebar() {},
  handleSmallSidebar() {},
  handleSetActiveAnnouncement() {},
  handleChangeSidebarType() {},
  handleSetActiveChatId() {},
  handleSetCustomers() {},
  handleSetActiveGroupEditItem() {},
  handleSetActiveSubjectAnnouncementEditItem() {},
  handleSetActiveSubjectItemEditItem() {},
  handleSetActiveUserEditItem() {},
};

const context = createContext(initialContext);

const GlobalContextProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState<IGlobalState>(initialState);

  useEffect(() => {
    handleManageSidebarType();

    window.addEventListener("resize", handleManageSidebarType);

    return () => {
      window.removeEventListener("resize", handleManageSidebarType);
    };
  }, [state]);

  function handleManageSidebarType() {
    if (window.innerWidth > 991.98 || state.sidebarType !== "small-main") {
      return;
    }

    setState((prevState) => ({ ...prevState, sidebarType: "main" }));
  }

  const handleHideMenubar = () => {
    setState((prevState) => ({ ...prevState, isShowMenubar: false }));
  };

  const handleHideSidebar = () => {
    setState((prevState) => ({ ...prevState, isShowSidebar: false }));
  };

  const handleShowMenubar = () => {
    setState((prevState) => ({ ...prevState, isShowMenubar: true }));
  };

  const handleShowSidebar = () => {
    setState((prevState) => ({ ...prevState, isShowSidebar: true }));
  };

  const handleLargeSidebar = () => {
    setState((prevState) => ({ ...prevState, isLargeSidebar: true }));
  };

  const handleSmallSidebar = () => {
    setState((prevState) => ({ ...prevState, isLargeSidebar: false }));
  };

  const handleChangeSidebarType = (type: IGlobalState["sidebarType"]) => {
    setState((prevState) => ({ ...prevState, sidebarType: type }));
  };

  const handleSetActiveItem = (activeItem: number) => {
    setState((prevState) => ({ ...prevState, activeItem }));
  };

  const handleSetActiveAnnouncement = (activeAnnouncement: number) => {
    setState((prevState) => ({ ...prevState, activeAnnouncement }));
  };

  const handleSetActiveChatId = (activeChatId: number) => {
    setState((prevState) => ({ ...prevState, activeChatId }));
  };

  const handleSetCustomers = (customers: string[]) => {
    setState((prevState) => ({ ...prevState, customers }));
  };

  const handleSetActiveGroupEditItem = (activeId: number) => {
    setState((prevState) => ({ ...prevState, activeGroupEditId: activeId }));
  };

  const handleSetActiveSubjectAnnouncementEditItem = (activeId: number) => {
    setState((prevState) => ({
      ...prevState,
      activeAnnouncementSubjectEditId: activeId,
    }));
  };

  const handleSetActiveSubjectItemEditItem = (activeId: number) => {
    setState((prevState) => ({
      ...prevState,
      activeItemSubjectEditId: activeId,
    }));
  };

  const handleSetActiveUserEditItem = (activeId: number) => {
    setState((prevState) => ({
      ...prevState,
      activeUserEditId: activeId,
    }));
  };

  return (
    <context.Provider
      value={{
        ...state,
        handleHideMenubar,
        handleHideSidebar,
        handleShowMenubar,
        handleShowSidebar,
        handleLargeSidebar,
        handleSmallSidebar,
        handleChangeSidebarType,
        handleSetActiveItem,
        handleSetActiveAnnouncement,
        handleSetActiveChatId,
        handleSetCustomers,
        handleSetActiveGroupEditItem,
        handleSetActiveSubjectAnnouncementEditItem,
        handleSetActiveSubjectItemEditItem,
        handleSetActiveUserEditItem,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useGlobalContext = () => {
  const globalContext = useContext(context);

  return globalContext;
};

export default GlobalContextProvider;
