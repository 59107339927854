import { forwardRef } from "react";

import { ArrowLeftIcon } from "../../icons";
import Avatar from "../avatar";
import CountBadge from "../countBadge";
import OnlineStatus from "../onlineStatus";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IChatBoxProps } from "./types";

const ChatBox = forwardRef(
  (
    {
      className = "",
      description,
      isOnline,
      status,
      username,
      profile,
      unseenMessagesCount,
      ...props
    }: IChatBoxProps,
    ref
  ) => {
    return (
      <div
        className={`${className} ${styles.wrapper}`}
        ref={ref as any}
        {...props}
      >
        <div>
          <Avatar profile={profile} />

          <div className={styles.user}>
            <Typography component="h5" variant="subtitle2">
              {username}
            </Typography>

            <Typography variant="body1">{description}</Typography>
          </div>
        </div>

        <div>
          <OnlineStatus
            isOnline={isOnline}
            className={styles.onlineStatus}
            text={status}
          />

          <span className={styles.unseen}>
            <CountBadge
              notShowOnZeroValue
              color="gold1"
              count={unseenMessagesCount}
            />
          </span>

          <ArrowLeftIcon />
        </div>
      </div>
    );
  }
);

export default ChatBox;
