import { useMutation } from "react-query";

import { userDeleteRequest } from "../../requests";

const useUserDeleteMutation = () => {
  return useMutation(
    async (variables: { id: number }) => await userDeleteRequest(variables.id)
  );
};

export default useUserDeleteMutation;
