import { useFieldArray, useFormContext } from "react-hook-form";

import TextField from "../textField";

const AttrFields = () => {
  const methods = useFormContext();
  const { fields } = useFieldArray({
    control: methods.control,
    name: "attrs",
  });

  return (
    <>
      {fields?.map((attr: any, index) => (
        <div key={attr.key} style={{ marginTop: 20 }}>
          <TextField
            id={attr.key}
            name={`attrs.${index}.value`}
            placeholder="اطلاعات مورد نیاز را وارد کنید."
            label={attr.name}
          />
        </div>
      ))}
    </>
  );
};

export default AttrFields;
