import { forwardRef, useState } from "react";

import { useGlobalContext } from "../../context";
import { usePagination } from "../../hooks";
import { useSubjectDeleteMutation, useSubjectsQuery } from "../../queries";
import Button from "../button";
import DeleteModal from "../deleteModal";
import Pagination from "../pagination";
import Select from "../select";
import Table from "../table";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IItemSettingTable } from "./types";

const ItemSettingTable = forwardRef(
  ({ className = "", items, ...props }: IItemSettingTable, ref) => {
    const deleteMutation = useSubjectDeleteMutation();
    const subjectsQuery = useSubjectsQuery({ type: "item" });
    const { handleSetActiveSubjectItemEditItem } = useGlobalContext();
    const { activePage, handleSetLimit, handleSetOffset, limit, offset } =
      usePagination();

    const [deleteId, setDeleteId] = useState<number>(-1);

    const thList = [
      "شناسه",
      "نام نوع",
      "وضعیت",
      "گروه",
      "باکس اختصاصی",
      "عملیات",
    ];

    return (
      <div>
        <DeleteModal
          title="حذف مورد"
          description="آیا از حذف مورد   مطمئن هستید"
          isOpened={deleteId !== -1}
          handleClose={() => {
            setDeleteId(-1);
          }}
          isLoading={deleteMutation.isLoading}
          handleSubmit={() => {
            deleteMutation.mutate(
              { id: deleteId },
              {
                onSuccess() {
                  void subjectsQuery.refetch();

                  setDeleteId(-1);
                },
              }
            );
          }}
        />

        <Table
          thList={thList}
          className={`${styles.wrapper} ${className}`}
          ref={ref}
          {...props}
        >
          {items
            .slice(offset * limit, limit * (offset + 1))
            .map((item, index) => (
              <tr key={item.id.toString()}>
                <Typography variant="body1" component="td">
                  {item.id}
                </Typography>

                <Typography variant="body1" component="td">
                  {item.name}
                </Typography>

                <Typography variant="body1" component="td">
                  {item.enabled ? "فعال" : "غیر فعال"}
                </Typography>

                <Typography variant="body1" component="td">
                  <Select
                    options={item.groups.map((g) => ({ label: g, value: g }))}
                    placeholder="گروه ها"
                    value={item.groups.length >= 1 ? item.groups[0] : undefined}
                    position={index <= 1 ? "bottom" : "top"}
                    boxClassName={styles.select}
                  />
                </Typography>

                <Typography variant="body1" component="td">
                  {item.attributesCount}
                </Typography>

                <Typography
                  variant="body1"
                  component="td"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    type="button"
                    buttonSize="small1"
                    color="secondary1"
                    style={{ minWidth: "80px", height: "40px" }}
                    onClick={() => {
                      handleSetActiveSubjectItemEditItem(item.id);
                    }}
                  >
                    ویرایش
                  </Button>

                  <div style={{ width: 10 }} />

                  <Button
                    type="button"
                    buttonSize="small1"
                    color="pink1"
                    style={{ minWidth: "80px", height: "40px" }}
                    onClick={() => {
                      setDeleteId(item.id);
                    }}
                  >
                    حذف
                  </Button>
                </Typography>
              </tr>
            ))}
        </Table>

        <div
          style={{
            marginTop: "15px",
          }}
        >
          <Pagination
            activePage={activePage}
            handleChange={handleSetOffset}
            handleChangeLimit={handleSetLimit}
            limit={limit}
            totalItems={items.length}
          />
        </div>
      </div>
    );
  }
);

export default ItemSettingTable;
