import { apiUrls } from "../data";
import requestService from "../services/request";
import {
  type IDetailsNotificationGetReq,
  type IDetailsNotificationGetRes,
  type INewNotificationPostReq,
  type INotificationCreatePostReq,
  type INotificationCreatePostRes,
  type INotificationSeenGetReq,
  type INotificationsGetReq,
  type INotificationsGetRes,
  type ISetExpirationTimeNotificationPostReq,
} from "./types";

export const notificationsGetRequest = async (
  params: INotificationsGetReq
): Promise<INotificationsGetRes> => {
  const { data } = await requestService.get(
    apiUrls.notifications.notificationsGet,
    {
      params,
    }
  );

  return data;
};

export const notificationCreatePostRequest = async (
  payload: INotificationCreatePostReq
): Promise<INotificationCreatePostRes> => {
  const { data } = await requestService.post(
    apiUrls.notifications.notificationCreate,
    payload
  );

  return data;
};

export const detailsNotificationGetRequest = async (
  payload: IDetailsNotificationGetReq
): Promise<IDetailsNotificationGetRes> => {
  const { data } = await requestService.get(
    apiUrls.notifications.detailsNotificationGet,
    {
      params: {
        notification_id: payload.notificationId,
      },
    }
  );

  return data;
};

export const newNotificationPostRequest = async (
  payload: INewNotificationPostReq
) => {
  const { data } = await requestService.post(
    apiUrls.notifications.newNotification,
    payload
  );

  return data;
};

export const setExpirationTimeNotificationPostRequest = async (
  payload: ISetExpirationTimeNotificationPostReq
) => {
  const { data } = await requestService.post(
    apiUrls.notifications.addExpiration,
    payload
  );

  return data;
};

export const notificationSeenGetRequest = async (
  payload: INotificationSeenGetReq
) => {
  const { data } = await requestService.post(
    apiUrls.notifications.addExpiration,
    payload
  );

  return data;
};
