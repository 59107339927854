import { forwardRef } from "react";

import styles from "./styles.module.scss";
import { type IButtonProps } from "./types";

const Button = forwardRef(
  (
    {
      children,
      color = "info1",
      radius = "medium",
      buttonSize,
      fullWidth,
      className = "",
      // loading,
      ...props
    }: IButtonProps,
    ref
  ) => {
    return (
      <button
        ref={ref as any}
        className={`${className} ${styles.button} ${
          fullWidth ? styles.buttonFullWidth : ""
        } ${styles[`button-color-${color}`]} ${
          styles[`button-size-${buttonSize}`]
        } ${styles[`button-radius-${radius}`]}`}
        {...props}
      >
        {children}
      </button>
    );
  }
);

export default Button;
