import { useMutation } from "react-query";

import { settingsUserCreatePost } from "../../requests";
import { type ISettingsUserCreateReq } from "../../requests/types";

export const useCreateUserMutation = () => {
  return useMutation(
    async (variables: ISettingsUserCreateReq) =>
      await settingsUserCreatePost(variables)
  );
};

export default useCreateUserMutation;
