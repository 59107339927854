import { ItemSettingTable } from "../../../../components";
import { Loading } from "../../../../layouts";
import { useSubjectsQuery } from "../../../../queries";

const Item = () => {
  const subjectsQuery = useSubjectsQuery({ type: "item" });

  const subjects = subjectsQuery?.data?.response?.subjects ?? [];

  if (subjectsQuery.isLoading) {
    return <Loading position="absolute" />;
  }
  if (subjects.length < 1) {
    return null;
  }
  return (
    <ItemSettingTable
      items={
        subjectsQuery.data?.response.subjects.map((item) => ({
          id: item.id,
          name: item.name,
          attributesCount: item.attributes.length,
          groups: item.groups.map((g) => g.name),
          enabled: item.is_active,
        })) ?? []
      }
    />
  );
};

export default Item;
