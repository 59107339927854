import { useMutation } from "react-query";

import { setExpirationItemPostRequest } from "../../requests";
import { type IExpirationItemPostReq } from "../../requests/types";

const useSetExpirationItemMutation = () => {
  return useMutation(
    async (variables: IExpirationItemPostReq) =>
      await setExpirationItemPostRequest(variables)
  );
};

export default useSetExpirationItemMutation;
