import { forwardRef } from "react";

import { ArrowLeftIcon, ArrowRightIcon } from "../../icons";
import Button from "../button";
import Select from "../select";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IPaginationProps } from "./types";

const Pagination = forwardRef(
  (
    {
      className = "",
      activePage,
      handleChange,
      totalItems,
      limit,
      handleChangeLimit,
      ...props
    }: IPaginationProps,
    ref
  ) => {
    const totalPages = Math.ceil(totalItems / limit);
    const isAccessPreviousPage = activePage * limit >= 1;
    const isAccessNextPage = totalPages !== activePage + 1;

    return (
      <div
        className={`${className} ${styles.wrapper}`}
        ref={ref as any}
        {...props}
      >
        <div className={styles.rightBox}>
          <Button
            type="button"
            buttonSize="small2"
            radius="circle"
            color="secondary1"
            className={styles.button}
            onClick={() => {
              if (!isAccessPreviousPage) return;

              handleChange(0);
            }}
            disabled={!isAccessPreviousPage}
          >
            <ArrowRightIcon />
            <ArrowRightIcon />
          </Button>

          <Button
            type="button"
            buttonSize="small2"
            radius="circle"
            color="secondary1"
            className={styles.button}
            onClick={() => {
              if (!isAccessPreviousPage) return;

              handleChange(activePage - 1);
            }}
            disabled={!isAccessPreviousPage}
          >
            <ArrowRightIcon />
          </Button>

          <Button
            type="button"
            buttonSize="small2"
            radius="circle"
            color="secondary1"
            className={styles.button}
            onClick={() => {
              if (!isAccessNextPage) return;

              handleChange(activePage + 1);
            }}
            disabled={!isAccessNextPage}
          >
            <ArrowLeftIcon />
          </Button>

          <Button
            type="button"
            buttonSize="small2"
            radius="circle"
            color="secondary1"
            className={styles.button}
            onClick={() => {
              if (!isAccessNextPage) return;

              handleChange(totalPages - 1);
            }}
            disabled={!isAccessNextPage}
          >
            <ArrowLeftIcon />
            <ArrowLeftIcon />
          </Button>

          <Typography component="span" variant="text1">
            صفحه {activePage + 1} از {totalPages} - تعداد کل {totalItems}
          </Typography>
        </div>

        <div className={styles.leftBox}>
          <Typography component="span" variant="text1">
            نمایش در هر صفحه
          </Typography>

          <Select
            placeholder=""
            value={limit.toString()}
            options={[5, 10, 20, 30, 40, 50].map((d) => ({
              label: d.toString(),
              value: d.toString(),
            }))}
            handleChange={(option) => {
              handleChangeLimit(parseInt(option.value));
            }}
            boxClassName={styles.select}
            position="top"
          />
        </div>
      </div>
    );
  }
);

export default Pagination;
