import { useMutation } from "react-query";

import { loginUserPostRequest } from "../../requests";
import { type ILoginUserPostReq } from "../../requests/types";

export const useLoginMutation = () => {
  return useMutation(
    async (variables: ILoginUserPostReq) =>
      await loginUserPostRequest(variables)
  );
};

export default useLoginMutation;
