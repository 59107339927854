import { forwardRef } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import ShortUniqueId from "short-unique-id";

import { CheckIcon, CloseIcon } from "../../icons";
import Button from "../button";
import TextField from "../textField";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IAddCustomInput } from "./types";

const AddCustomInput = forwardRef(
  ({ className = "", name, ...props }: IAddCustomInput, ref) => {
    const methods = useFormContext();
    const { fields, append, remove } = useFieldArray({
      control: methods.control,
      name,
    });

    const handleAddNewAttribute = () => {
      const id = new ShortUniqueId({ dictionary: "number" });

      append({
        key: id.stamp(10).toString(),
        name: "",
      });
    };

    const handleRemoveAttribute = (index: number) => {
      remove(index);
    };

    return (
      <div
        className={`${className} ${styles.wrapper}`}
        ref={ref as any}
        {...props}
      >
        {fields.map((item: any, index) => (
          <div key={item.key} className={styles.item}>
            <Button
              type="button"
              buttonSize="small2"
              color="pink1"
              className={styles.remove}
              onClick={() => {
                handleRemoveAttribute(index);
              }}
            >
              <CloseIcon />
            </Button>

            <div className={styles.fieldGroup}>
              <TextField
                label="نام اینپوت باکس"
                placeholder="نام اینپوت باکس"
                id={`input-name-${index}-${item.name}`}
                name={`${name}.${index}.name`}
              />
            </div>

            <div className={styles.fieldGroup}>
              <TextField
                label="شناسه برنامه نویسی"
                placeholder="شناسه برنامه نویسی"
                id={`input-key-${index}-${item.key}`}
                name={`${name}.${index}.key`}
                disabled
              />
            </div>
          </div>
        ))}

        <div className={styles.add}>
          <Button
            type="button"
            buttonSize="small2"
            color="info1"
            onClick={handleAddNewAttribute}
          >
            <CheckIcon />
          </Button>

          <Typography variant="subtitle2" onClick={handleAddNewAttribute}>
            ایجاد اینپوت باکس انحصاری
          </Typography>
        </div>
      </div>
    );
  }
);

export default AddCustomInput;
