import { type IIconProps } from "./types";

const AllUsersIcon = ({ color = "#ffffff", ...props }: IIconProps) => {
  return (
    <svg
      width="25"
      height="27"
      viewBox="0 0 25 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3358 0.631836C11.0577 0.631834 9.80814 1.01085 8.74538 1.72096C7.68263 2.43107 6.85439 3.44038 6.36526 4.62125C5.87612 5.80213 5.7481 7.10152 5.99746 8.35513C6.24682 9.60873 6.86233 10.7602 7.76614 11.664C8.66994 12.5678 9.82149 13.1833 11.0751 13.4327C12.3287 13.6821 13.628 13.5541 14.8089 13.0649C15.9897 12.5758 16.9991 11.7475 17.7093 10.6847C18.4194 9.62197 18.7984 8.37251 18.7984 7.09435C18.7984 5.38038 18.1175 3.73662 16.9055 2.52467C15.6936 1.31271 14.0498 0.631839 12.3358 0.631836Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2546 18.3138C19.8925 16.5271 16.1432 15.5928 12.3358 15.5928C8.5284 15.5928 4.77916 16.5271 1.41699 18.3138C1.08996 18.4876 0.816388 18.7471 0.62561 19.0645C0.434833 19.3819 0.333983 19.7453 0.333984 20.1156V24.6355C0.333983 24.9184 0.38979 25.1985 0.498047 25.4598C0.606304 25.7212 0.764932 25.9587 0.964966 26.1587C1.165 26.3587 1.40246 26.5174 1.66382 26.6257C1.92517 26.7339 2.20527 26.7896 2.48816 26.7896H22.1835C22.4664 26.7896 22.7465 26.7339 23.0078 26.6257C23.2692 26.5174 23.5066 26.3587 23.7067 26.1587C23.9067 25.9587 24.0654 25.7212 24.1737 25.4598C24.282 25.1985 24.3376 24.9184 24.3376 24.6355V20.1156C24.3376 19.7453 24.2368 19.3819 24.046 19.0645C23.8552 18.7471 23.5817 18.4876 23.2546 18.3138Z"
        fill={color}
      />
    </svg>
  );
};

export default AllUsersIcon;
