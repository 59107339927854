import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { AnnouncementCard, Pagination } from "../../../../components";
import { useGlobalContext } from "../../../../context";
import { usePagination } from "../../../../hooks";
import { Loading } from "../../../../layouts";
import { useNotificationsQuery } from "../../../../queries";
import { normalizeExpireAt } from "../../../../utils";
import SetExpirationTimeModal from "../setExpirationTimeModal";
import styles from "./styles.module.scss";

const List = () => {
  const { offset, limit, activePage, handleSetOffset, handleSetLimit } =
    usePagination();

  const methods = useFormContext();
  const filtersWatcher = methods.watch("filters");
  const notificationsQuery = useNotificationsQuery({
    offset: activePage,
    limit,
    ...filtersWatcher,
  });
  const { handleSetActiveAnnouncement, handleChangeSidebarType } =
    useGlobalContext();
  const [expirationTimeId, setExpirationTimeId] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (!notificationsQuery.isFetched) {
      return;
    }

    if (
      offset !== 0 &&
      notificationsQuery.data?.response.notifications.length === 0
    ) {
      handleSetOffset(0);
    }
  }, [notificationsQuery, offset]);

  const handleNext = (notificationId: number) => {
    handleSetActiveAnnouncement(notificationId);
    handleChangeSidebarType("next-announcement");
  };

  const handleNew = (notificationId: number) => {
    handleSetActiveAnnouncement(notificationId);

    handleChangeSidebarType("new-announcement");
  };

  if (notificationsQuery.isLoading) {
    return <Loading position="absolute" />;
  }
  const notifications = notificationsQuery?.data?.response?.notifications ?? [];
  return (
    <section className={styles.wrapper}>
      <SetExpirationTimeModal
        id={expirationTimeId as number}
        isOpened={typeof expirationTimeId === "number"}
        handleClose={() => {
          setExpirationTimeId(undefined);
        }}
      />

      {notifications.length >= 1 && (
        <AnnouncementCard
          items={
            notifications.map((item) => ({
              id: item.id,
              type: item.type,
              isSeen: item.isSeen,
              subject: item?.subject?.name,
              customers: item?.customers?.map((c) => c.phone) ?? [],
              groups: item.group?.map((g) => g.name) ?? [],
              creator: item?.creator?.name,
              createdAt: item.created_at,
              hasExpiration: item.has_expiration,
              isExpired: item.is_expired,
              time: normalizeExpireAt(item.expire_at) ?? undefined,
            })) ?? []
          }
          handleClickExpirationTime={(id) => {
            setExpirationTimeId(id);
          }}
          handleClickNew={(id) => {
            handleNew(id);
          }}
          handleClickNext={(id) => {
            handleNext(id);
          }}
        />
      )}

      {notifications.length >= 1 && (
        <Pagination
          activePage={offset}
          totalItems={notificationsQuery.data?.response.total_count ?? 0}
          limit={limit}
          className={styles.pagination}
          handleChange={(activePage) => {
            handleSetOffset(activePage);
          }}
          handleChangeLimit={(newLimit) => {
            handleSetLimit(newLimit);
          }}
        />
      )}
    </section>
  );
};

export default List;
