import { type IIconProps } from "./types";

const SeenIcon = ({ color = "#2A3A65", ...props }: IIconProps) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.86096 2.272C8.72238 2.27186 9.55729 2.5704 10.2233 3.11676C10.8892 3.66313 11.3451 4.4235 11.5133 5.26834C11.6815 6.11318 11.5517 6.99022 11.1458 7.75C10.7398 8.50978 10.083 9.10531 9.28723 9.43512C8.49145 9.76493 7.60587 9.8086 6.78149 9.55872C5.95712 9.30883 5.24485 8.78084 4.76611 8.0647C4.28738 7.34856 4.07176 6.48858 4.15601 5.63129C4.24025 4.774 4.61917 3.97242 5.22815 3.36316C5.57372 3.01713 5.98414 2.74266 6.43591 2.55542C6.88769 2.36818 7.37192 2.27187 7.86096 2.272ZM7.86096 0.552734C2.88517 0.552734 0 4.63554 0 5.99884C0 7.36173 2.88515 11.4435 7.86096 11.4435C12.8384 11.4435 15.7224 7.36173 15.7224 5.99884C15.7224 4.63578 12.8384 0.552734 7.86096 0.552734Z"
        fill={color}
      />
      <path
        d="M10.1716 5.99856C10.1713 6.53851 9.98298 7.06151 9.63915 7.47784C9.29532 7.89417 8.81738 8.17788 8.28723 8.28027C7.75707 8.38265 7.20776 8.29733 6.73364 8.03897C6.25951 7.7806 5.89007 7.36528 5.68871 6.86428C5.48736 6.36328 5.46666 5.80782 5.63012 5.29321C5.79358 4.77859 6.13103 4.33692 6.58459 4.04394C7.03814 3.75096 7.57953 3.62496 8.11584 3.68756C8.65215 3.75015 9.15002 3.99744 9.52392 4.38699L7.84643 5.99877L10.1716 5.99856Z"
        fill={color}
      />
    </svg>
  );
};

export default SeenIcon;
