import { useMutation } from "react-query";

import { subjectDeleteRequest } from "../../requests";

const useSubjectDeleteMutation = () => {
  return useMutation(
    async (variables: { id: number }) =>
      await subjectDeleteRequest(variables.id)
  );
};

export default useSubjectDeleteMutation;
