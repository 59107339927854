import { apiUrls } from "../data";
import requestService from "../services/request";
import {
  type IAnswerItemPostReq,
  type IDetailsItemGetReq,
  type IDetailsItemGetRes,
  type IExpirationItemPostReq,
  type IItemCreatePostReq,
  type IItemCreatePostRes,
  type IItemFinishPostReq,
  type IItemsGetReq,
  type IItemsGetRes,
  type IReferItemPostReq,
} from "./types";

export const itemCreatePostRequest = async (
  payload: IItemCreatePostReq
): Promise<IItemCreatePostRes> => {
  const { data } = await requestService.post(apiUrls.items.itemCreate, payload);

  return data;
};

export const itemsGetRequest = async (
  params: IItemsGetReq
): Promise<IItemsGetRes> => {
  const { data } = await requestService.get(apiUrls.items.itemsGet, { params });

  return data;
};

export const detailsItemGetRequest = async (
  payload: IDetailsItemGetReq
): Promise<IDetailsItemGetRes> => {
  const { data } = await requestService.get(apiUrls.items.detailsItemGet, {
    params: {
      item_id: payload.id,
    },
  });

  return data;
};

export const finishItemPostRequest = async (payload: IItemFinishPostReq) => {
  const { data } = await requestService.post(apiUrls.items.finishItem, payload);

  return data;
};

export const answerItemPostRequest = async (payload: IAnswerItemPostReq) => {
  const { data } = await requestService.post(apiUrls.items.answerItem, payload);

  return data;
};

export const setExpirationItemPostRequest = async (
  params: IExpirationItemPostReq
) => {
  const { data } = await requestService.post(
    apiUrls.items.setExpirationItem,
    undefined,
    {
      params: {
        item_id: params.itemId,
        expire_at: params.expireAt,
        refer_id: params.refer_id,
      },
    }
  );

  return data;
};

export const referItemPostRequest = async (payload: IReferItemPostReq) => {
  const { data } = await requestService.post(apiUrls.items.referItem, payload);

  return data;
};
