import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Avatar,
  Button,
  ChatBox,
  CountBadge,
  NotificationItem,
  SearchBox,
  Typography,
} from "../../components";
import { useGlobalContext } from "../../context";
import { customerSearchKey } from "../../data";
import useAuth from "../../hooks/useAuth";
import { CheckIcon } from "../../icons";
import {
  useChatUsersQuery,
  useLogoutMutation,
  useSettingUsersQuery,
  useUserNotificationsQuery,
  useUserQuery,
} from "../../queries";
import styles from "./styles.module.scss";

const MainSidebar = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { handleHideSidebar, handleChangeSidebarType, handleSetActiveChatId } =
    useGlobalContext();
  const { handleLogout } = useAuth();
  const userQuery = useUserQuery();
  const chatUsersQuery = useChatUsersQuery();
  const userNotificationsQuery = useUserNotificationsQuery({
    limit: 10000,
    offset: 0,
  });
  const settingUsersQuery = useSettingUsersQuery({ offset: 0, limit: 10000 });
  const logoutMutation = useLogoutMutation();

  const [searchValue, setSearchValue] = useState<string>("");

  const findUser = settingUsersQuery.data?.response?.users?.find(
    (u) => u.id === userQuery?.data?.user?.id
  );

  useEffect(() => {
    if (typeof params.searchValue !== "string") {
      setSearchValue("");
      return;
    }

    setSearchValue(params.searchValue.trim());
  }, [params]);

  const unseenChatsCount =
    chatUsersQuery?.data?.response?.unread_chat_count ?? 0;
  const unseenNotificationsCount =
    userNotificationsQuery?.data?.response?.unseen_notifications_count ?? 0;

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.user}>
          <div>
            <Avatar profile={userQuery?.data?.user?.profile} />

            <Typography variant="subtitle2" style={{ fontWeight: 800 }}>
              {userQuery.data?.user?.name?.slice(0, 15)}
            </Typography>
          </div>

          <Button
            type="button"
            buttonSize="medium2"
            color="secondary1"
            radius="high"
            disabled={logoutMutation.isLoading}
            loading={logoutMutation.isLoading}
            onClick={() => {
              logoutMutation.mutate(undefined, {
                onSuccess: handleLogout,
              });
            }}
          >
            خروج
          </Button>
        </div>

        <div>
          <SearchBox
            handleChange={setSearchValue}
            handleSubmit={() => {
              handleHideSidebar();
              navigate(`/search/${searchValue.trim()}`);
            }}
            value={searchValue === customerSearchKey ? "" : searchValue}
            inputProps={{ id: "search-field" }}
          />

          <div
            className={styles.buttons}
            style={{
              gridTemplateColumns: findUser?.can_make_notification
                ? "repeat(2, 1fr)"
                : "1fr",
            }}
          >
            <Button
              type="button"
              buttonSize="large2"
              onClick={() => {
                if (
                  !Array.isArray(findUser?.groups) ||
                  (findUser?.groups?.length as number) < 1
                ) {
                  toast("برای ساخت مورد جدید باید در یک گروه عضو شوید", {
                    type: "info",
                    toastId: "create-new-item",
                  });
                  return;
                }

                handleChangeSidebarType("create-item");
              }}
            >
              <CheckIcon />
              ایجاد مورد
            </Button>

            {findUser?.can_make_notification && (
              <Button
                type="button"
                buttonSize="large2"
                onClick={() => {
                  if (
                    !Array.isArray(findUser?.groups) ||
                    (findUser?.groups?.length as number) < 1
                  ) {
                    toast("برای ساخت اعلان جدید باید در یک گروه عضو شوید", {
                      type: "info",
                      toastId: "create-new-item",
                    });
                    return;
                  }

                  handleChangeSidebarType("create-announcement");
                }}
              >
                <CheckIcon />
                ایجاد اعلان
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className={styles.notifications}>
        <div className={styles.title}>
          <Typography variant="subtitle1" style={{ fontWeight: 800 }}>
            نوتیفیکیشن ها
          </Typography>

          <Button
            type="button"
            color="secondary1"
            buttonSize="medium3"
            radius="high"
            onClick={() => {
              handleChangeSidebarType("notifications");
            }}
            style={{
              justifyContent:
                unseenNotificationsCount > 0 ? "space-between" : "center",
            }}
          >
            مشاهده همه
            <CountBadge
              color="gold1"
              count={unseenNotificationsCount}
              notShowOnZeroValue
            />
          </Button>
        </div>

        {userNotificationsQuery.data?.response.user_notifications
          .slice(0, 3)
          .map((notification, index) => (
            <div
              key={notification.id.toString()}
              style={{ marginTop: index === 0 ? 0 : 3 }}
            >
              <NotificationItem
                title={notification.title}
                description={notification.text}
                date={notification.created_at}
              />
            </div>
          ))}
      </div>

      <div className={styles.chats}>
        <div className={styles.title}>
          <Typography variant="subtitle1" style={{ fontWeight: 800 }}>
            چت با کاربران
          </Typography>

          <Button
            type="button"
            color="secondary1"
            radius="high"
            buttonSize="medium3"
            onClick={() => {
              handleChangeSidebarType("chats");
            }}
            style={{
              justifyContent: unseenChatsCount > 0 ? "space-between" : "center",
            }}
          >
            مشاهده همه
            <CountBadge
              color="gold1"
              count={unseenChatsCount}
              notShowOnZeroValue
            />
          </Button>
        </div>

        {chatUsersQuery.data?.response.users.slice(0, 3).map((item, index) => (
          <div
            key={item.id.toString()}
            style={{ marginTop: index === 0 ? 0 : 10 }}
          >
            <ChatBox
              username={item.name}
              description={item?.group?.name ?? ""}
              isOnline={item.online}
              status={item.online ? undefined : item.last_online}
              profile={item.profile}
              onClick={() => {
                handleSetActiveChatId(item.id);
                handleChangeSidebarType("details-chat");
              }}
              unseenMessagesCount={item.unseen_messages_count}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainSidebar;
