import { useMutation } from "react-query";

import { answerItemPostRequest } from "../../requests";
import { type IAnswerItemPostReq } from "../../requests/types";

const useAnswerItemMutation = () => {
  return useMutation(
    async (variables: IAnswerItemPostReq) =>
      await answerItemPostRequest(variables)
  );
};

export default useAnswerItemMutation;
