const handleTransformFilterItems = (
  items: Array<{
    value: any;
    label: any;
  }>
): Array<{ label: any; value: any }> => {
  const allItems = {
    label: "نمایش همه",
    value: "",
  };

  if (!Array.isArray(items)) {
    return [allItems];
  }

  return [allItems, ...items];
};

export default handleTransformFilterItems;
