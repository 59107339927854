export const announcementTypesData = [
  {
    label: "آموزشی",
    value: "educational",
  },
  {
    label: "خبری",
    value: "news",
  },
];
