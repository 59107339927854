import { type IIconProps } from "./types";

const LineIcon = ({ color = "#2A3A65", ...props }: IIconProps) => {
  return (
    <svg
      width="250"
      height="24"
      viewBox="0 0 327 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.2272 23.0002H319.145C322.931 23.0002 326 18.6847 326 13.3613V11.8638"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="10.73 10.73"
      />
      <path
        d="M326 6.5V1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LineIcon;
