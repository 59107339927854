import { useGlobalContext } from "../../context";
import {
  AnswerItemSidebar,
  CompleteItem,
  CreateAnnouncementSidebar,
  CreateItemSidebar,
  DetailsChatSidebarLayout,
  NewNotification,
  NotificationSidebarLayout,
  ReferItemSidebar,
  SmallMainSidebarLayout,
} from "..";
import ChatsSidebar from "../chatsSidebar";
import MainSidebar from "../mainSidebar";
import styles from "./styles.module.scss";

const Sidebar = () => {
  const {
    isShowSidebar,
    handleHideSidebar,
    sidebarType,
    handleChangeSidebarType,
  } = useGlobalContext();

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <div
        className={`${styles.wrapper} ${
          isShowSidebar ? styles.wrapperShow : ""
        } ${sidebarType === "small-main" ? styles.wrapperSmall : ""}`}
      >
        {(sidebarType === "small-main" || sidebarType === "main") && (
          <div
            className={`${styles.menu} ${
              sidebarType === "small-main" ? styles.menuSmall : styles.menuLarge
            }`}
            onClick={() => {
              handleChangeSidebarType(
                sidebarType === "small-main" ? "main" : "small-main"
              );
            }}
          >
            <div />
            <div />
            <div />
          </div>
        )}

        <div className={styles.wrapperSidebar}>
          {sidebarType === "main" && <MainSidebar />}

          {sidebarType === "small-main" && <SmallMainSidebarLayout />}

          {sidebarType === "notifications" && <NotificationSidebarLayout />}

          {sidebarType === "chats" && <ChatsSidebar />}

          {sidebarType === "details-chat" && <DetailsChatSidebarLayout />}

          {sidebarType === "create-item" && <CreateItemSidebar />}

          {sidebarType === "answer-item" && <AnswerItemSidebar />}

          {sidebarType === "complete-item" && <CompleteItem />}

          {sidebarType === "refer-item" && <ReferItemSidebar />}

          {sidebarType === "create-announcement" && (
            <CreateAnnouncementSidebar />
          )}

          {(sidebarType === "new-announcement" ||
            sidebarType === "next-announcement") && <NewNotification />}
        </div>
      </div>

      <div
        onClick={handleHideSidebar}
        className={`${styles.shadow} ${isShowSidebar ? styles.shadowShow : ""}`}
      />
    </div>
  );
};

export default Sidebar;
