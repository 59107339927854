import { GroupSettingTable } from "../../../../components";
import { Loading } from "../../../../layouts";
import { useSettingGroupsQuery } from "../../../../queries";

const Group = () => {
  const groupsQuery = useSettingGroupsQuery({
    offset: 0,
    limit: 10000,
    my_groups: false,
  });

  if (groupsQuery.isLoading) return <Loading />;
  const groups = groupsQuery.data?.response.groups.groups ?? [];

  if (groups.length < 1) {
    return null;
  }
  return (
    <GroupSettingTable
      items={groups.map((item) => ({
        id: `${item.id}`,
        name: item.name,
        startShift: item.shift_start_time,
        endShift: item.shift_end_time,
        shiftUsersCount: item.users_count.toString(),
        shiftConcurrentUsers: item.concurrent_users_count,
        shiftHours: item.per_shift_hour,
        status: item.active,
      }))}
    />
  );
};

export default Group;
