import { type IIconProps } from "./types";

const ArrowBottomIcon = ({ color = "#2A3A65", ...props }: IIconProps) => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.0001 1.07747C14.0001 1.15743 13.9844 1.23662 13.9538 1.31049C13.9232 1.38435 13.8783 1.45144 13.8217 1.50791L7.73355 7.59599C7.67705 7.65256 7.60996 7.69743 7.5361 7.72804C7.46225 7.75866 7.38308 7.77442 7.30313 7.77442C7.22318 7.77442 7.14402 7.75866 7.07016 7.72804C6.9963 7.69743 6.92918 7.65256 6.87268 7.59599L0.784546 1.50787C0.67047 1.39369 0.606411 1.23888 0.606445 1.07749C0.60648 0.916089 0.670634 0.761313 0.78476 0.647188C0.898885 0.533063 1.05366 0.468931 1.21506 0.468897C1.37645 0.468862 1.53124 0.532929 1.64542 0.647005L7.30313 6.3047L12.9608 0.647005C13.046 0.561884 13.1544 0.503919 13.2725 0.48044C13.3906 0.456961 13.513 0.469019 13.6242 0.515093C13.7355 0.561166 13.8305 0.639189 13.8974 0.73929C13.9643 0.839391 14 0.957074 14.0001 1.07747Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowBottomIcon;
