import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";

import {
  AvatarUploader,
  Button,
  Modal,
  RHFSelect,
  TextField,
  Typography,
} from "../../../../components";
import ChooseGroup from "../../../../components/chooseGroup";
import { RHFSwitch } from "../../../../components/switch";
import { useGlobalContext } from "../../../../context";
import { queryKeys } from "../../../../data";
import {
  useCreateUserMutation,
  useSettingUsersQuery,
  useUploadFileMutation,
} from "../../../../queries";
import { type ISettingsAddModalProps } from "../../types";
import styles from "./styles.module.scss";

const resolverFn = (isEdit: boolean) => {
  const obj: any = {
    pictureFile: yup.mixed().notRequired(),
    pictureUrl: yup.string().notRequired(),
    name: yup.string().required("نام را وارد کنید").trim(),
    email: yup
      .string()
      .email("ایمیل را صحیح وارد کنید")
      .required("ایمیل را وارد کنید ")
      .trim(),
    type: yup.boolean().required("نوع کاربر را انتخاب کنید"),
    activeGroupsId: yup
      .array()
      .of(yup.object().shape({ value: yup.number().required() }))
      .min(1, "حداقل یک گروه باید  انتخاب شود")
      .required(),
    mainGroupId: yup
      .array()
      .of(yup.object().shape({ value: yup.number().required() }))
      .min(1, "گروه اصلی را انتخاب کنید")
      .required(),
    active: yup.boolean().required("وضعیت کاربر را مشخص کنید"),
    chatAccess: yup.boolean().required(),
    makeNotificationAccess: yup.boolean().required(),
  };

  if (isEdit) {
    obj.password = yup
      .string()
      .test("password", "رمز عبور حداقل باید ۸ رقم باشد", (value) => {
        if (typeof value !== "string" || value === "") return true;

        if (value.length >= 1 && value.length < 8) {
          return false;
        }

        return true;
      })
      .trim();

    obj.repeatPassword = yup
      .string()
      .oneOf([yup.ref("password")], "تکرار رمز عبور صحیح نمیباشد")
      .trim();
  } else {
    obj.password = yup
      .string()
      .required("رمز عبور را وارد کنید")
      .min(8, "رمز عبور نمیتواند کمتر از ۸ رقم باشد")
      .max(30, "رمز عبور نمیتواند بیشتر از ۳۰ رقم باشد")
      .trim();

    obj.repeatPassword = yup
      .string()
      .required("تکرار رمز عبور را وارد کنید")
      .oneOf([yup.ref("password")], "تکرار رمز عبور صحیح نمیباشد")
      .trim();
  }

  return yupResolver(yup.object().shape(obj));
};

const AddUser = ({ handleClose, isOpened }: ISettingsAddModalProps) => {
  const { activeUserEditId } = useGlobalContext();
  const usersQuery = useSettingUsersQuery({ limit: 10000, offset: 0 });
  const findUser = usersQuery?.data?.response?.users?.find(
    (u) => u.id === activeUserEditId
  );

  const methods = useForm({
    defaultValues: {
      pictureFile: undefined,
      pictureUrl: findUser?.profile ?? undefined,
      name: findUser?.name ?? "",
      email: findUser?.email ?? "",
      type: findUser?.is_admin ?? undefined,
      activeGroupsId: findUser?.groups?.map((g) => ({ value: g.id })) ?? [],
      mainGroupId:
        typeof findUser?.main_group?.id === "number"
          ? [{ value: findUser?.main_group?.id }]
          : undefined,
      password: "",
      repeatPassword: "",
      active: findUser?.active ?? undefined,
      chatAccess: findUser?.can_chat ?? true,
      makeNotificationAccess: findUser?.can_make_notification ?? true,
    } as any,
    resolver: resolverFn(activeUserEditId !== -1),
  });

  const uploadMutation = useUploadFileMutation();
  const createUserMutation = useCreateUserMutation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpened) return;

    methods.reset();
  }, [isOpened]);

  const handleSubmit = async (values: any) => {
    let imageUrl;

    if (typeof values.pictureFile !== "undefined") {
      const file = await uploadMutation.mutateAsync({
        file: values.pictureFile as any,
        type: "file",
      });

      imageUrl = file.data.token;
    }

    createUserMutation.mutate(
      {
        user_id: activeUserEditId === -1 ? undefined : activeUserEditId,
        name: values.name,
        email: values.email,
        type: values.type ? "admin" : "user",
        group_ids: values.activeGroupsId.map((u: any) => u.value),
        main_group_id: values.mainGroupId[0].value,
        password: (values.password === "" ? undefined : values.password) as any,
        repeat_password: (values.repeatPassword === ""
          ? undefined
          : values.repeatPassword) as any,
        active: values.active,
        profile_image: imageUrl,
        can_chat: values.chatAccess,
        can_make_notification: values.makeNotificationAccess,
      },
      {
        onSuccess() {
          void queryClient.refetchQueries({ queryKey: queryKeys.chatUsers });
          void queryClient.refetchQueries({ queryKey: queryKeys.users });
          void queryClient.refetchQueries({ queryKey: queryKeys.user });

          toast("کاربر ایجاد شد", { type: "success" });

          handleClose();
        },
      }
    );
  };

  return (
    <Modal
      title={activeUserEditId === -1 ? "ایجاد کاربر جدید" : "ویرایش کاربر"}
      handleClose={handleClose}
      isOpened={isOpened}
      isShowCloseButton
    >
      <FormProvider {...methods}>
        <form
          className={styles.wrapper}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <div
            className={styles.generalFields}
            style={{ gridTemplateColumns: "260px repeat(3, 1fr)" }}
          >
            <AvatarUploader />

            <div>
              <TextField
                name="name"
                placeholder="نام کاربری"
                label="نام کاربری"
                id="name"
              />
            </div>

            <div>
              <TextField
                name="email"
                label="ایمیل"
                placeholder="ایمیل"
                id="email"
                style={{ direction: "ltr", textAlign: "center" }}
              />
            </div>

            <div>
              <RHFSelect
                label="نوع"
                options={[
                  {
                    label: "کاربر",
                    value: false,
                  },
                  {
                    label: "مدیر",
                    value: true,
                  },
                ]}
                placeholder="نوع"
                name="type"
                id="type"
              />
            </div>
          </div>

          <div className={styles.selectGroup}>
            <Typography variant="subtitle2" component="label">
              انتخاب گروه
            </Typography>

            <div style={{ height: 20 }} />

            <ChooseGroup myGroup={false} />
          </div>

          <div className={styles.selectGroup}>
            <Typography variant="subtitle2" component="label">
              انتخاب گروه اصلی
            </Typography>

            <div style={{ height: 20 }} />

            <ChooseGroup myGroup={false} multiple={false} name="mainGroupId" />
          </div>

          <div className={styles.generalFields}>
            <div>
              <TextField
                name="password"
                label="رمز عبور"
                placeholder="رمز عبور"
                id="password"
                type="password"
              />
            </div>

            <div>
              <TextField
                name="repeatPassword"
                placeholder="تکرار رمز عبور"
                label="تکرار رمز عبور"
                id="repeat-password"
                type="password"
              />
            </div>

            <div>
              <RHFSelect
                label="وضعیت"
                options={[
                  {
                    label: "فعال",
                    value: true,
                  },
                  {
                    label: "غیرفعال",
                    value: false,
                  },
                ]}
                placeholder="وضعیت"
                name="active"
                id="active"
              />
            </div>
          </div>

          <div className={`${styles.generalFields} ${styles.switches}`}>
            <div>
              <RHFSwitch name="chatAccess" label="امکان دریافت چت" />
            </div>

            <div>
              <RHFSwitch
                name="makeNotificationAccess"
                label="امکان ایجاد اعلان"
              />
            </div>
          </div>

          <div className={styles.button}>
            <Button
              buttonSize="medium1"
              type="submit"
              color="info1"
              radius="high"
              disabled={
                createUserMutation.isLoading || uploadMutation.isLoading
              }
              loading={createUserMutation.isLoading || uploadMutation.isLoading}
            >
              {activeUserEditId === -1 ? "ایجاد کاربر" : "ویرایش کاربر"}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddUser;
