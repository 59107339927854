import { type IIconProps } from "./types";

const SearchBox = ({ color = "#2A3A65", ...props }: IIconProps) => {
  return (
    <svg
      width="37"
      height="31"
      viewBox="0 0 37 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.6591 20.1265C30.969 18.4691 31.6852 16.4205 31.6934 14.308C31.6934 12.4215 31.134 10.5773 30.0859 9.00872C29.0378 7.44013 27.5481 6.21756 25.8051 5.49561C24.0622 4.77367 22.1443 4.58477 20.294 4.95281C18.4437 5.32086 16.7442 6.22931 15.4102 7.56329C14.0763 8.89727 13.1678 10.5969 12.7998 12.4472C12.4317 14.2974 12.6205 16.2153 13.3425 17.9582C14.0644 19.7012 15.2871 21.1909 16.8557 22.239C18.4243 23.2871 20.2684 23.8465 22.155 23.8465C24.2674 23.8383 26.3161 23.122 27.9735 21.8122L34.4278 28.2664C34.6527 28.4835 34.9539 28.6036 35.2664 28.6008C35.579 28.5981 35.878 28.4727 36.0991 28.2517C36.3201 28.0306 36.4454 27.7316 36.4482 27.4191C36.4509 27.1065 36.3308 26.8053 36.1137 26.5805L29.6591 20.1265ZM15.0011 14.308C15.0011 12.8931 15.4207 11.51 16.2067 10.3335C16.9928 9.15711 18.1101 8.24018 19.4173 7.69872C20.7245 7.15727 22.1629 7.0156 23.5506 7.29163C24.9383 7.56767 26.213 8.24901 27.2135 9.24949C28.2139 10.25 28.8953 11.5247 29.1713 12.9124C29.4474 14.3001 29.3057 15.7385 28.7642 17.0457C28.2228 18.3529 27.3058 19.4702 26.1293 20.2562C24.9529 21.0423 23.5699 21.4619 22.155 21.4619C21.2155 21.4619 20.2853 21.2768 19.4173 20.9173C18.5494 20.5578 17.7607 20.0309 17.0964 19.3666C16.4321 18.7023 15.9051 17.9136 15.5456 17.0457C15.1861 16.1777 15.001 15.2475 15.0011 14.308Z"
        fill={color}
      />
      <path
        d="M35.2704 0H1.88574C1.56952 0 1.26633 0.125611 1.04272 0.349213C0.819123 0.572814 0.693481 0.876086 0.693481 1.19231C0.693481 1.50853 0.819123 1.8118 1.04272 2.0354C1.26633 2.259 1.56952 2.38461 1.88574 2.38461H35.2704C35.5866 2.38461 35.8898 2.259 36.1134 2.0354C36.337 1.8118 36.4626 1.50853 36.4626 1.19231C36.4626 0.876086 36.337 0.572814 36.1134 0.349213C35.8898 0.125611 35.5866 0 35.2704 0Z"
        fill={color}
      />
      <path
        d="M1.88574 16.6932H9.03955C9.35577 16.6932 9.65909 16.5676 9.88269 16.344C10.1063 16.1204 10.2319 15.8171 10.2319 15.5009C10.2319 15.1847 10.1063 14.8814 9.88269 14.6578C9.65909 14.4342 9.35577 14.3086 9.03955 14.3086H1.88574C1.56952 14.3086 1.26633 14.4342 1.04272 14.6578C0.819123 14.8814 0.693481 15.1847 0.693481 15.5009C0.693481 15.8171 0.819123 16.1204 1.04272 16.344C1.26633 16.5676 1.56952 16.6932 1.88574 16.6932Z"
        fill={color}
      />
      <path
        d="M1.88574 23.8456H9.03955C9.19642 23.846 9.35189 23.8155 9.49695 23.7558C9.642 23.6961 9.77381 23.6083 9.88489 23.4976C9.99597 23.3868 10.0842 23.2552 10.1443 23.1103C10.2044 22.9654 10.2354 22.8101 10.2354 22.6532C10.2354 22.4964 10.2044 22.3411 10.1443 22.1962C10.0842 22.0513 9.99597 21.9197 9.88489 21.8089C9.77381 21.6982 9.642 21.6104 9.49695 21.5507C9.35189 21.491 9.19642 21.4605 9.03955 21.4609H1.88574C1.72888 21.4605 1.57352 21.491 1.42847 21.5507C1.28341 21.6104 1.15149 21.6982 1.04041 21.8089C0.929323 21.9197 0.841263 22.0513 0.781128 22.1962C0.720993 22.3411 0.689941 22.4964 0.689941 22.6532C0.689941 22.8101 0.720993 22.9654 0.781128 23.1103C0.841263 23.2552 0.929323 23.3868 1.04041 23.4976C1.15149 23.6083 1.28341 23.6961 1.42847 23.7558C1.57352 23.8155 1.72888 23.846 1.88574 23.8456Z"
        fill={color}
      />
      <path
        d="M1.88574 30.9998H28.1166C28.4328 30.9998 28.736 30.8742 28.9596 30.6506C29.1832 30.427 29.3088 30.1238 29.3088 29.8075C29.3088 29.4913 29.1832 29.188 28.9596 28.9644C28.736 28.7408 28.4328 28.6152 28.1166 28.6152H1.88574C1.56952 28.6152 1.26633 28.7408 1.04272 28.9644C0.819123 29.188 0.693481 29.4913 0.693481 29.8075C0.693481 30.1238 0.819123 30.427 1.04272 30.6506C1.26633 30.8742 1.56952 30.9998 1.88574 30.9998Z"
        fill={color}
      />
      <path
        d="M1.88574 9.53891H9.03955C9.35577 9.53891 9.65909 9.41328 9.88269 9.18968C10.1063 8.96608 10.2319 8.66282 10.2319 8.3466C10.2319 8.03038 10.1063 7.72711 9.88269 7.50351C9.65909 7.27991 9.35577 7.1543 9.03955 7.1543H1.88574C1.56952 7.1543 1.26633 7.27991 1.04272 7.50351C0.819124 7.72711 0.693481 8.03038 0.693481 8.3466C0.693481 8.66282 0.819124 8.96608 1.04272 9.18968C1.26633 9.41328 1.56952 9.53891 1.88574 9.53891Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchBox;
