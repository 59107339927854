import { useMutation } from "react-query";

import { itemCreatePostRequest } from "../../requests";
import { type IItemCreatePostReq } from "../../requests/types";

const useItemCreateMutation = () => {
  return useMutation(
    async (variables: IItemCreatePostReq) =>
      await itemCreatePostRequest(variables)
  );
};

export default useItemCreateMutation;
