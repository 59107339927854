import { useQuery } from "react-query";

import { localStorageKeys, queryKeys } from "../../data";
import { userGetRequest } from "../../requests";

export const useUserQuery = () => {
  return useQuery(queryKeys.user, userGetRequest, {
    enabled:
      typeof localStorage.getItem(localStorageKeys.apiAuthorization) ===
      "string",
  });
};

export default useUserQuery;
