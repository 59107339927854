import { useQuery } from "react-query";

import { localStorageKeys, queryKeys } from "../../data";
import { settingsGroupsGet } from "../../requests";
import { type ISettingsGroupGetReq } from "../../requests/types";

const useSettingGroupsQuery = (req: ISettingsGroupGetReq) => {
  return useQuery(
    [queryKeys.settingsGroup, req.my_groups],
    async () => await settingsGroupsGet(req),
    {
      enabled:
        typeof localStorage.getItem(localStorageKeys.apiAuthorization) ===
        "string",
    }
  );
};

export default useSettingGroupsQuery;
