import { forwardRef } from "react";

import Button from "../button";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IModalProps } from "./types";

const Modal = forwardRef(
  (
    {
      className = "",
      children,
      handleClose,
      title,
      isOpened,
      isShowCloseButton,
      ...props
    }: IModalProps,
    ref
  ) => {
    return (
      <div
        className={`${className} ${styles.wrapper} ${
          isOpened ? styles.wrapperOpened : ""
        }`}
        ref={ref as any}
        {...props}
      >
        <div className={styles.closeBox} onClick={handleClose} />

        <div
          className={`${styles.modal} ${isOpened ? styles.modalOpened : ""}`}
        >
          <div className={styles.modalHeader}>
            <Typography component="h3" variant="title">
              {title}
            </Typography>

            {isShowCloseButton && (
              <Button
                type="button"
                buttonSize="medium2"
                color="secondary1"
                radius="high"
                onClick={handleClose}
              >
                بستن
              </Button>
            )}
          </div>

          <div className={styles.modalBody}>{children}</div>
        </div>
      </div>
    );
  }
);

export default Modal;
