import { useEffect } from "react";
import { useStopwatch, useTimer } from "react-timer-hook";

const useExpireAt = (
  time?: Date,
  cb?: () => void,
  isNotIncreaseTime?: boolean
) => {
  const startAtTime = new Date();

  if (time) {
    startAtTime.setTime(
      new Date().getTime() + (new Date().getTime() - time.getTime())
    );
  }

  const timer = useTimer({
    autoStart: typeof time !== "undefined" && time !== null,
    expiryTimestamp: time as Date,
  });
  const stopwatch = useStopwatch({
    autoStart: false,
    offsetTimestamp: startAtTime,
  });

  const isFinishedTimer =
    timer.hours === 0 && timer.minutes === 0 && timer.seconds === 0;

  useEffect(() => {
    if (
      typeof time === "undefined" ||
      !isFinishedTimer ||
      stopwatch.isRunning
    ) {
      return;
    }

    if (typeof cb === "function") {
      cb();
    }

    stopwatch.start();
  }, [isFinishedTimer, stopwatch.isRunning, time]);

  useEffect(() => {
    timer.restart(time as Date);
  }, [time]);

  const getTimerText = (hours: number, minutes: number, seconds: number) => {
    return `${seconds.toString().length > 1 ? seconds : `0${seconds}`} : ${
      minutes.toString().length > 1 ? minutes : `0${minutes}`
    } : ${hours.toString().length > 1 ? hours : `0${hours}`}`;
  };

  if (isFinishedTimer) {
    if (isNotIncreaseTime) return "";
    return `(${getTimerText(
      stopwatch.hours,
      stopwatch.minutes,
      stopwatch.seconds
    )})`;
  }
  return getTimerText(timer.hours, timer.minutes, timer.seconds);
};

export default useExpireAt;
