import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";

import { Button, ChooseUser, TextEditor, Typography } from "../../components";
import ChooseGroup from "../../components/chooseGroup";
import { useGlobalContext } from "../../context";
import { queryKeys } from "../../data";
import { useTextEditorUploader } from "../../hooks";
import { useDetailsItemQuery, useReferItemMutation } from "../../queries";
import styles from "./styles.module.scss";

const yupSchema = yup.object({
  activeGroupsId: yup
    .array()
    .of(yup.object().shape({ value: yup.number().required() }).required())
    .min(1, "حداقل یک گروه باید  انتخاب شود"),
  file1: yup.mixed().nullable(),
  file2: yup.mixed().nullable(),
  file3: yup.mixed().nullable(),
  file4: yup.mixed().nullable(),
  voice: yup.mixed().nullable(),
  description: yup.string().required("توضیحات را وارد کنید"),
  usersId: yup
    .array()
    .of(yup.object().shape({ value: yup.number().required() }).required()),
});

const CreateItemSidebar = () => {
  const { handleChangeSidebarType, activeItem, handleSetActiveItem } =
    useGlobalContext();
  const [textEditorHeight, setTextEditorHeight] = useState<number>(233);
  const referItemMutation = useReferItemMutation();
  const queryClient = useQueryClient();
  const detailsItemQuery = useDetailsItemQuery({ id: activeItem.toString() });

  const methods = useForm({
    defaultValues: {
      activeGroupsId: [],
      description: "",
      file1: null,
      file2: null,
      file3: null,
      file4: null,
      voice: null,
      usersId: [],
    },
    resolver: yupResolver(yupSchema),
  });
  const { handleUpload, isUploading } = useTextEditorUploader(methods as any);

  const handleSubmit = async () => {
    const values = methods.getValues();

    const resultUploader = await handleUpload();

    referItemMutation.mutate(
      {
        item_id: activeItem,
        group_id: values?.activeGroupsId?.[0]?.value as any,
        description: values.description,
        file_1: resultUploader?.file_1?.token ?? null,
        file_2: resultUploader?.file_2?.token ?? null,
        file_3: resultUploader?.file_3?.token ?? null,
        file_4: resultUploader?.file_4?.token ?? null,
        voice: resultUploader?.voice?.token ?? null,
        user_id: values?.usersId?.[0]?.value,
      },
      {
        onSuccess() {
          toast("انتقال با موفقیت انجام شد", { type: "success" });

          void queryClient.refetchQueries({ queryKey: queryKeys.items });
          void queryClient.refetchQueries({
            queryKey: [queryKeys.detailsItem, activeItem.toString()],
          });

          handleSetActiveItem(-1);

          handleChangeSidebarType("main");
        },
      }
    );
  };

  const messages = detailsItemQuery?.data?.response?.messages ?? [];
  const endGroup = messages?.[messages?.length - 1]?.referred_group_name;
  return (
    <div className={styles.wrapper}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <div
            style={{
              height: `calc(100vh - 15px - ${textEditorHeight}px)`,
              overflow: "auto",
            }}
          >
            <div className={styles.header}>
              <Typography component="h3" variant="title">
                انتقال
              </Typography>

              <Button
                buttonSize="medium2"
                type="button"
                color="secondary1"
                radius="high"
                onClick={() => {
                  handleSetActiveItem(-1);
                  handleChangeSidebarType("main");
                }}
              >
                بستن
              </Button>
            </div>

            <div className={styles.content}>
              <div className={styles.selectGroup}>
                <Typography variant="subtitle1" component="h4">
                  انتخاب گروه
                </Typography>

                <ChooseGroup
                  multiple={false}
                  filterGroup={endGroup}
                  myGroup={false}
                />
              </div>

              <div className={styles.selectGroup}>
                <Typography variant="subtitle1" component="h4">
                  انتخاب کاربر
                </Typography>

                <ChooseUser multiple={false} />
              </div>
            </div>
          </div>

          <div className={styles.textEditor}>
            <TextEditor
              height={textEditorHeight}
              setHeight={setTextEditorHeight}
              isLoading={referItemMutation.isLoading || isUploading}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateItemSidebar;
