import { forwardRef } from "react";

import { AvatarIcon } from "../../icons";
import styles from "./styles.module.scss";
import { type IAvatarProps } from "./types";

const Avatar = forwardRef(
  ({ className = "", profile, ...props }: IAvatarProps, ref) => {
    return (
      <div
        className={`${className} ${styles.wrapper}`}
        ref={ref as any}
        {...props}
      >
        {typeof profile === "string" && profile !== "" ? (
          <img src={profile} alt={profile} />
        ) : (
          <AvatarIcon />
        )}
      </div>
    );
  }
);

export default Avatar;
