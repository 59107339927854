import Pusher from "pusher-js";

const socketConfig = () => {
  return new Pusher("KJGshadiaAJGHLIHjkLSAK67fa7sf87", {
    forceTLS: true,
    enabledTransports: ["ws"],
    cluster: "mt1",
    wsHost: "ws.kareman.online",
    wsPort: 443,
  });
};

export default socketConfig;
