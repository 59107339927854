import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment-jalaali";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { FadeLoader } from "react-spinners";
import * as yup from "yup";

import {
  Avatar,
  Button,
  Message,
  OnlineStatus,
  TextEditor,
  Typography,
} from "../../components";
import { useGlobalContext } from "../../context";
import { queryKeys } from "../../data";
import { useTextEditorUploader } from "../../hooks";
import {
  useChatMessagesQuery,
  useChatSeenMessagesMutation,
  useChatSendMessageMutation,
  useChatUsersQuery,
  useSettingUsersQuery,
  useUserQuery,
} from "../../queries";
import { type IMessage } from "../../requests/types";
import styles from "./styles.module.scss";

const yupSchema = yup.object({
  file1: yup.mixed().nullable(),
  file2: yup.mixed().nullable(),
  file3: yup.mixed().nullable(),
  file4: yup.mixed().nullable(),
  voice: yup.mixed().nullable(),
  description: yup.string().required("پیام را وارد کنید"),
});

const DetailsChatSidebar = () => {
  const [messages, setMessages] = useState<IMessage[]>([]);

  const {
    handleChangeSidebarType,
    activeChatId,
    sidebarType,
    handleSetActiveChatId,
  } = useGlobalContext();
  const queryClient = useQueryClient();
  const userQuery = useUserQuery();
  const settingUsersQuery = useSettingUsersQuery({ limit: 10000, offset: 0 });
  const messagesQuery = useChatMessagesQuery(
    { id: activeChatId },
    sidebarType === "details-chat"
  );
  const sendMessageMutation = useChatSendMessageMutation();
  const seenMessagesMutation = useChatSeenMessagesMutation();
  const chatUsersQuery = useChatUsersQuery();

  useEffect(() => {
    if (sidebarType !== "details-chat" || typeof activeChatId !== "number") {
      return;
    }

    seenMessagesMutation.mutate({ user_id: activeChatId });
  }, [messagesQuery.data, activeChatId, sidebarType]);

  useEffect(() => {
    return () => {};
  }, []);

  const handleScrollToEnd = () => {
    const messagesWrapper = document.getElementById(
      "messages-wrapper"
    ) as HTMLDivElement;

    if (messagesWrapper === null) return;

    messagesWrapper.scrollTo({
      top: messagesWrapper.scrollTop + messagesWrapper.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(handleScrollToEnd, [messages]);

  useEffect(() => {
    setMessages(messagesQuery.data?.response.messages ?? []);
  }, [messagesQuery.data]);

  const findMyUser = settingUsersQuery?.data?.response?.users?.find(
    (u) => u.id === userQuery?.data?.user?.id
  );
  const user = settingUsersQuery?.data?.response?.users?.find(
    (u) => u.id === activeChatId
  );
  const chatUser = chatUsersQuery?.data?.response?.users?.find(
    (u) => u.id === activeChatId
  );

  const methods = useForm({
    defaultValues: {
      description: "",
      file1: null,
      file2: null,
      file3: null,
      file4: null,
      voice: null,
    },
    resolver: yupResolver(yupSchema),
  });
  const { handleUpload, isUploading } = useTextEditorUploader(methods as any);

  const [textEditorHeight, setTextEditorHeight] = useState<number>(233);

  const handleSubmitForm = async () => {
    const values = methods.getValues();

    const resultUploader = await handleUpload();

    void sendMessageMutation.mutateAsync({
      receiver_id: activeChatId,
      message: values.description,
      file_1: resultUploader?.file_1?.token ?? null,
      file_2: resultUploader?.file_2?.token ?? null,
      file_3: resultUploader?.file_3?.token ?? null,
      file_4: resultUploader?.file_4?.token ?? null,
      voice: resultUploader?.voice?.token ?? null,
    });

    methods.reset();

    const media: any = [
      resultUploader?.file_1?.link,
      resultUploader?.file_2?.link,
      resultUploader?.file_3?.link,
      resultUploader?.file_4?.link,
    ].filter((i) => typeof i === "string");

    setMessages((prevState) => {
      const newMessages = [...prevState];

      void messagesQuery.refetch();

      newMessages.push({
        id: Math.random(),
        created_at: moment().format("jYYYY/jDD/MM HH:mm"),
        is_my_message: true,
        is_seen: false,
        media,
        message: values.description,
        voice: resultUploader?.voice?.link ?? null,
        receiver: {
          id: user?.id as any,
          name: user?.name ?? "",
          online: user?.online ?? false,
        },
        sender: {
          id: userQuery?.data?.user?.id as any,
          name: userQuery?.data?.user?.name ?? "",
          online: userQuery?.data?.user?.online ?? false,
        },
      });

      return newMessages;
    });

    void queryClient.refetchQueries({ queryKey: queryKeys.chatUsers });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Typography component="h3" variant="title">
              چت با کاربران
            </Typography>

            <Button
              buttonSize="medium2"
              type="button"
              color="secondary1"
              radius="high"
              onClick={() => {
                handleSetActiveChatId(-1);
                handleChangeSidebarType("chats");
              }}
            >
              بستن
            </Button>
          </div>

          <div className={styles.user}>
            <div className={styles.userInfo}>
              <Avatar profile={user?.profile} />

              <div className={styles.userInfoText}>
                <Typography variant="subtitle1">{user?.name}</Typography>

                <Typography variant="body1">
                  {user?.is_admin ? "مدیر" : "کاربر"}
                </Typography>
              </div>
            </div>

            <OnlineStatus
              isOnline={user?.online ?? false}
              text={user?.online ? undefined : chatUser?.last_online}
            />
          </div>

          <div
            className={styles.content}
            id="messages-wrapper"
            style={{ height: `calc(100vh - 149px - ${textEditorHeight}px)` }}
          >
            <div className={styles.chats}>
              {messagesQuery.isLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FadeLoader color="#29b9fc" />
                </div>
              ) : (
                messages.map((message) => (
                  <Message
                    createdAt={message.created_at}
                    voice={message.voice}
                    files={message.media}
                    isSeen={message.is_seen}
                    isMe={message.is_my_message}
                    key={message.id.toString()}
                  >
                    {message.message}
                  </Message>
                ))
              )}
            </div>
          </div>

          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          {(chatUser?.can_chat || findMyUser?.is_admin || user?.is_admin) && (
            <div className={styles.textEditor}>
              <TextEditor
                height={textEditorHeight}
                setHeight={setTextEditorHeight}
                isLoading={sendMessageMutation.isLoading || isUploading}
              />
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default DetailsChatSidebar;
