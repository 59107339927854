import { useFormContext } from "react-hook-form";

import {
  Button,
  CollapseCard,
  RHFDatePickerField,
  RHFSelect,
  TextField,
} from "../../../../components";
import {
  useSettingGroupsQuery,
  useSettingUsersQuery,
  useSubjectsQuery,
} from "../../../../queries";
import {
  handleTransformFilterItems,
  normalizeFilteringField,
} from "../../../../utils";
import { convertJalaliToMiladi } from "../../../../utils/normalizeDate";
import styles from "./styles.module.scss";

const Filter = () => {
  const methods = useFormContext();
  const subjectsQuery = useSubjectsQuery({ type: "notification" });
  const groupsQuery = useSettingGroupsQuery({
    offset: 0,
    limit: 10000,
    my_groups: true,
  });
  const usersQuery = useSettingUsersQuery({ offset: 0, limit: 10000 });

  const subjects = subjectsQuery?.data?.response?.subjects ?? [];
  const groups = groupsQuery?.data?.response?.groups?.groups ?? [];
  const users = usersQuery?.data?.response?.users ?? [];

  const from = methods.watch("createdTimeFrom");
  const to = methods.watch("createdTimeTo");

  const handleSubmit = () => {
    const watcher = methods.watch();

    methods.setValue("filters", {
      code: normalizeFilteringField(watcher.code),
      customer_phones: normalizeFilteringField([
        normalizeFilteringField(watcher.customersPhone),
      ]),
      subject_ids: normalizeFilteringField([
        normalizeFilteringField(watcher.subjectIds),
      ]),
      group_ids: normalizeFilteringField([
        normalizeFilteringField(watcher.groupIds),
      ]),
      user_ids: normalizeFilteringField([
        normalizeFilteringField(watcher.userIds),
      ]),
      created_time_from: normalizeFilteringField(
        convertJalaliToMiladi(watcher.createdTimeFrom)
      ),
      created_time_to: normalizeFilteringField(
        convertJalaliToMiladi(watcher.createdTimeTo)
      ),
      status:
        typeof normalizeFilteringField(watcher.status) === "number"
          ? watcher.status
            ? "TEMPORARY"
            : "PERMANENT"
          : undefined,
      types: normalizeFilteringField([normalizeFilteringField(watcher.types)]),
    });
  };

  return (
    <form
      className={styles.wrapper}
      onSubmit={methods.handleSubmit(handleSubmit)}
    >
      <CollapseCard contentClassName={styles.card}>
        <TextField placeholder="شناسه اعلان" name="code" />

        <TextField placeholder="مشتری" name="customersPhone" />

        <RHFSelect
          placeholder="نوع"
          name="types"
          options={handleTransformFilterItems([
            {
              label: "آموزشی",
              value: "educational",
            },
            {
              label: "خبری",
              value: "news",
            },
          ])}
        />

        <RHFSelect
          placeholder="موضوع"
          name="subjectIds"
          options={handleTransformFilterItems(
            subjects?.map((subject) => ({
              label: subject.name,
              value: subject.id,
            }))
          )}
        />

        <RHFSelect
          placeholder="گروه ها"
          name="groupIds"
          options={handleTransformFilterItems(
            groups.map((g) => ({ label: g.name, value: g.id }))
          )}
        />

        <div className={styles.row} />

        <RHFSelect
          name="userIds"
          placeholder="کاربران"
          options={handleTransformFilterItems(
            users.map((u) => ({ label: u.name, value: u.id }))
          )}
        />

        <RHFDatePickerField
          fromName="createdTimeFrom"
          toName="createdTimeTo"
          position="left"
        >
          <TextField
            name="time"
            placeholder="زمان"
            value={`${from ? `${from} ` : ""}${to ? `تا ${to}` : ""}`}
          />
        </RHFDatePickerField>

        <RHFSelect
          placeholder="وضعیت"
          name="status"
          options={handleTransformFilterItems([
            {
              label: "دائمی",
              value: 0,
            },
            {
              label: "زماندار",
              value: 1,
            },
          ])}
        />

        <div />

        <Button type="submit" buttonSize="medium1" radius="high">
          جستجو کن
        </Button>
      </CollapseCard>
    </form>
  );
};

export default Filter;
