import { forwardRef } from "react";

import { SeenIcon } from "../../icons";
import { normalizeDate } from "../../utils";
import Button from "../button";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IMessageProps } from "./types";

const Card = forwardRef(
  (
    {
      className = "",
      children,
      isMe,
      createdAt,
      voice,
      files,
      isSeen,
      ...props
    }: IMessageProps,
    ref
  ) => {
    return (
      <div
        className={`${className} ${styles.wrapper} ${
          isMe ? styles.wrapperMe : styles.wrapperUser
        }`}
        ref={ref as any}
        {...props}
      >
        <Typography variant="body1">{children}</Typography>

        <div className={styles.files}>
          {files
            .filter((i) => typeof i === "string" && i !== "")
            .map((file, fileIndex) => (
              <a
                href={file}
                key={fileIndex}
                download={true}
                target="_blank"
                rel="noreferrer"
              >
                <Button type="button" buttonSize="medium2" color="white">
                  فایل {typeof fileIndex === "number" ? fileIndex + 1 : ""}
                </Button>
              </a>
            ))}

          {typeof voice === "string" && (
            <a href={voice} download={true} target="_blank" rel="noreferrer">
              <Button type="button" buttonSize="medium2" color="white">
                صدا
              </Button>
            </a>
          )}
        </div>

        <div className={styles.footer}>
          <Typography variant="text2" component="span">
            {normalizeDate(createdAt)}
          </Typography>

          {isSeen && <SeenIcon />}
        </div>
      </div>
    );
  }
);

export default Card;
