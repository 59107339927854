import { apiUrls } from "../data";
import requestService from "../services/request";
import { type ISearchGetReq, type ISearchGetRes } from "./types";

export const searchGetRequest = async (
  payload: ISearchGetReq
): Promise<ISearchGetRes> => {
  const { data } = await requestService.post(apiUrls.search, payload);

  return data;
};
