import { forwardRef } from "react";

import { useExpireAt } from "../../hooks";
import { E_STATUS } from "../../types/status";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IBadgeStatusProps } from "./types";

export const getStatusText = (status: E_STATUS) => {
  switch (status) {
    case E_STATUS.PENDING:
      return "در انتظار";

    case E_STATUS.CHECKING:
      return "در بررسی";

    case E_STATUS.COMPLETED:
      return "تکمیل شده";

    default:
      return "";
  }
};

const BadgeStatus = forwardRef(
  ({ className = "", status, time, ...props }: IBadgeStatusProps, ref) => {
    const expireAt = useExpireAt(time);
    const text: string = getStatusText(status);
    let activeVariant: string = "";

    switch (status) {
      case E_STATUS.PENDING:
        activeVariant = styles.variantInfo;
        break;

      case E_STATUS.CHECKING:
        activeVariant = styles.variantPink;

        break;

      case E_STATUS.COMPLETED:
        activeVariant = styles.variantSuccess;
        break;

      default:
        break;
    }

    return (
      <div
        {...props}
        className={`${styles.wrapper} ${activeVariant} ${className}`}
        ref={ref as any}
      >
        <Typography variant="body1" component="span">
          {text}{" "}
          {status !== E_STATUS.COMPLETED && typeof time !== "undefined" && (
            <span style={{ display: "inline-block", width: 85 }}>
              {expireAt}
            </span>
          )}
        </Typography>
      </div>
    );
  }
);

export default BadgeStatus;
