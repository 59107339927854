import { type ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";

import Typography from "../typography";
import styles from "./styles.module.scss";

const AvatarUploader = () => {
  const methods = useFormContext();

  const pictureUrl = methods.watch("pictureUrl");

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files === null || typeof files === "undefined" || files.length < 1) {
      return;
    }

    methods.setValue("pictureFile", files[0]);
    methods.setValue("pictureUrl", URL.createObjectURL(files[0]));
  };

  return (
    <div className={styles.uploader}>
      <div className={styles.circle}>
        {typeof pictureUrl === "string" && <img src={pictureUrl} />}

        <input
          type="file"
          accept="image/*"
          multiple={false}
          onChange={handleUpload}
        />
      </div>

      <Typography variant="subtitle2">بارگذاری عکس جدید</Typography>
    </div>
  );
};

export default AvatarUploader;
