import { useMutation } from "react-query";

import { checkLoginTokenUserPostRequest } from "../../requests";
import { type ICheckLoginTokenUserPostReq } from "../../requests/types";

export const useCheckLoginTokenMutation = () => {
  return useMutation(
    async (variables: ICheckLoginTokenUserPostReq) =>
      await checkLoginTokenUserPostRequest(variables)
  );
};

export default useCheckLoginTokenMutation;
