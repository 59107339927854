import { forwardRef, useState } from "react";

import { useGlobalContext } from "../../context";
import { usePagination } from "../../hooks";
import { useGroupDeleteMutation, useSettingGroupsQuery } from "../../queries";
import Button from "../button";
import DeleteModal from "../deleteModal";
import Pagination from "../pagination";
import Table from "../table";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IGroupSettingTable } from "./types";

const GroupSettingTable = forwardRef(
  ({ className = "", items, ...props }: IGroupSettingTable, ref) => {
    const { activePage, handleSetLimit, handleSetOffset, limit, offset } =
      usePagination();
    const thList = [
      "شناسه",
      "نام گروه",
      "شروع شیفت",
      "پایان شیفت",
      "شیفت چند ساعته",
      "تعداد کاربر همزمان",
      "تعداد اعضا",
      "وضعیت",
      "عملیات",
    ];
    const { handleSetActiveGroupEditItem } = useGlobalContext();

    const deleteMutation = useGroupDeleteMutation();
    const groupsQuery = useSettingGroupsQuery({
      offset: 0,
      limit: 10000,
      my_groups: false,
    });

    const [deleteId, setDeleteId] = useState<number>(-1);

    return (
      <div>
        <DeleteModal
          title="حذف گروه"
          description="آیا از حذف گروه مطمئن هستید"
          isOpened={deleteId !== -1}
          handleClose={() => {
            setDeleteId(-1);
          }}
          isLoading={deleteMutation.isLoading}
          handleSubmit={() => {
            deleteMutation.mutate(
              { id: deleteId },
              {
                onSuccess() {
                  void groupsQuery.refetch();

                  setDeleteId(-1);
                },
              }
            );
          }}
        />

        <Table
          thList={thList}
          className={`${styles.wrapper} ${className}`}
          ref={ref}
          {...props}
        >
          {items.slice(offset * limit, limit * (offset + 1)).map((item) => (
            <tr key={item.id}>
              <Typography variant="body1" component="td">
                {item.id}
              </Typography>
              <Typography variant="body1" component="td">
                {item.name}
              </Typography>
              <Typography variant="body1" component="td">
                {item.startShift ?? "ندارد"}
              </Typography>
              <Typography variant="body1" component="td">
                {item.endShift ?? "ندارد"}
              </Typography>
              <Typography variant="body1" component="td">
                {item.shiftHours ?? "ندارد"}
              </Typography>
              <Typography variant="body1" component="td">
                {item.shiftConcurrentUsers ?? "ندارد"}
              </Typography>
              <Typography variant="body1" component="td">
                {item.shiftUsersCount ?? "ندارد"}
              </Typography>
              <Typography variant="body1" component="td">
                {item.status ? "فعال" : "غیرفعال"}
              </Typography>
              <Typography
                variant="body1"
                component="td"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  type="button"
                  buttonSize="small1"
                  color="secondary1"
                  style={{ minWidth: "80px", height: "40px" }}
                  onClick={() => {
                    handleSetActiveGroupEditItem(parseInt(item.id));
                  }}
                >
                  ویرایش
                </Button>

                <div style={{ width: 10 }} />

                <Button
                  type="button"
                  buttonSize="small1"
                  color="pink1"
                  style={{ minWidth: "80px", height: "40px" }}
                  onClick={() => {
                    setDeleteId(parseInt(item.id));
                  }}
                >
                  حذف
                </Button>
              </Typography>
            </tr>
          ))}
        </Table>

        <div
          style={{
            marginTop: "15px",
          }}
        >
          <Pagination
            activePage={activePage}
            handleChange={handleSetOffset}
            handleChangeLimit={handleSetLimit}
            limit={limit}
            totalItems={items.length}
          />
        </div>
      </div>
    );
  }
);

export default GroupSettingTable;
