import { type ElementType, type FC, forwardRef } from "react";

import styles from "./styles.module.scss";
import { type ITypographyProps } from "./types";

const Typography: FC<ITypographyProps> = forwardRef(
  (
    {
      component = "p",
      variant,
      className = "",
      textAlgin,
      direction,
      color = "dark1",
      noneSelection,
      children,
      textDecoration = "none",
      withIcon,
      style = {},
      href,
      ...args
    }: ITypographyProps,
    ref
  ) => {
    let Component: ElementType | "link" = component;

    if (component === "link") {
      Component = "a";
    }

    return (
      <Component
        ref={ref as any}
        style={{
          ...style,
          color: `var(--color-${color})`,
        }}
        className={`${styles.typography} ${
          styles[`typography-variant-${variant}`]
        } ${styles[`typography-align-${textAlgin ?? ""}`]} ${
          noneSelection ? styles["typography-none-selection"] : ""
        } ${
          component === "link" || component === "a"
            ? styles["typography-cursor-pointer"]
            : ""
        } ${styles[`typography-direction-${direction ?? ""}`]} ${
          typeof textDecoration === "string"
            ? styles[`typography-text-decoration-${textDecoration}`]
            : ""
        } ${withIcon ? styles["typography-with-icon"] : ""} ${className}`}
        href={component === "a" ? href : undefined}
        {...args}
      >
        {children}
      </Component>
    );
  }
);

export default Typography;
