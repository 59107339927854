import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaStop } from "react-icons/fa";
import { useReactMediaRecorder } from "react-media-recorder";
import { toast } from "react-toastify";

import { SpeechIcon, VoiceIcon } from "../../icons";
import Button from "../button";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type ITextEditorProps } from "./types";

const MAX_RESIZE_PERCENT = 65;

const TextEditor = ({
  className = "",
  height,
  setHeight,
  minHeight = 233,
  style = {},
  isLoading,
  ...props
}: ITextEditorProps) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const methods = useFormContext();
  const voice = methods.watch("voice");
  const [recordingStatus, setRecordingStatus] = useState<
    "idle" | "recording" | "stop"
  >(voice === null ? "idle" : "stop");
  const { startRecording, stopRecording, clearBlobUrl, mediaBlobUrl } =
    useReactMediaRecorder({
      audio: true,
      video: false,
    });

  const fileWrapperRef = useRef<HTMLDivElement>();

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);

    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [height]);

  useEffect(() => {
    if (typeof mediaBlobUrl !== "string") {
      methods.setValue("voice", null);
      return;
    }

    void (async () => {
      const audioBlob = await fetch(mediaBlobUrl).then((r: any) => r.blob());
      const audio = new File([audioBlob], "voice.wav", { type: "audio/wav" });

      methods.setValue("voice", audio);
    })();
  }, [mediaBlobUrl]);

  function handleResizeWindow() {
    const browserHalfHeight = (window.innerHeight * MAX_RESIZE_PERCENT) / 100;

    if (height < browserHalfHeight) return;

    setHeight(browserHalfHeight);
  }

  const handleMouseDown = () => {
    window.addEventListener("mousemove", handleMouseMove);

    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = () => {
    window.removeEventListener("mousemove", handleMouseMove);
  };

  const handleMouseMove = (e: any) => {
    if (typeof wrapperRef.current === "undefined") return;

    const newHeight =
      wrapperRef.current.getBoundingClientRect().bottom - e.pageY;
    const browserHalfHeight = (window.innerHeight * MAX_RESIZE_PERCENT) / 100;

    if (newHeight >= browserHalfHeight || newHeight <= minHeight) return;

    setHeight(newHeight);
  };

  const handleUploadFile = () => {
    if (!fileWrapperRef.current) return;

    const files: HTMLInputElement[] = Array.from(
      fileWrapperRef.current.children
    ) as any;
    const watcher = methods.watch();

    for (const file of files) {
      const hasFile = watcher?.[file.name] !== null;

      if (hasFile && file.name === "file4") {
        toast("نمیتوانید بیشتر از ۴ فایل انتخاب کنید", {
          type: "error",
        });
      }

      if (hasFile) {
        continue;
      }

      file.click();
      break;
    }
  };

  const handleRemoveFile = (index: number) => {
    methods.setValue(`file${index + 1}`, null);
  };

  const handleRecordAudio = () => {
    switch (recordingStatus) {
      case "idle":
        setRecordingStatus("recording");
        startRecording();
        break;

      case "recording":
        stopRecording();
        setRecordingStatus("stop");

        break;

      case "stop":
        clearBlobUrl();
        setRecordingStatus("idle");
        break;

      default:
        break;
    }
  };

  const handleSetFile = (event: any, fileName: string) => {
    const files = event.target.files;

    if (files.length < 1) return;

    methods.setValue(fileName, files[0]);
  };

  const watcher = methods.watch();
  return (
    <div>
      <div
        style={{ position: "fixed", left: "-100%", visibility: "collapse" }}
        ref={fileWrapperRef as any}
      >
        <input
          type="file"
          name="file1"
          onChange={(event: any) => {
            handleSetFile(event, "file1");
          }}
          multiple={false}
        />
        <input
          type="file"
          name="file2"
          onChange={(event: any) => {
            handleSetFile(event, "file2");
          }}
          multiple={false}
        />
        <input
          type="file"
          name="file3"
          onChange={(event: any) => {
            handleSetFile(event, "file3");
          }}
          multiple={false}
        />
        <input
          type="file"
          name="file4"
          onChange={(event: any) => {
            handleSetFile(event, "file4");
          }}
          multiple={false}
        />
      </div>

      <div
        className={`${className} ${styles.wrapper}`}
        style={{ height, ...style }}
        ref={wrapperRef as any}
        {...props}
      >
        <div className={styles.move} onMouseDown={handleMouseDown} />

        <textarea
          {...methods.register("description")}
          onKeyUp={(event) => {
            if (!event.shiftKey && event.code === "Enter") {
              const btn = document.getElementById(
                "texteditor-button"
              ) as HTMLButtonElement;

              methods.setValue(
                "description",
                methods.getValues("description").replace(/\n$/, "")
              );

              btn.click();
              return;
            }
          }}
          placeholder="متن خود را بنویسید"
        />

        <div className={styles.fileButtons}>
          {Array(4)
            .fill(null)
            .map((_, index) => {
              const hasData = watcher[`file${index + 1}`] !== null;

              if (!hasData) return <div style={{ height: 26 }} />;
              return (
                <Button
                  key={index}
                  buttonSize="small2"
                  type="button"
                  color="white"
                  onClick={() => {
                    handleRemoveFile(index);
                  }}
                  className={styles.fileButtonsItem}
                >
                  فایل
                </Button>
              );
            })}
        </div>

        <div className={styles.mainButton}>
          <Button
            buttonSize="small2"
            type="submit"
            color="info1"
            id="texteditor-button"
            disabled={isLoading}
          >
            ارسال
          </Button>

          <div>
            <Button
              buttonSize="small2"
              type="button"
              color="white"
              onClick={handleUploadFile}
            >
              آپلود فایل
            </Button>

            <Button
              buttonSize="small2"
              type="button"
              color="info1"
              className={styles.blueButton}
              style={{
                backgroundColor:
                  recordingStatus === "stop" ? "#00debd" : "transparent",
                color: recordingStatus === "stop" ? "#fff" : "#29b9fc",
                borderColor: recordingStatus === "stop" ? "#00debd" : "#29b9fc",
              }}
              onClick={handleRecordAudio}
            >
              {(recordingStatus === "idle" || recordingStatus === "stop") && (
                <span>
                  <VoiceIcon
                    color={recordingStatus === "stop" ? "#fff" : undefined}
                  />
                </span>
              )}
              {recordingStatus === "recording" && (
                <span>
                  <FaStop />
                </span>
              )}
              صدا
              <i />
            </Button>

            <Button
              buttonSize="small2"
              type="button"
              color="info1"
              style={{ backgroundColor: "transparent", color: "#29b9fc" }}
              className={styles.blueButton}
            >
              <span>
                <SpeechIcon />
              </span>
              متن
              <i />
            </Button>
          </div>
        </div>
      </div>

      <ErrorMessage
        name="description"
        errors={methods.formState.errors}
        render={({ message }) => (
          <Typography
            variant="text2"
            style={{ paddingTop: 4, paddingBottom: 4 }}
            color="danger1"
          >
            {message}
          </Typography>
        )}
      />
    </div>
  );
};

export default TextEditor;
