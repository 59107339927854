import { apiUrls } from "../data";
import requestService from "../services/request";
import {
  type IReqGet,
  type ISettingsGroupGetReq,
  type ISettingsGroupGetRes,
  type ISettingsSubjectCreateReq,
  type ISettingsSubjectCreateRes,
  type ISettingsTypeCreateReq,
  type ISettingsTypeCreateRes,
  type ISettingsUserCreateReq,
  type ISettingsUserCreateRes,
  type ISettingsUserGetRes,
} from "./types";

export const settingsSubjectCreatePost = async (
  reqData: ISettingsSubjectCreateReq
): Promise<ISettingsSubjectCreateRes> => {
  const { data } = await requestService.post(
    apiUrls.settings.cerateSubject,
    reqData
  );

  return data;
};

export const settingsTypeCreatePost = async (
  reqData: ISettingsTypeCreateReq
): Promise<ISettingsTypeCreateRes> => {
  const { data } = await requestService.post(
    apiUrls.settings.createType,
    reqData
  );

  return data;
};

export const settingsUserCreatePost = async (
  reqData: ISettingsUserCreateReq
): Promise<ISettingsUserCreateRes> => {
  const { data } = await requestService.post(
    apiUrls.settings.createUser,
    reqData
  );

  return data;
};

export const settingsUserGet = async (
  params: IReqGet
): Promise<ISettingsUserGetRes> => {
  const { data } = await requestService.get(apiUrls.settings.getUsers, {
    params,
  });

  return data;
};

export const settingsGroupCreatePost = async (reqData: any) => {
  const { data } = await requestService.post(
    apiUrls.settings.createGroup,
    reqData
  );

  return data;
};

export const settingsGroupsGet = async (
  reqData: ISettingsGroupGetReq
): Promise<ISettingsGroupGetRes> => {
  const { data } = await requestService.get(apiUrls.settings.getGroups, {
    params: reqData,
  });

  return data;
};

export const subjectDeleteRequest = async (id: number) => {
  const { data } = await requestService.delete(apiUrls.subjects.subjectDelete, {
    params: {
      subject_id: id,
    },
  });

  return data;
};

export const groupDeleteRequest = async (id: number) => {
  const { data } = await requestService.delete(apiUrls.groups.groupDelete, {
    params: {
      group_id: id,
    },
  });

  return data;
};

export const userDeleteRequest = async (id: number) => {
  const { data } = await requestService.delete(apiUrls.users.userDelete, {
    params: {
      user_id: id,
    },
  });

  return data;
};
