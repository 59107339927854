import { forwardRef } from "react";

import { normalizeDate } from "../../utils";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type INotificationItemProps } from "./types";

const NotificationItem = forwardRef(
  (
    {
      className = "",
      description,
      date,
      title,
      ...props
    }: INotificationItemProps,
    ref
  ) => {
    return (
      <div
        className={`${className} ${styles.wrapper}`}
        ref={ref as any}
        {...props}
      >
        <div className={styles.item}>
          <Typography variant="subtitle2" component="h4">
            {title}
          </Typography>

          <Typography variant="text1" className={styles.date}>
            {normalizeDate(date)}
          </Typography>
        </div>

        <Typography variant="body1">{description}</Typography>
      </div>
    );
  }
);

export default NotificationItem;
