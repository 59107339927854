import { useMutation } from "react-query";

import { seenMessagesChatPostReq } from "../../requests";
import { type ISeenMessagesChatPostReq } from "../../requests/types";

const useChatSeenMessagesMutation = () => {
  return useMutation(
    async (variables: ISeenMessagesChatPostReq) =>
      await seenMessagesChatPostReq(variables)
  );
};

export default useChatSeenMessagesMutation;
