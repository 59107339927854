import { type PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { localStorageKeys } from "./data";
import { Loading } from "./layouts";
import {
  useChatUsersQuery,
  useSettingGroupsQuery,
  useSettingUsersQuery,
  useUserNotificationsQuery,
  useUserQuery,
} from "./queries";

const RouterGuard = ({
  status,
  children,
  redirect,
  userType,
}: PropsWithChildren<{
  status: "authorize" | "unauthorize" | "check";
  userType?: "user" | "admin";
  redirect?: string;
}>) => {
  const navigate = useNavigate();

  const userQuery = useUserQuery();
  const groupsQuery = useSettingGroupsQuery({
    limit: 10000,
    offset: 0,
    my_groups: true,
  });
  const usersQuery = useSettingUsersQuery({ limit: 10000, offset: 0 });
  const chatUsersQuery = useChatUsersQuery();
  const userNotificationsQuery = useUserNotificationsQuery({
    limit: 10000,
    offset: 0,
  });

  const findUser = usersQuery.data?.response.users.find(
    (u) => u.id === userQuery?.data?.user?.id
  );

  useEffect(() => {
    if (typeof userType === "undefined") return;
    if (userType === "admin" && findUser?.is_admin) return;
    if (userType === "user" && !findUser?.is_admin) return;

    navigate("/");
  }, [userQuery, userType]);

  useEffect(() => {
    if (status === "authorize") {
      authorize();
      return;
    }

    if (status === "unauthorize") {
      unauthorize();
      return;
    }

    if (status === "check") {
      check();
      return;
    }
  }, [status, redirect]);

  function authorize() {
    const token = localStorage.getItem(localStorageKeys.apiAuthorization);

    if (typeof token === "string") return;

    navigate("/login");
  }

  function unauthorize() {
    const token = localStorage.getItem(localStorageKeys.apiAuthorization);

    if (typeof token !== "string") return;

    navigate("/");
  }

  function check() {
    return;
  }

  if (
    userQuery.isLoading ||
    userQuery.isError ||
    groupsQuery.isLoading ||
    usersQuery.isLoading ||
    userNotificationsQuery.isLoading ||
    chatUsersQuery.isLoading
  ) {
    return <Loading />;
  }
  if (
    (userType === "admin" && !findUser?.is_admin) ??
    (userType === "user" && findUser?.is_admin)
  ) {
    return null;
  }
  return children;
};

export default RouterGuard;
