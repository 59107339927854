import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment-jalaali";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import {
  AttrFields,
  Button,
  ChooseUser,
  CustomerList,
  RHFSelect,
  RHFTimePickerField,
  Switch,
  TextEditor,
  Typography,
} from "../../components";
import ChooseGroup from "../../components/chooseGroup";
import { useGlobalContext } from "../../context";
import { announcementTypesData, customerSearchKey } from "../../data";
import { usePagination, useTextEditorUploader } from "../../hooks";
import { CheckIcon } from "../../icons";
import { AddAnnouncement } from "../../pages/settings/components";
import {
  useNotificationCreateMutation,
  useNotificationsQuery,
  useSettingUsersQuery,
  useSubjectsQuery,
  useUserQuery,
} from "../../queries";
import styles from "./styles.module.scss";

const resolver = yup.object().shape({
  activeGroupsId: yup
    .array()
    .of(yup.object().shape({ value: yup.number().required() }).required())
    .min(1, "حداقل یک گروه باید  انتخاب شود"),
  usersId: yup
    .array()
    .of(yup.object().shape({ value: yup.number().required() }).required()),
  customer_phones: yup.array().of(
    yup
      .object()
      .shape({
        phoneNumber: yup.string().notRequired(),
      })
      .required()
  ),
  type: yup
    .string()
    .oneOf(["educational", "news"])
    .required("نوع اعلان را انتخاب کنید"),
  subjectId: yup.number().required("موضوع اعلان را انتخاب کنید"),
  file1: yup.mixed().nullable(),
  file2: yup.mixed().nullable(),
  file3: yup.mixed().nullable(),
  file4: yup.mixed().nullable(),
  voice: yup.mixed().nullable(),
  attrs: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      key: yup.string().required(),
      value: yup.string().required("این فیلد اجباری میباشد"),
    })
  ),
  expireAt: yup.string(),
  description: yup.string().required("توضیحات را وارد کنید"),
});

const CreateAnnouncementSidebar = () => {
  const { handleChangeSidebarType } = useGlobalContext();
  const [textEditorHeight, setTextEditorHeight] = useState<number>(233);
  const subjectsQuery = useSubjectsQuery({ type: "notification" });
  const [isOpenedCreateSubjectModal, setIsOpenedCreateSubjectModal] =
    useState<boolean>(false);
  const notificationCreateMutation = useNotificationCreateMutation();
  const { limit, activePage } = usePagination();
  const notificationsQuery = useNotificationsQuery({
    offset: activePage,
    limit,
  });
  const [enabled, setEnabled] = useState<boolean>(false);

  const userQuery = useUserQuery();
  const settingUsersQuery = useSettingUsersQuery({ offset: 0, limit: 10000 });
  const findUser = settingUsersQuery?.data?.response?.users?.find(
    (u) => u.id === userQuery?.data?.user?.id
  );
  const navigate = useNavigate();
  const location = useLocation();

  const methods = useForm({
    defaultValues: {
      activeGroupsId: [],
      usersId: [],
      customer_phones: [{ phoneNumber: "" }, { phoneNumber: "" }],
      subjectId: undefined,
      type: undefined,
      description: "",
      file1: null,
      file2: null,
      file3: null,
      file4: null,
      voice: null,
      expireAt: "",
      attrs: [],
    },
    resolver: yupResolver(resolver),
  });

  const { handleUpload, isUploading } = useTextEditorUploader(methods as any);
  const selectedSubject = methods?.watch("subjectId") ?? [];
  const selectedGroups = methods?.watch("activeGroupsId") ?? [];
  const subjects = subjectsQuery.data?.response.subjects ?? [];
  const filteredSubjects = subjects.filter((subject) => {
    if (!subject.is_active) {
      return false;
    }

    const selectedGroups = methods?.watch("activeGroupsId") ?? [];
    let isValid = true;

    selectedGroups.forEach((g) => {
      if (!isValid) return;

      isValid = subject.groups.some((sg) => sg.id === g.value);
    });

    return isValid;
  });

  const mappedSubject = filteredSubjects.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const attrs = filteredSubjects?.find(
    (s) => s.id === selectedSubject
  )?.attributes;

  useEffect(() => {
    methods.setValue(
      "attrs",
      attrs?.map((attr) => ({
        key: attr.key,
        name: attr.name,
        value: "",
      }))
    );
  }, [attrs]);

  useEffect(() => {
    if (!enabled) return;
    if (location.pathname === `/search/${customerSearchKey}`) return;

    navigate(`/search/${customerSearchKey}`);
  }, [enabled, location]);

  const handleSubmit = async () => {
    if (notificationCreateMutation.isLoading) return;
    const values = methods.getValues();

    const date = new Date();
    const isValidExpireAt =
      typeof values.expireAt !== "undefined" && values.expireAt !== "";

    if (isValidExpireAt) {
      const time = values.expireAt?.split(":") ?? [];
      date.setHours(date.getHours() + Number(time[0]));
      date.setMinutes(date.getMinutes() + Number(time[1]));
    }

    const resultUploader = await handleUpload();

    notificationCreateMutation.mutate(
      {
        type: values.type,
        subject_id: values.subjectId,
        user_ids: values.usersId?.map((i) => i.value) as number[],
        description: values.description.trim(),
        group_ids: values.activeGroupsId?.map((item) => item.value) as number[],
        customer_phones: values.customer_phones
          ?.map((item) => item.phoneNumber)
          ?.filter((i) => i?.trim() !== "") as string[],
        file_1: resultUploader?.file_1?.token ?? null,
        file_2: resultUploader?.file_2?.token ?? null,
        file_3: resultUploader?.file_3?.token ?? null,
        file_4: resultUploader?.file_4?.token ?? null,
        voice: resultUploader?.voice?.token ?? null,
        expire_at: isValidExpireAt
          ? moment(date).format("YYYY/MM/DD HH:mm")
          : null,
        attributes: values.attrs ?? [],
      },
      {
        onSuccess() {
          void notificationsQuery.refetch();

          handleChangeSidebarType("main");

          toast("اعلان جدید ایجاد شد", {
            type: "success",
          });
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <AddAnnouncement
        isOpened={isOpenedCreateSubjectModal}
        handleClose={() => {
          setIsOpenedCreateSubjectModal(false);
        }}
      />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <div
            style={{
              height: `calc(100vh - 15px - ${textEditorHeight}px)`,
              overflow: "auto",
            }}
          >
            <div className={styles.header}>
              <Typography component="h3" variant="title">
                ایجاد اعلان جدید
              </Typography>

              <Button
                buttonSize="medium2"
                type="button"
                color="secondary1"
                radius="high"
                onClick={() => {
                  handleChangeSidebarType("main");
                }}
              >
                بستن
              </Button>
            </div>

            <div className={styles.content}>
              <div className={styles.selectGroup}>
                <Typography variant="subtitle1" component="h4">
                  انتخاب گروه
                </Typography>

                <ChooseGroup myGroup />
              </div>

              <div className={styles.selectUser}>
                <Typography variant="subtitle1" component="h4">
                  انتخاب کاربر
                </Typography>

                <ChooseUser />
              </div>

              <div className={styles.selectType}>
                <RHFSelect
                  placeholder="نوع"
                  label="نوع"
                  name="type"
                  position="top"
                  options={announcementTypesData}
                />
              </div>

              <div className={styles.selectSubject}>
                <div className={styles.selectSubjectHeader}>
                  <Typography variant="subtitle1" component="h4">
                    موضوع
                  </Typography>

                  {findUser?.is_admin && (
                    <Button
                      buttonSize="medium1"
                      type="button"
                      color="info1"
                      onClick={() => {
                        setIsOpenedCreateSubjectModal(true);
                      }}
                    >
                      <CheckIcon width={15} height={15} />
                    </Button>
                  )}
                </div>

                <RHFSelect
                  name="subjectId"
                  options={mappedSubject}
                  disabled={selectedGroups.length < 1}
                  placeholder="موضوع را انتخاب کنید"
                  position="top"
                />
              </div>

              <div className={styles.expireAt}>
                <RHFTimePickerField
                  placeholder="ورود زمان اختیاری است"
                  label="زمان فعال بودن"
                  name="expireAt"
                  startFromZero
                />
              </div>

              <AttrFields />

              <div className={styles.selectCustomers}>
                <div className={styles.selectCustomersHeader}>
                  <Typography variant="subtitle1" component="h4">
                    مشتری
                  </Typography>

                  <div>
                    <Typography variant="body1" component="span">
                      جستجو
                    </Typography>
                    <Switch
                      handleSwitch={() => {
                        setEnabled((prevState) => !prevState);
                      }}
                      enabled={enabled}
                    />{" "}
                  </div>
                </div>

                <div>
                  <CustomerList />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.textEditor}>
            <TextEditor
              height={textEditorHeight}
              setHeight={setTextEditorHeight}
              isLoading={notificationCreateMutation.isLoading || isUploading}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateAnnouncementSidebar;
