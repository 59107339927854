import { type PropsWithChildren } from "react";

import { HeaderLayout } from "..";
import styles from "./styles.module.scss";

const PageLayout = ({ children }: PropsWithChildren) => {
  return (
    <main className={styles.wrapper}>
      <HeaderLayout />

      <div>{children}</div>
    </main>
  );
};

export default PageLayout;
