const NormalLine = () => {
  return (
    <svg
      width="2"
      height="38"
      viewBox="0 0 2 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1V37"
        stroke="#2A3A65"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="10 10"
      />
    </svg>
  );
};

export default NormalLine;
