import { AiOutlineMenu } from "react-icons/ai";
import { BsChatLeftText } from "react-icons/bs";

import { useGlobalContext } from "../../context";
import styles from "./styles.module.scss";

const Header = () => {
  const { handleShowMenubar, handleShowSidebar } = useGlobalContext();

  return (
    <>
      <div className={styles.box} />

      <header className={styles.header}>
        <BsChatLeftText size={30} onClick={handleShowSidebar} />

        <AiOutlineMenu size={30} onClick={handleShowMenubar} />
      </header>
    </>
  );
};

export default Header;
