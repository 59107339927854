const apiUrls = {
  uploadFile: "/v1/upload",
  search: "/v1/search",
  auth: {
    loginPost: "/v1/login",
    checkLoginToken: "/v1/checkLoginToken",
    userGet: "/v1/user",
    logout: "/v1/logout",
  },
  userNotifications: {
    userNotificationsGet: "/v1/user_notifications",
    userNotificationSeenPost: "/v1/user_notifications_seen",
  },
  subjects: {
    subjectsGet: "/v1/subject",
    subjectDelete: "/v1/setting/subject/delete",
  },
  salaries: {
    walletsGet: "/v1/salary/getAll",
    walletAdd: "/v1/salary/create",
  },
  vacations: {
    vacationsGet: "/v1/vacation/getAll",
    vacationAdd: "/v1/vacation/create",
  },
  chats: {
    users: "/v1/chat/users",
    messages: "/v1/chat/messages",
    sendMessage: "/v1/chat/send-message",
    seenMessages: "/v1/chat/seen-messages",
  },
  notifications: {
    notificationsGet: "/v1/notification",
    notificationCreate: "/v1/notification/create",
    detailsNotificationGet: "/v1/notification/detail",
    newNotification: "/v1/notification/new-description",
    addExpiration: "/v1/notification/update-expiration",
  },
  items: {
    itemCreate: "/v1/item/create-or-update",
    itemsGet: "/v1/item",
    detailsItemGet: "v1/item/get",
    finishItem: "v1/item/finish",
    answerItem: "v1/item/answer",
    setExpirationItem: "v1/item/update-expiration",
    referItem: "v1/item/refer",
    itemDelete: "v1/setting/item/delete",
  },
  groups: {
    groupsGet: "/v1/group/get-my-groups",
    detailsGroupGet: "/v1/group",
    shiftsPlan: "v1/group/shift-plans",
    groupDelete: "/v1/setting/group/delete",
  },
  users: {
    chats: "/v1/chat/users",
    userDelete: "/v1/setting/user/delete",
  },
  settings: {
    cerateSubject: "/v1/setting/subject/update_or_create",
    createType: "/v1/setting/type/update_or_create",
    createUser: "/v1/users/update_or_create",
    createGroup: "/v1/setting/group/create-or-update",
    getGroups: "/v1/setting/group/get",
    groups: "/v1/setting/group/get",
    getUsers: "/v1/setting/user/get",
  },
};

export default apiUrls;
