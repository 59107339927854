import { forwardRef } from "react";

import { useExpireAt } from "../../hooks";
import { LineIcon } from "../../icons";
import { E_STATUS } from "../../types/status";
import { normalizeDate } from "../../utils";
import Button from "../button";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IDetailsCardProps } from "./types";

const DetailsCard = forwardRef(
  (
    {
      className = "",
      text,
      status,
      writer,
      users,
      time,
      isShowSetTime,
      completedAt,
      completedBy,
      info,
      files,
      isEndMessage,
      createdAt,
      voice,
      isShowButtons,
      handleAnswerItem,
      handleReferItem,
      attributes,
      handleSetExpirationTime,
      ...props
    }: IDetailsCardProps,
    ref
  ) => {
    const expireAt = useExpireAt(time);

    return (
      <div
        className={`${className} ${styles.wrapper}`}
        ref={ref as any}
        {...props}
      >
        <div className={styles.card}>
          <div className={styles.text}>
            {users.length >= 1 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                {users.map((user, index) => (
                  <div
                    key={index}
                    style={{
                      height: 28,
                      paddingLeft: 16,
                      paddingRight: 16,
                      background: "#82D6F7",
                      color: "#2A3A65",
                      fontSize: 14,
                      marginRight: index === 0 ? 0 : 13,
                      borderRadius: 25,
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {user}
                  </div>
                ))}
              </div>
            )}

            <Typography variant="body1" color="white" textAlgin="right">
              <Typography
                variant="body1"
                component="span"
                color="white"
                style={{ fontWeight: "bold", paddingLeft: 7 }}
                textAlgin="right"
              >
                {writer}
              </Typography>{" "}
              <Typography
                variant="body1"
                color="white"
                textAlgin="right"
                component="span"
                dangerouslySetInnerHTML={{
                  __html: text.replace(/\n/g, "<br />"),
                }}
              />
            </Typography>

            {attributes.length >= 1 && (
              <ul style={{ marginTop: 10 }}>
                {attributes.map((attr) => (
                  <Typography
                    variant="body2"
                    component="li"
                    color="white"
                    key={attr.key}
                  >
                    {attr.name}: {attr.value}
                  </Typography>
                ))}
              </ul>
            )}

            <Typography
              variant="body1"
              color="white"
              textAlgin="left"
              style={{ fontWeight: 200 }}
            >
              {normalizeDate(createdAt)}
            </Typography>
          </div>

          <div className={styles.buttons}>
            <div className={styles.files}>
              {files
                .filter((item) => typeof item === "string" && item !== "")
                .map((file, fileIndex) => (
                  <a
                    href={file}
                    key={fileIndex}
                    download={true}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button type="button" buttonSize="medium2" color="white">
                      فایل {typeof fileIndex === "number" ? fileIndex + 1 : ""}
                    </Button>
                  </a>
                ))}

              {typeof voice === "string" && (
                <a
                  href={voice}
                  download={true}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button type="button" buttonSize="medium2" color="white">
                    صدا
                  </Button>
                </a>
              )}
            </div>

            {isShowButtons && status.key !== E_STATUS.COMPLETED && (
              <div className={styles.buttonsGeneral}>
                {isShowSetTime && (
                  <Button
                    type="button"
                    buttonSize="medium2"
                    color="pink1"
                    onClick={handleSetExpirationTime}
                  >
                    زمان
                  </Button>
                )}

                <Button
                  type="button"
                  buttonSize="medium2"
                  color="gold1"
                  onClick={handleReferItem}
                >
                  انتقال
                </Button>

                <Button
                  type="button"
                  buttonSize="medium2"
                  color="info2"
                  onClick={handleAnswerItem}
                >
                  پاسخ
                </Button>
              </div>
            )}
          </div>
        </div>

        {typeof info === "object" && (
          <div className={styles.info}>
            <div>
              <LineIcon className={styles.line} />

              <div
                className={`${styles.badgeTime} ${
                  status.key === E_STATUS.COMPLETED && isEndMessage
                    ? styles.badgeTimeSuccess
                    : ""
                }`}
              >
                <Typography variant="text1">
                  {status.key === E_STATUS.COMPLETED && isEndMessage
                    ? normalizeDate(completedAt ?? "")
                    : info.moveAt}
                </Typography>
              </div>
            </div>

            <div className={styles.infoContent}>
              {status.key === E_STATUS.COMPLETED && isEndMessage ? (
                <Typography variant="text1" color="secondary2">
                  اتمام توسط:{" "}
                  <Typography variant="text1" component="span">
                    {completedBy}
                  </Typography>
                </Typography>
              ) : (
                <Typography variant="text1" color="secondary2">
                  انتقال به: ‌
                  {typeof info.moveTo === "string" && (
                    <Typography variant="text1" component="span">
                      {info.moveTo}
                    </Typography>
                  )}
                </Typography>
              )}

              <Typography variant="text1">{info.group}</Typography>

              {typeof info.seenAt === "string" && (
                <Typography variant="text1">{info.seenAt}</Typography>
              )}

              {typeof time !== "undefined" &&
              status.key !== E_STATUS.COMPLETED ? (
                <div className={styles.badgeFinishedTime}>
                  <div>
                    <Typography variant="text1">
                      زمان تقریبی انتظار
                      <Typography
                        variant="text1"
                        component="p"
                        style={{
                          paddingRight: "10px",
                          width: 85,
                          display: "inline-block",
                        }}
                      >
                        {expireAt}
                      </Typography>
                    </Typography>
                  </div>
                </div>
              ) : (
                <div style={{ width: 230 }} />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default DetailsCard;
