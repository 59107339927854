import { apiUrls } from "../data";
import requestService from "../services/request";
import {
  type IMessagesChatGetReq,
  type IMessagesChatGetRes,
  type ISeenMessagesChatPostReq,
  type ISendMessageChatPostReq,
  type IUsersChatGetRes,
} from "./types";

export const usersChatGetReq = async (): Promise<IUsersChatGetRes> => {
  const { data } = await requestService.get(apiUrls.chats.users, {
    params: {
      offset: 0,
      limit: 10000,
    },
  });

  return data;
};

export const messagesChatGetReq = async (
  payload: IMessagesChatGetReq
): Promise<IMessagesChatGetRes> => {
  const { data } = await requestService.get(apiUrls.chats.messages, {
    params: {
      offset: 0,
      limit: 10000,
      id: payload.id,
    },
  });

  return data;
};

export const sendMessageChatPostReq = async (
  payload: ISendMessageChatPostReq
): Promise<any> => {
  const { data } = await requestService.post(
    apiUrls.chats.sendMessage,
    payload
  );

  return data;
};

export const seenMessagesChatPostReq = async (
  payload: ISeenMessagesChatPostReq
): Promise<any> => {
  const { data } = await requestService.post(
    apiUrls.chats.seenMessages,
    payload
  );

  return data;
};
