import { forwardRef } from "react";

import Button from "../button";
import styles from "./styles.module.scss";
import { type ISearchBoxProps } from "./types";

const SearchBox = forwardRef(
  (
    {
      className = "",
      placeholder = "ورود اطلاعات مشتری/کاربر",
      value,
      handleChange,
      handleSubmit,
      inputProps = {},
      ...props
    }: ISearchBoxProps,
    ref
  ) => {
    return (
      <form
        ref={ref as any}
        className={`${className} ${styles.searchBox}`}
        onSubmit={(e) => {
          e.preventDefault();

          if (value.trim() === "") return;

          handleSubmit(value);
        }}
        {...props}
      >
        <input
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={(event) => {
            handleChange(event.target.value);
          }}
          {...inputProps}
        />

        <Button type="submit" buttonSize="medium2" radius="high">
          جستجو
        </Button>
      </form>
    );
  }
);

export default SearchBox;
