import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment-jalaali";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";

import { Button, Modal, RHFTimePickerField } from "../../../../components";
import { queryKeys } from "../../../../data";
import { useSetExpirationTimeNotificationMutation } from "../../../../queries";

const resolver = yupResolver(
  yup.object().shape({
    time: yup.string().required("زمان تقریبی انتظار را وارد کنید"),
  })
);

const SetExpirationTimeModal = ({
  handleClose,
  isOpened,
  id,
}: {
  handleClose: () => void;
  isOpened: boolean;
  id: number;
}) => {
  const methods = useForm({
    defaultValues: {
      time: "",
    },
    resolver,
  });
  const queryClient = useQueryClient();
  const setExpirationTimeMutation = useSetExpirationTimeNotificationMutation();

  const handleSubmit = () => {
    const date = new Date();
    const time = methods.getValues("time")?.split(":");
    date.setHours(date.getHours() + Number(time[0]));
    date.setMinutes(date.getMinutes() + Number(time[1]));

    setExpirationTimeMutation.mutate(
      {
        notification_id: id,
        expire_at: moment(date).format("YYYY/MM/DD HH:mm"),
      },
      {
        onSuccess() {
          methods.reset();

          toast("زمان انتظار مورد ثبت شد", { type: "success" });

          void queryClient.refetchQueries({
            queryKey: queryKeys.notifications,
          });
          void queryClient.refetchQueries({
            queryKey: [queryKeys.detailsNotification, id],
          });

          handleClose();
        },
      }
    );
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpened={isOpened}
      title="تائین زمان انتظار"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <div
            style={{
              marginBottom: 30,
            }}
          >
            <RHFTimePickerField
              placeholder="زمان فعال بودن"
              label="زمان فعال بودن"
              name="time"
              startFromZero
            />
          </div>

          <div style={{ marginTop: "80px" }}>
            <Button
              buttonSize="medium2"
              style={{ minWidth: 200 }}
              type="submit"
              loading={setExpirationTimeMutation.isLoading}
              disabled={setExpirationTimeMutation.isLoading}
            >
              ثبت
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default SetExpirationTimeModal;
