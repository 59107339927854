import { type IIconProps } from "./types";

const AvatarIcon = ({ color = "#29B9FC", ...props }: IIconProps) => {
  return (
    <svg
      width="27"
      height="30"
      viewBox="0 0 27 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.0393 25.9559C26.0393 26.4032 25.9512 26.846 25.78 27.2592C25.6089 27.6724 25.358 28.0479 25.0417 28.3641C24.7255 28.6804 24.3501 28.9312 23.9369 29.1024C23.5237 29.2735 23.0808 29.3616 22.6335 29.3616H3.40674C2.9595 29.3618 2.51664 29.2738 2.10339 29.1028C1.69014 28.9317 1.31463 28.681 0.998291 28.3648C0.68195 28.0487 0.430918 27.6733 0.259644 27.2602C0.0883697 26.847 0.000131362 26.4042 5.92283e-08 25.9569C-0.000265173 23.7145 0.890291 21.5629 2.47571 19.9771C4.06113 18.3913 6.21159 17.5003 8.45398 17.5H17.5844C19.8267 17.5 21.9773 18.3908 23.5629 19.9764C25.1485 21.562 26.0393 23.7125 26.0393 25.9549V25.9559Z"
        fill={color}
      />
      <path
        d="M12.5392 15.0783C16.7029 15.0783 20.0782 11.7029 20.0782 7.53916C20.0782 3.3754 16.7029 0 12.5392 0C8.37542 0 5 3.3754 5 7.53916C5 11.7029 8.37542 15.0783 12.5392 15.0783Z"
        fill={color}
      />
    </svg>
  );
};

export default AvatarIcon;
