import { useMutation } from "react-query";

import { settingsSubjectCreatePost } from "../../requests";
import { type ISettingsSubjectCreateReq } from "../../requests/types";

export const useCreateSubjectMutation = () => {
  return useMutation(
    async (variables: ISettingsSubjectCreateReq) =>
      await settingsSubjectCreatePost(variables)
  );
};

export default useCreateSubjectMutation;
