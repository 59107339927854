import { useQuery } from "react-query";

import { queryKeys } from "../../data";
import { detailsGroupGetRequest } from "../../requests";
import { type IDetailsGroupGetReq } from "../../requests/types";

const useDetailsGroupQuery = (payload: IDetailsGroupGetReq) => {
  return useQuery(
    [queryKeys.detailsGroup, payload.id, payload.from, payload.to],
    async () => await detailsGroupGetRequest(payload),
    {
      refetchOnMount: true,
      refetchIntervalInBackground: true,
    }
  );
};

export default useDetailsGroupQuery;
