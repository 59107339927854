import { useQuery } from "react-query";

import { queryKeys } from "../../data";
import { shiftPlansGroupGetRequest } from "../../requests";

const useShiftsPlanGroupQuery = (groupId: number) => {
  return useQuery(
    [queryKeys.shiftsPlanGroup, groupId],
    async () => await shiftPlansGroupGetRequest(groupId),
    {
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      enabled: typeof groupId === "number" && groupId !== -1,
    }
  );
};

export default useShiftsPlanGroupQuery;
