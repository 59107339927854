import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Button } from "../../../../components";
import { useGlobalContext } from "../../../../context";
import { CheckIcon } from "../../../../icons";
import { routeNames } from "../../../../router";
import { AddGroup, AddItem, AddUser } from "..";
import AddAnnouncement from "../addAnnouncement";
import styles from "./styles.module.scss";

const Select = () => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    handleSetActiveUserEditItem,
    handleSetActiveSubjectAnnouncementEditItem,
    handleSetActiveGroupEditItem,
    handleSetActiveSubjectItemEditItem,
    activeUserEditId,
    activeGroupEditId,
    activeItemSubjectEditId,
    activeAnnouncementSubjectEditId,
  } = useGlobalContext();

  const [openedModal, setOpenedModal] = useState<
    "announcement" | "item" | "group" | "user" | null
  >(null);

  const iconColor = (isActive: boolean) => (isActive ? "#2a3a65" : "#29b9fc");

  const handleCloseModal = () => {
    handleSetActiveUserEditItem(-1);
    handleSetActiveSubjectAnnouncementEditItem(-1);
    handleSetActiveGroupEditItem(-1);
    handleSetActiveSubjectItemEditItem(-1);

    setOpenedModal(null);
  };

  const isActiveAnnouncementTab = params.pageName === "announcement";
  const isActiveItemTab = params.pageName === "item";
  const isActiveGroupTab = params.pageName === "group";
  const isActiveUserTab = params.pageName === "user";

  useEffect(() => {
    if (activeAnnouncementSubjectEditId === -1) return;
    if (openedModal === "announcement") return;

    setOpenedModal("announcement");
  }, [openedModal, activeAnnouncementSubjectEditId]);

  useEffect(() => {
    if (activeItemSubjectEditId === -1) return;
    if (openedModal === "item") return;

    setOpenedModal("item");
  }, [openedModal, activeItemSubjectEditId]);

  useEffect(() => {
    if (activeGroupEditId === -1) return;
    if (openedModal === "group") return;

    setOpenedModal("group");
  }, [openedModal, activeGroupEditId]);

  useEffect(() => {
    if (activeUserEditId === -1) return;
    if (openedModal === "user") return;

    setOpenedModal("user");
  }, [openedModal, activeUserEditId]);

  return (
    <div className={styles.wrapper}>
      {openedModal === "announcement" && (
        <AddAnnouncement handleClose={handleCloseModal} isOpened />
      )}

      {openedModal === "item" && (
        <AddItem handleClose={handleCloseModal} isOpened />
      )}

      {openedModal === "group" && (
        <AddGroup handleClose={handleCloseModal} isOpened />
      )}

      {openedModal === "user" && (
        <AddUser handleClose={handleCloseModal} isOpened />
      )}

      <Link to={routeNames.announcementSetting}>
        <Button
          type="button"
          buttonSize="large2"
          radius="high"
          color={isActiveAnnouncementTab ? "dark1" : "info1"}
          onClick={() => {
            navigate(routeNames.announcementSetting);
          }}
        >
          اعلانات
          <div
            onClick={() => {
              setOpenedModal("announcement");
            }}
          >
            <CheckIcon color={iconColor(isActiveAnnouncementTab)} />
          </div>
        </Button>
      </Link>

      <Link to={routeNames.itemSetting}>
        <Button
          type="button"
          buttonSize="large2"
          radius="high"
          color={isActiveItemTab ? "dark1" : "info1"}
        >
          موارد
          <div
            onClick={() => {
              setOpenedModal("item");
            }}
          >
            <CheckIcon color={iconColor(isActiveItemTab)} />
          </div>
        </Button>
      </Link>

      <Link to={routeNames.groupSetting}>
        <Button
          type="button"
          buttonSize="large2"
          radius="high"
          color={isActiveGroupTab ? "dark1" : "info1"}
        >
          گروه ها
          <div
            onClick={() => {
              setOpenedModal("group");
            }}
          >
            <CheckIcon color={iconColor(isActiveGroupTab)} />
          </div>
        </Button>
      </Link>

      <Link to={routeNames.userSetting}>
        <Button
          type="button"
          buttonSize="large2"
          radius="high"
          color={isActiveUserTab ? "dark1" : "info1"}
        >
          کاربران
          <div
            onClick={() => {
              setOpenedModal("user");
            }}
          >
            <CheckIcon color={iconColor(isActiveUserTab)} />
          </div>
        </Button>
      </Link>
    </div>
  );
};

export default Select;
