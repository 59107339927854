import { forwardRef } from "react";
import { Link } from "react-router-dom";

import { useGlobalContext } from "../../context";
import { E_STATUS } from "../../types/status";
import { normalizeDate } from "../../utils";
import BadgeStatus, { getStatusText } from "../badgeStatus";
import Button from "../button";
import Select from "../select";
import Table from "../table";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IItemCardProps } from "./types";

const ItemCard = forwardRef(
  (
    {
      className = "",
      isDetails,
      items,
      isCompleteLoading,
      ...props
    }: IItemCardProps,
    ref
  ) => {
    const thList: any = [
      "شناسه",
      "نوع",
      "کاربر",
      "گروه",
      "زمان",
      "وضعیت",
      "عملیات",
    ].filter((item) => typeof item !== "undefined");
    const { handleChangeSidebarType, handleSetActiveItem } = useGlobalContext();

    return (
      <div>
        <Table
          thList={thList}
          className={`${styles.wrapper} ${className}`}
          ref={ref}
          {...props}
        >
          {items.map((item, index) => {
            return (
              <tr key={item.id.toString()}>
                <Typography component="td" variant="body1">
                  {item.id}
                </Typography>

                <Typography component="td" variant="body1">
                  {item.type}
                </Typography>

                <Typography component="td" variant="body1">
                  {item.user}
                </Typography>

                <Typography component="td" variant="body1">
                  {item.groups.length === 1 ? (
                    item.groups[0]
                  ) : (
                    <Select
                      boxClassName={styles.select}
                      options={item.groups.map((g) => ({
                        label: g,
                        value: g,
                      }))}
                      value={
                        item.groups.length >= 1 ? item.groups[0] : undefined
                      }
                      placeholder="گروه ها"
                      position={index <= 1 ? "bottom" : "top"}
                    />
                  )}
                </Typography>

                <Typography component="td" variant="body1">
                  {normalizeDate(item.createdAt)}
                </Typography>

                <Typography component="td" variant="body1">
                  {!isDetails && (
                    <BadgeStatus
                      status={item.status.key as any}
                      time={item.expireAt}
                    />
                  )}

                  {isDetails && (
                    <Typography variant="body1">
                      {getStatusText(item.status.key as any)}
                    </Typography>
                  )}
                </Typography>

                {isDetails && (
                  <Typography component="td" variant="body1">
                    {(!item.isAccessToComplete ||
                      item.status.key === E_STATUS.COMPLETED) && (
                      <BadgeStatus
                        status={item.status.key as any}
                        style={{
                          marginRight: "auto  ",
                          display: "block",
                          width: 120,
                        }}
                      />
                    )}

                    {item.status.key !== E_STATUS.COMPLETED &&
                      item.isAccessToComplete && (
                        <Button
                          buttonSize="medium2"
                          type="button"
                          loading={isCompleteLoading}
                          disabled={isCompleteLoading}
                          color="success1"
                          style={{
                            width: 120,
                            height: 35,
                            marginRight: "auto",
                          }}
                          onClick={() => {
                            handleSetActiveItem(item.id);
                            handleChangeSidebarType("complete-item");
                          }}
                        >
                          تکمیل کردن
                        </Button>
                      )}
                  </Typography>
                )}

                {!isDetails && (
                  <Typography component="td" variant="body1">
                    <Link
                      to={`/details-item/${item.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        type="button"
                        buttonSize="small1"
                        color="secondary1"
                        fullWidth
                      >
                        ورود
                      </Button>
                    </Link>
                  </Typography>
                )}
              </tr>
            );
          })}
        </Table>
      </div>
    );
  }
);

export default ItemCard;
