import { useQuery } from "react-query";

import { queryKeys } from "../../data";
import { messagesChatGetReq } from "../../requests";
import { type IMessagesChatGetReq } from "../../requests/types";

const useChatMessagesQuery = (
  payload: IMessagesChatGetReq,
  enabled: boolean
) => {
  return useQuery(
    [queryKeys.chatMessages, payload.id],
    async () => await messagesChatGetReq(payload),
    {
      enabled,
    }
  );
};

export default useChatMessagesQuery;
