import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import {
  AttrFields,
  Button,
  ChooseUser,
  CustomerList,
  RHFSelect,
  Switch,
  TextEditor,
  Typography,
} from "../../components";
import ChooseGroup from "../../components/chooseGroup";
import { useGlobalContext } from "../../context";
import { customerSearchKey, queryKeys } from "../../data";
import { useTextEditorUploader } from "../../hooks";
import { CheckIcon } from "../../icons";
import { AddItem } from "../../pages/settings/components";
import {
  useItemCreateMutation,
  useSettingUsersQuery,
  useSubjectsQuery,
  useUserQuery,
} from "../../queries";
import styles from "./styles.module.scss";

const yupSchema = yup.object({
  activeGroupsId: yup
    .array()
    .of(yup.object().shape({ value: yup.number().required() }).required())
    .min(1, "حداقل یک گروه باید  انتخاب شود"),
  usersId: yup
    .array()
    .of(yup.object().shape({ value: yup.number().required() }).required()),
  customer_phones: yup.array().of(
    yup
      .object()
      .shape({
        phoneNumber: yup.string().notRequired(),
      })
      .required()
  ),
  attrs: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      key: yup.string().required(),
      value: yup.string().required("این فیلد اجباری میباشد"),
    })
  ),
  type: yup.number().required("نوع مورد را انتخاب کنید"),
  file1: yup.mixed().nullable(),
  file2: yup.mixed().nullable(),
  file3: yup.mixed().nullable(),
  file4: yup.mixed().nullable(),
  voice: yup.mixed().nullable(),
  description: yup.string().required("توضیحات را وارد کنید"),
});

const CreateItemSidebar = () => {
  const { handleChangeSidebarType } = useGlobalContext();
  const [textEditorHeight, setTextEditorHeight] = useState<number>(233);
  const [isOpenedCreateItemModal, setIsOpenedCreateItemModal] =
    useState<boolean>(false);
  const location = useLocation();
  const [enabled, setEnabled] = useState<boolean>(false);
  const subjectsQuery = useSubjectsQuery({ type: "item" });
  const createItemMutation = useItemCreateMutation();
  const queryClient = useQueryClient();
  const userQuery = useUserQuery();
  const settingUsersQuery = useSettingUsersQuery({ offset: 0, limit: 10000 });
  const findUser = settingUsersQuery?.data?.response?.users?.find(
    (u) => u.id === userQuery?.data?.user?.id
  );

  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      activeGroupsId: [],
      usersId: [],
      customer_phones: [{ phoneNumber: "" }, { phoneNumber: "" }],
      type: undefined,
      description: "",
      file1: null,
      file2: null,
      file3: null,
      file4: null,
      voice: null,
      attrs: [],
    },
    resolver: yupResolver(yupSchema),
  });
  const { handleUpload, isUploading } = useTextEditorUploader(methods as any);
  const selectedGroups = methods?.watch("activeGroupsId") ?? [];
  const selectedSubject = methods?.watch("type") ?? [];
  const subjects = subjectsQuery.data?.response.subjects ?? [];
  const filteredSubjects = subjects.filter((subject) => {
    if (typeof subject.is_active === "boolean" && !subject.is_active) {
      return false;
    }

    const selectedGroups = methods?.watch("activeGroupsId") ?? [];
    let isFindGroup = false;

    selectedGroups.forEach((g) => {
      if (isFindGroup) return;

      isFindGroup = subject.groups.some((sg) => sg.id === g.value);
    });

    return isFindGroup;
  });
  const mappedSubject = filteredSubjects.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const attrs = filteredSubjects?.find(
    (s) => s.id === selectedSubject
  )?.attributes;

  useEffect(() => {
    methods.setValue(
      "attrs",
      attrs?.map((attr) => ({
        key: attr.key,
        name: attr.name,
        value: "",
      }))
    );
  }, [attrs]);

  useEffect(() => {
    if (!enabled) return;
    if (location.pathname === `/search/${customerSearchKey}`) return;

    navigate(`/search/${customerSearchKey}`);
  }, [enabled, location]);

  const handleSubmit = async () => {
    const values = methods.getValues();

    const resultUploader = await handleUpload();

    createItemMutation.mutate(
      {
        type_id: values.type,
        user_id: values?.usersId?.[0]?.value,
        description: values.description.trim(),
        group_id: values.activeGroupsId?.[0].value as number,
        customer_phones: values.customer_phones
          ?.map((item) => item.phoneNumber)
          ?.filter((i) => i?.trim() !== "") as string[],
        attributes: values.attrs ?? [],
        file_1: resultUploader?.file_1?.token ?? null,
        file_2: resultUploader?.file_2?.token ?? null,
        file_3: resultUploader?.file_3?.token ?? null,
        file_4: resultUploader?.file_4?.token ?? null,
        voice: resultUploader?.voice?.token ?? null,
      },
      {
        onSuccess() {
          void queryClient.refetchQueries({
            queryKey: [queryKeys.items],
            inactive: true,
            active: true,
          });

          handleChangeSidebarType("main");

          toast("مورد جدید ایجاد شد", {
            type: "success",
          });
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <AddItem
        isOpened={isOpenedCreateItemModal}
        handleClose={() => {
          setIsOpenedCreateItemModal(false);
        }}
      />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <div
            style={{
              height: `calc(100vh - 15px - ${textEditorHeight}px)`,
              overflow: "auto",
            }}
          >
            <div className={styles.header}>
              <Typography component="h3" variant="title">
                ایجاد مورد جدید
              </Typography>

              <Button
                buttonSize="medium2"
                type="button"
                color="secondary1"
                radius="high"
                onClick={() => {
                  handleChangeSidebarType("main");
                }}
              >
                بستن
              </Button>
            </div>

            <div className={styles.content}>
              <div className={styles.selectGroup}>
                <Typography variant="subtitle1" component="h4">
                  انتخاب گروه
                </Typography>

                <ChooseGroup multiple={false} myGroup={false} />
              </div>

              <div className={styles.selectGroup}>
                <Typography variant="subtitle1" component="h4">
                  انتخاب کاربر
                </Typography>

                <ChooseUser multiple={false} />
              </div>

              <div className={styles.selectType}>
                <div className={styles.selectTypeHeader}>
                  <Typography variant="subtitle1" component="h4">
                    نوع
                  </Typography>

                  {findUser?.is_admin && (
                    <Button
                      buttonSize="medium1"
                      type="button"
                      color="info1"
                      onClick={() => {
                        setIsOpenedCreateItemModal(true);
                      }}
                    >
                      <CheckIcon width={15} height={15} />
                    </Button>
                  )}
                </div>

                <RHFSelect
                  name="type"
                  options={mappedSubject}
                  disabled={selectedGroups.length < 1}
                  placeholder="نوع را انتخاب کنید"
                />
              </div>

              <AttrFields />

              <div className={styles.selectCustomers}>
                <div className={styles.selectCustomersHeader}>
                  <Typography variant="subtitle1" component="h4">
                    مشتری
                  </Typography>

                  <div>
                    <Typography variant="body1" component="span">
                      جستجو
                    </Typography>
                    <Switch
                      handleSwitch={() => {
                        setEnabled((prevState) => !prevState);
                      }}
                      enabled={enabled}
                    />{" "}
                  </div>
                </div>

                <div>
                  <CustomerList />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.textEditor}>
            <TextEditor
              height={textEditorHeight}
              setHeight={setTextEditorHeight}
              isLoading={createItemMutation.isLoading || isUploading}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateItemSidebar;
