import { type IIconProps } from "./types";

const CheckingItemsIcon = ({ color = "#ffffff", ...props }: IIconProps) => {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5851 14.7939C11.3301 16.37 10.6093 18.3048 10.5273 20.3178C10.4453 22.3309 11.0063 24.3178 12.129 25.9907H2.82605C2.25473 25.9907 1.70684 25.7637 1.30286 25.3598C0.898876 24.9558 0.671896 24.4079 0.671875 23.8365V19.3166C0.671879 18.9462 0.772714 18.5828 0.963501 18.2654C1.15429 17.948 1.42784 17.6885 1.75488 17.5147C5.0904 15.7416 8.80764 14.8078 12.5851 14.7939Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8657 0.140625C11.5875 0.140625 10.3381 0.51964 9.27531 1.22975C8.21255 1.93986 7.38425 2.94917 6.89512 4.13004C6.40599 5.31091 6.27802 6.61032 6.52738 7.86392C6.77674 9.11753 7.3922 10.269 8.296 11.1728C9.1998 12.0766 10.3514 12.6921 11.605 12.9415C12.8586 13.1908 14.1579 13.0629 15.3388 12.5737C16.5197 12.0846 17.529 11.2563 18.2391 10.1935C18.9492 9.13076 19.3282 7.8813 19.3282 6.60313C19.3282 5.75446 19.1611 4.9141 18.8363 4.13004C18.5115 3.34597 18.0355 2.63354 17.4354 2.03344C16.8353 1.43334 16.1229 0.957316 15.3388 0.632545C14.5547 0.307775 13.7144 0.140622 12.8657 0.140625Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3316 14.9125C21.2433 14.9133 22.142 15.1291 22.9546 15.5425C23.7673 15.9559 24.4709 16.5551 25.0084 17.2916C25.5459 18.028 25.9021 18.8809 26.048 19.7808C26.1939 20.6808 26.1255 21.6025 25.8483 22.4711C25.7749 22.7007 25.7941 22.9498 25.9018 23.1655L26.895 25.1518H24.3609C24.1609 25.1518 23.9662 25.2168 23.8063 25.337C23.0722 25.8875 22.2177 26.2557 21.3134 26.4112C20.4091 26.5668 19.4808 26.5052 18.6049 26.2316C17.729 25.9579 16.9307 25.4801 16.2757 24.8374C15.6208 24.1947 15.1279 23.4056 14.8378 22.5351C14.5476 21.6646 14.4684 20.7375 14.6068 19.8304C14.7452 18.9233 15.0971 18.0621 15.6337 17.3176C16.1702 16.5732 16.8759 15.967 17.6927 15.5488C18.5095 15.1306 19.414 14.9125 20.3316 14.9125ZM27.9692 20.7035C27.9688 18.997 27.3968 17.3398 26.3444 15.9964C25.292 14.653 23.82 13.7008 22.1632 13.2918C20.5064 12.8829 18.7603 13.0407 17.2037 13.7401C15.6471 14.4395 14.3697 15.6402 13.5752 17.1505C12.7808 18.6609 12.5152 20.3938 12.8208 22.0727C13.1265 23.7516 13.9857 25.2798 15.2613 26.4133C16.537 27.5468 18.1557 28.2203 19.8589 28.3263C21.5621 28.4324 23.2517 27.9648 24.6581 26.9982H28.3888C28.5462 26.9982 28.7009 26.958 28.8384 26.8814C28.9758 26.8048 29.0914 26.6943 29.1742 26.5604C29.2569 26.4265 29.304 26.2737 29.3111 26.1165C29.3182 25.9593 29.2849 25.8029 29.2145 25.6622L27.7151 22.6633C27.8843 22.0238 27.9696 21.365 27.9692 20.7035Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6179 18.9125C17.6179 18.6676 17.7152 18.4328 17.8883 18.2597C18.0614 18.0865 18.2963 17.9893 18.5411 17.9893H22.234C22.3555 17.9889 22.4759 18.0124 22.5883 18.0587C22.7006 18.1049 22.8027 18.1728 22.8888 18.2586C22.9748 18.3444 23.0431 18.4463 23.0897 18.5585C23.1363 18.6707 23.1603 18.791 23.1603 18.9125C23.1603 19.034 23.1363 19.1543 23.0897 19.2665C23.0431 19.3787 22.9748 19.4806 22.8888 19.5664C22.8027 19.6521 22.7006 19.7201 22.5883 19.7663C22.4759 19.8125 22.3555 19.8361 22.234 19.8357H18.5411C18.4199 19.8357 18.2998 19.8118 18.1878 19.7654C18.0758 19.719 17.974 19.651 17.8883 19.5653C17.8026 19.4796 17.7346 19.3778 17.6882 19.2658C17.6418 19.1538 17.6179 19.0337 17.6179 18.9125Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6179 21.9906C17.6179 21.7458 17.7152 21.5109 17.8883 21.3378C18.0614 21.1647 18.2963 21.0674 18.5411 21.0674H20.3875C20.509 21.067 20.6294 21.0906 20.7418 21.1368C20.8541 21.183 20.9562 21.2509 21.0423 21.3367C21.1283 21.4225 21.1966 21.5244 21.2432 21.6366C21.2898 21.7488 21.3138 21.8691 21.3138 21.9906C21.3138 22.1121 21.2898 22.2324 21.2432 22.3446C21.1966 22.4568 21.1283 22.5587 21.0423 22.6445C20.9562 22.7303 20.8541 22.7982 20.7418 22.8444C20.6294 22.8906 20.509 22.9142 20.3875 22.9138H18.5411C18.4199 22.9138 18.2998 22.89 18.1878 22.8436C18.0758 22.7972 17.974 22.7292 17.8883 22.6434C17.8026 22.5577 17.7346 22.4559 17.6882 22.3439C17.6418 22.2319 17.6179 22.1119 17.6179 21.9906Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckingItemsIcon;
