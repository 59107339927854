import { type CreateAxiosDefaults } from "axios";

import configs from "./configs";

const requestConfig: CreateAxiosDefaults = {
  timeout: 15000,
  baseURL: configs.apiUrl,
};

export default requestConfig;
