import { useMutation } from "react-query";

import { newNotificationPostRequest } from "../../../requests";
import { type INewNotificationPostReq } from "../../../requests/types";

const useNewNotificationMutation = () => {
  return useMutation(
    async (variables: INewNotificationPostReq) =>
      await newNotificationPostRequest(variables)
  );
};

export default useNewNotificationMutation;
