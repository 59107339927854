export { announcementTypesData } from "./announcement";
export { default as apiUrls } from "./apiUrls";
export { default as localStorageKeys } from "./localStorageKeys";
export { default as queryKeys } from "./queryKeys";

export const customerSearchKey = "search-in-customers";

// export const stepsText = [
//   'اول',
//   'دوم',
//   'سوم',
//   'چهارم',
//   'پنجم',
//   'ششم',
//   'هفتم',
//   'هشتم',
//   'نهم',
//   'دهم',
//   'یازدهم',
//   'دوازدهم',
//   'سیزدهم',
//   'چهاردهم',
//   'پانزدهم',
//   'شانزدهم',
//   'هفدهم',
//   'هجدهم',
//   'نوزدهم',
//   'بیستم',
// ];
