import { useQuery } from "react-query";

import { queryKeys } from "../../data";
import { itemsGetRequest } from "../../requests";
import { type IItemsGetReq } from "../../requests/types";

const useItemsQuery = (params: IItemsGetReq) => {
  return useQuery(
    [queryKeys.items, params],
    async () => await itemsGetRequest(params)
  );
};

export default useItemsQuery;
