import { apiUrls } from "../data";
import requestService from "../services/request";
import {
  type IDetailsGroupGetReq,
  type IDetailsGroupGetRes,
  type IShiftPlansGroupGetReq,
} from "./types";

export const detailsGroupGetRequest = async (
  payload: IDetailsGroupGetReq
): Promise<IDetailsGroupGetRes> => {
  const { data } = await requestService.get(apiUrls.groups.detailsGroupGet, {
    params: {
      group_id: payload.id,
      start_at: payload.from,
      end_at: payload.to,
      offset: 0,
      limit: 10000,
    },
  });

  return data;
};

export const shiftPlansGroupGetRequest = async (
  groupId: number
): Promise<IShiftPlansGroupGetReq> => {
  const { data } = await requestService.get(apiUrls.groups.shiftsPlan, {
    params: {
      group_id: groupId,
    },
  });

  return data;
};
