import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { useGlobalContext } from "../../context";
import useSocketInitializer from "../../hooks/useSocketInitializer";
import { Loading, MenuLayout, SidebarLayout } from "..";
import styles from "./styles.module.scss";

const Dashboard = () => {
  const { sidebarType } = useGlobalContext();
  useSocketInitializer();

  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.sidebar} ${
          sidebarType !== "small-main"
            ? styles.sidebarLarge
            : styles.sidebarSmall
        }`}
      >
        <SidebarLayout />
      </div>

      <div
        className={`${styles.content} ${
          sidebarType !== "small-main"
            ? styles.contentSmall
            : styles.contentLarge
        }`}
      >
        <Suspense fallback={<Loading position="absolute" />}>
          <Outlet />
        </Suspense>
      </div>

      <div className={styles.menuBar}>
        <MenuLayout />
      </div>
    </div>
  );
};

export default Dashboard;
