import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import {
  AddCustomInput,
  Button,
  Modal,
  RHFSelect,
  TextField,
  Typography,
} from "../../../../components";
import ChooseGroup from "../../../../components/chooseGroup";
import { useGlobalContext } from "../../../../context";
import {
  useCreateSubjectMutation,
  useSubjectsQuery,
} from "../../../../queries";
import { type ISettingsAddModalProps } from "../../types";
import styles from "./styles.module.scss";

const resolver = yupResolver(
  yup.object().shape({
    name: yup.string().required("لطفا موضوع اعلان را وارد کنید"),
    activeGroupsId: yup
      .array()
      .of(yup.object().shape({ value: yup.number().required() }).required())
      .min(1, "حداقل یک گروه باید  انتخاب شود"),
    attributes: yup
      .array()
      .of(
        yup.object().shape({
          key: yup.string().required("شناسه اجباری میباشد."),
          name: yup.string().required("نام اینپوت باکس را وارد کنید"),
        })
      )
      .required(),
    isPermanent: yup.boolean().required("وضعیت را انتخاب کنید"),
  })
);

const AddAnnouncement = ({ handleClose, isOpened }: ISettingsAddModalProps) => {
  const createSubjectMutation = useCreateSubjectMutation();

  const { activeAnnouncementSubjectEditId } = useGlobalContext();
  const subjectsQuery = useSubjectsQuery({ type: "notification" });
  const findSubject =
    activeAnnouncementSubjectEditId === -1
      ? undefined
      : subjectsQuery?.data?.response?.subjects?.find(
          (s) => s.id === activeAnnouncementSubjectEditId
        );

  const methods = useForm({
    defaultValues: {
      name: findSubject?.name ?? "",
      isPermanent: findSubject?.is_active ?? undefined,
      activeGroupsId: findSubject?.groups?.map((g) => ({ value: g.id })) ?? [],
      attributes: findSubject?.attributes ?? [],
    },
    resolver,
  });

  useEffect(() => {
    if (isOpened) return;

    methods.reset();
  }, [isOpened]);

  const handleSubmit = (values: any) => {
    createSubjectMutation.mutate(
      {
        subject_id:
          activeAnnouncementSubjectEditId === -1
            ? undefined
            : activeAnnouncementSubjectEditId,
        name: values.name,
        group_ids: values.activeGroupsId?.map((u: any) => u.value) ?? [],
        attributes: values.attributes,
        is_active: values.isPermanent,
      },
      {
        onSuccess() {
          void subjectsQuery.refetch();

          toast("اعلان جدید ایجاد شد", { type: "success" });

          handleClose();
        },
      }
    );
  };

  return (
    <Modal
      title={
        activeAnnouncementSubjectEditId === -1
          ? "ایجاد اعلان جدید"
          : "ویرایش اعلان"
      }
      handleClose={handleClose}
      isOpened={isOpened}
      isShowCloseButton
    >
      <FormProvider {...methods}>
        <form
          className={styles.wrapper}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <div className={styles.generalFields}>
            <div>
              <TextField
                label="موضوع اعلان"
                placeholder="موضوع اعلان"
                name="name"
                id="name"
              />
            </div>

            <div>
              <RHFSelect
                options={[
                  {
                    label: "فعال",
                    value: true,
                  },
                  {
                    label: "غیرفعال",
                    value: false,
                  },
                ]}
                label="وضعیت"
                placeholder="وضعیت"
                name="isPermanent"
                id="isPermanent"
              />
            </div>
          </div>

          <div className={styles.selectGroup}>
            <Typography variant="subtitle2" component="label">
              انتخاب گروه
            </Typography>

            <div style={{ marginTop: 20 }}>
              <ChooseGroup myGroup={false} />
            </div>
          </div>

          <div className={styles.addCustomInput}>
            <AddCustomInput name="attributes" />
          </div>

          <div className={styles.button}>
            <Button
              buttonSize="medium1"
              type="submit"
              color="info1"
              radius="high"
              disabled={createSubjectMutation.isLoading}
              loading={createSubjectMutation.isLoading}
            >
              {activeAnnouncementSubjectEditId === -1
                ? "ایجاد اعلان"
                : "ویرایش اعلان"}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddAnnouncement;
