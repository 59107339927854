import { useQuery } from "react-query";

import { localStorageKeys, queryKeys } from "../../data";
import { usersChatGetReq } from "../../requests";

const useChatUsersQuery = () => {
  return useQuery(queryKeys.chatUsers, async () => await usersChatGetReq(), {
    enabled:
      typeof localStorage.getItem(localStorageKeys.apiAuthorization) ===
      "string",
  });
};

export default useChatUsersQuery;
