import { useEffect } from "react";

import { Button, NotificationItem, Typography } from "../../components";
import { useGlobalContext } from "../../context";
import {
  useUserNotificationSeenMutation,
  useUserNotificationsQuery,
} from "../../queries";
import styles from "./styles.module.scss";

const NotificationSidebar = () => {
  const { handleChangeSidebarType, sidebarType } = useGlobalContext();

  const userNotificationsQuery = useUserNotificationsQuery({
    limit: 10000,
    offset: 0,
  });
  const seenNotificationsMutation = useUserNotificationSeenMutation();

  useEffect(() => {
    if (sidebarType !== "notifications") return;

    seenNotificationsMutation.mutate(undefined, {
      onSuccess() {
        void userNotificationsQuery.refetch();
      },
    });
  }, [sidebarType]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography component="h3" variant="title">
          نوتیفیکیشن ها
        </Typography>

        <Button
          buttonSize="medium2"
          type="button"
          color="secondary1"
          radius="high"
          onClick={() => {
            handleChangeSidebarType("main");
          }}
        >
          بستن
        </Button>
      </div>

      <div className={styles.content}>
        {userNotificationsQuery.data?.response.user_notifications.map(
          (notification) => (
            <NotificationItem
              key={notification.id.toString()}
              title={notification.title}
              description={notification.text}
              date={notification.created_at}
            />
          )
        )}
      </div>
    </div>
  );
};

export default NotificationSidebar;
