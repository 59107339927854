import moment from "moment-jalaali";

const normalizeExpireAt = (value?: string | null): null | Date => {
  if (typeof value === "undefined" || value === null) {
    return null;
  }

  return moment(value, "jYYYY/jMM/jDD HH:mm").toDate();
};

export default normalizeExpireAt;
