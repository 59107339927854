import { forwardRef, useState } from "react";

import { useGlobalContext } from "../../context";
import { AnnouncementIcon, ArrowBottomIcon } from "../../icons";
import { SetExpirationTimeModal } from "../../pages/announcements/components";
import { normalizeDate } from "../../utils";
import { AnnouncementDetailsCard } from "../announcementCard";
import Select from "../select";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IAnnouncementSearchCard } from "./types";

const AnnouncementSearchCard = forwardRef(
  (
    {
      className = "",
      type,
      isSeen,
      itemId,
      subject,
      customers,
      time,
      creator,
      isShowMore,
      isExpired,
      setOpenedItemsId,
      groups,
      createdAt,
      hasExpiration,
      handleRefetch,
      ...props
    }: IAnnouncementSearchCard,
    ref
  ) => {
    const [expirationTimeId, setExpirationTimeId] = useState<
      number | undefined
    >();

    const thList = [
      "شناسه",
      "نوع",
      "موضوع",
      "مشتری",
      "ایجاد کننده",
      "گروه",
      "زمان ایجاد",
      "وضعیت",
      "",
    ];
    const { handleChangeSidebarType, handleSetActiveAnnouncement } =
      useGlobalContext();

    const handleNext = (notificationId: number) => {
      handleSetActiveAnnouncement(notificationId);
      handleChangeSidebarType("next-announcement");
    };

    const handleNew = (notificationId: number) => {
      handleSetActiveAnnouncement(notificationId);

      handleChangeSidebarType("new-announcement");
    };

    return (
      <div
        className={`${className} ${styles.wrapper}`}
        ref={ref as any}
        {...props}
      >
        <SetExpirationTimeModal
          id={expirationTimeId as number}
          isOpened={typeof expirationTimeId === "number"}
          handleClose={() => {
            handleRefetch();
            setExpirationTimeId(undefined);
          }}
        />

        <div className={styles.icon}>
          <AnnouncementIcon width="23px" height="21px" color="#ffffff" />
        </div>

        <table>
          <thead>
            <tr>
              {thList.map((text, index) => (
                <Typography
                  component="th"
                  variant="subtitle2"
                  key={index}
                  textAlgin="center"
                >
                  {text}
                </Typography>
              ))}
            </tr>
          </thead>

          <tbody>
            <tr>
              <Typography component="td" textAlgin="center" variant="body1">
                {itemId}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                {type}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                {subject}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                {customers.length >= 1 ? (
                  <Select
                    placeholder=""
                    position="bottom"
                    value={customers[0]}
                    boxClassName={styles.select}
                    options={customers.map((g) => ({
                      label: g,
                      value: g,
                    }))}
                  />
                ) : (
                  "ندارد"
                )}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                {creator}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                {groups.length >= 1 ? (
                  <Select
                    placeholder=""
                    position="bottom"
                    value={groups[0]}
                    boxClassName={styles.select}
                    options={groups.map((g) => ({
                      label: g,
                      value: g,
                    }))}
                  />
                ) : (
                  "ندارد"
                )}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                {normalizeDate(createdAt)}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                {hasExpiration ? (isExpired ? "منقضی" : "فعال") : "دائمی"}
              </Typography>

              <Typography
                component="td"
                variant="body1"
                style={{ borderRadius: isShowMore ? "40px 0 0 0" : undefined }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    backgroundColor: isSeen ? "transparent" : "#f283b4",
                    width: "25px",
                    height: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                  }}
                  onClick={setOpenedItemsId}
                >
                  <ArrowBottomIcon
                    style={{
                      transition: "0.3s",
                      transform: isShowMore ? "rotate(180deg)" : undefined,
                    }}
                  />
                </span>
              </Typography>
            </tr>

            {isShowMore && (
              <tr style={{ borderTopWidth: 0 }}>
                <td
                  colSpan={9}
                  style={{
                    borderRadius: "0 0 40px 40px",
                    marginTop: 0,
                  }}
                >
                  <AnnouncementDetailsCard
                    item={{
                      type,
                      createdAt,
                      creator,
                      customers,
                      groups,
                      isSeen: true,
                      hasExpiration,
                      id: itemId,
                      isExpired,
                      subject,
                      time,
                    }}
                    handleClickNew={() => {
                      handleNew(itemId);
                    }}
                    handleClickNext={() => {
                      handleNext(itemId);
                    }}
                    handleClickExpirationTime={() => {
                      setExpirationTimeId(itemId);
                    }}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
);

export default AnnouncementSearchCard;
