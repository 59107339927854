import { forwardRef } from "react";
import { Link } from "react-router-dom";

import { ItemIcon } from "../../icons";
import { normalizeDate } from "../../utils";
import BadgeStatus from "../badgeStatus";
import Button from "../button";
import Select from "../select";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IItemSearchCardProps } from "./types";

const ItemSearchCard = forwardRef(
  (
    {
      className = "",
      id,
      type,
      customers,
      user,
      expireAt,
      group,
      createdAt,
      status,
      ...props
    }: IItemSearchCardProps,
    ref
  ) => {
    const thList = [
      "شناسه",
      "نوع",
      "مشتری",
      "کاربر",
      "گروه",
      "زمان",
      "وضعیت",
      "عملیات",
    ];

    return (
      <div
        className={`${className} ${styles.wrapper}`}
        ref={ref as any}
        {...props}
      >
        <div className={styles.icon}>
          <ItemIcon width="23px" height="21px" color="#ffffff" />
        </div>

        <table>
          <thead>
            <tr>
              {thList.map((text, index) => (
                <Typography
                  component="th"
                  variant="subtitle2"
                  textAlgin="center"
                  key={index}
                >
                  {text}
                </Typography>
              ))}
            </tr>
          </thead>

          <tbody>
            <tr>
              <Typography component="td" textAlgin="center" variant="body1">
                {id}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                {type}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                {customers.length >= 1 ? (
                  <Select
                    placeholder=""
                    position="bottom"
                    value={customers[0]}
                    boxClassName={styles.select}
                    options={customers.map((g) => ({
                      label: g,
                      value: g,
                    }))}
                  />
                ) : (
                  "ندارد"
                )}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                {user}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                {group}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                {normalizeDate(createdAt)}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                {typeof status?.key === "string" && (
                  <BadgeStatus
                    status={status?.key as any}
                    time={expireAt ?? undefined}
                  />
                )}
              </Typography>

              <Typography component="td" textAlgin="center" variant="body1">
                <Link to={`/details-item/${id}`}>
                  <Button
                    type="button"
                    buttonSize="small1"
                    color="dark1"
                    fullWidth
                  >
                    ورود
                  </Button>
                </Link>
              </Typography>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
);

export default ItemSearchCard;
