import { forwardRef } from "react";

import Button from "../button";
import OnlineStatus from "../onlineStatus";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IGroupCardProps } from "./types";

const GroupCard = forwardRef(
  (
    {
      className = "",
      groupName,
      users,
      imgUrl,
      handleClickLogin,
      handleClickShiftListModal,
      ...props
    }: IGroupCardProps,
    ref
  ) => {
    return (
      <div
        ref={ref as any}
        className={`${styles.wrapper} ${className}`}
        {...props}
      >
        <div className={styles.rightBox}>
          <div className={styles.avatar}>
            {imgUrl !== "" && (
              <img src={imgUrl} alt={groupName} draggable={false} />
            )}
          </div>

          <div className={styles.content}>
            <div className={styles.contentMain}>
              <Typography variant="body1" component="h5">
                {groupName}
              </Typography>

              <Typography variant="subtitle2" component="span">
                {users.length} نفر
              </Typography>

              {typeof handleClickShiftListModal === "function" && (
                <Button
                  type="button"
                  buttonSize="small1"
                  color="secondary1"
                  onClick={handleClickShiftListModal}
                >
                  برنامه کاری اعضا
                </Button>
              )}
            </div>

            <div className={styles.contentUserList}>
              {users.map((user) => (
                <OnlineStatus
                  key={user.id}
                  isOnline={user.isOnline}
                  text={user.username}
                />
              ))}
            </div>
          </div>
        </div>

        <Button
          buttonSize="large1"
          type="button"
          color="secondary1"
          className={styles.login}
          onClick={handleClickLogin}
        />
      </div>
    );
  }
);

export default GroupCard;
