import { useQuery } from "react-query";

import { queryKeys } from "../../../data";
import { detailsNotificationGetRequest } from "../../../requests";
import { type IDetailsNotificationGetReq } from "../../../requests/types";

const useDetailsNotificationQuery = (params: IDetailsNotificationGetReq) => {
  return useQuery(
    [queryKeys.detailsNotification, params.notificationId],
    async () => await detailsNotificationGetRequest(params)
  );
};

export default useDetailsNotificationQuery;
