import { apiUrls } from "../data";
import requestService from "../services/request";
import { type ISubjectsGetReq, type ISubjectsGetRes } from "./types";

export const subjectsGetRequest = async (
  reqData: ISubjectsGetReq
): Promise<ISubjectsGetRes> => {
  const { data } = await requestService.get(apiUrls.subjects.subjectsGet, {
    params: reqData,
  });

  return data;
};
