import { useMutation } from "react-query";

import { notificationCreatePostRequest } from "../../../requests";
import { type INotificationCreatePostReq } from "../../../requests/types";

const useNotificationCreateMutation = () => {
  return useMutation(
    async (variables: INotificationCreatePostReq) =>
      await notificationCreatePostRequest(variables)
  );
};

export default useNotificationCreateMutation;
