import { type IIconProps } from "./types";

const NotificationIcon = ({ color = "#2A3A65", ...props }: IIconProps) => {
  return (
    <svg
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0814 25.767H4.07478C1.22537 25.767 -0.40122 22.9009 1.30842 20.8924C2.05212 20.0332 2.52192 18.9712 2.6573 17.8429L3.3542 11.7025C3.60042 10.044 4.31261 8.48959 5.40778 7.22006C6.50296 5.95053 7.93606 5.0181 9.54047 4.53131V4.36972C9.53891 3.90417 9.62927 3.44289 9.80634 3.01233C9.98342 2.58176 10.2438 2.19041 10.5725 1.86066C10.9011 1.53091 11.2915 1.26925 11.7215 1.09073C12.1515 0.912207 12.6124 0.820312 13.078 0.820312C13.5435 0.820312 14.0046 0.912207 14.4345 1.09073C14.8645 1.26925 15.2549 1.53091 15.5836 1.86066C15.9122 2.19041 16.1726 2.58176 16.3497 3.01233C16.5268 3.44289 16.6171 3.90417 16.6155 4.36972V4.53134C18.22 5.01812 19.6532 5.95053 20.7484 7.22006C21.8435 8.48959 22.5556 10.044 22.8018 11.7025L23.4987 17.8429C23.6342 18.9712 24.104 20.0332 24.8476 20.8924C26.5572 22.9009 24.9308 25.767 22.0814 25.767ZM17.7099 28.9953C17.3107 29.8901 16.6608 30.6502 15.8388 31.1835C15.0168 31.7169 14.0579 32.0007 13.0781 32.0007C12.0982 32.0007 11.1393 31.7169 10.3173 31.1835C9.49535 30.6502 8.84546 29.8901 8.44624 28.9953C8.41701 28.9218 8.40226 28.8434 8.40278 28.7643C8.40278 28.6777 8.4198 28.5919 8.45295 28.5118C8.4861 28.4318 8.53476 28.3591 8.59602 28.2978C8.65727 28.2366 8.72997 28.188 8.81001 28.1548C8.89004 28.1217 8.97582 28.1046 9.06245 28.1046H17.0937C17.1803 28.1046 17.2661 28.1217 17.3461 28.1548C17.4262 28.188 17.4989 28.2366 17.5601 28.2978C17.6214 28.3591 17.67 28.4318 17.7032 28.5118C17.7363 28.5919 17.7534 28.6777 17.7534 28.7643C17.7539 28.8434 17.7392 28.9218 17.7099 28.9953Z"
        fill={color}
      />
    </svg>
  );
};

export default NotificationIcon;
