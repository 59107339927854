import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";

import { useSettingUsersQuery } from "../../queries";
import { RHFSelect } from "../select";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IShiftListProps } from "./types";

const heads: any = {
  0: "شیفت اول",
  1: "شیفت دوم",
  2: "شیفت سوم",
  3: "شیفت چهارم",
  4: "شیفت پنجم",
  5: "شیفت ششم",
  6: "شیفت هفتم",
  7: "شیفت هشتم",
  8: "شیفت نهم",
  9: "شیفت دهم",
};

const ShiftList = forwardRef(
  ({ className = "", groupId, ...props }: IShiftListProps, ref) => {
    const methods = useFormContext();
    const shifts: Array<{ userIds: number[] }> = methods.watch("shifts");
    const usersQuery = useSettingUsersQuery({ offset: 0, limit: 10000 });
    const users = shifts?.[0]?.userIds?.map((_, i) => heads[i]) ?? [];

    const thList = ["ردیف", ...users];

    const options =
      usersQuery.data?.response.users
        .filter((u) => {
          const hasGroup = u.groups.some((g) => g.id === groupId);

          return hasGroup;
        })
        .map((u) => ({
          label: u.name,
          value: u.id,
        })) ?? [];

    return (
      <div
        className={`${className} ${styles.wrapper}`}
        ref={ref as any}
        {...props}
      >
        <div className={styles.shifts}>
          <ul
            style={{
              gridTemplateColumns: `40px repeat(${thList.length - 1} ,1fr)`,
              marginBottom: 18,
            }}
          >
            {thList.map((th, index) => (
              <Typography
                component="li"
                variant="subtitle2"
                key={index}
                textAlgin="center"
              >
                {th}
              </Typography>
            ))}
          </ul>

          {shifts.map((shift, index) => {
            return (
              <ul
                key={index}
                style={{
                  gridTemplateColumns: `40px repeat(${shift.userIds.length} ,1fr)`,
                  marginBottom: index < shifts.length - 1 ? 13 : 0,
                }}
              >
                <Typography component="li" variant="body1" textAlgin="center">
                  {index + 1}
                </Typography>

                {shift.userIds.map((_, ci) => {
                  return (
                    <Typography component="li" variant="body1" key={ci}>
                      <RHFSelect
                        name={`shifts.${index}.userIds.${ci}`}
                        options={[
                          {
                            label: "هیچکدام",
                            value: "",
                          },
                          ...options.filter((u) => {
                            const hasId = shift.userIds.some(
                              (uId) => uId === u.value
                            );

                            if (hasId && shift.userIds[ci] !== u.value) {
                              return false;
                            }

                            return true;
                          }),
                        ]}
                        placeholder="انتخاب کنید"
                        boxClassName={styles.field}
                      />
                    </Typography>
                  );
                })}
              </ul>
            );
          })}
        </div>
      </div>
    );
  }
);

export default ShiftList;
