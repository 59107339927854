import { type IIconProps } from "./types";

const GroupIcon = ({ color = "#2A3A65", ...props }: IIconProps) => {
  return (
    <svg
      width="41"
      height="44"
      viewBox="0 0 41 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38.6613 33.6193C37.7329 34.95 36.3789 35.9248 34.8224 36.3832C33.266 36.8416 31.5998 36.7562 30.0983 36.1411C30.2206 36.7316 30.282 37.3331 30.2817 37.9362V41.8055C30.2821 42.2354 30.2087 42.6621 30.0647 43.0672H39.1139C39.4484 43.0671 39.7693 42.9341 40.0059 42.6975C40.2425 42.4609 40.3755 42.1401 40.3756 41.8055V37.9362C40.3762 36.3311 39.7629 34.7866 38.6613 33.6193Z"
        fill={color}
      />
      <path
        d="M10.0939 37.9362C10.0936 37.3331 10.155 36.7317 10.2773 36.1412C8.77579 36.7562 7.10964 36.8416 5.55317 36.3832C3.99671 35.9248 2.64276 34.95 1.71427 33.6194C0.61269 34.7867 -0.00064586 36.3312 5.10369e-07 37.9362V41.8055C0.00012243 42.1401 0.133093 42.461 0.369687 42.6976C0.606281 42.9342 0.927136 43.0671 1.26173 43.0673H10.3109C10.1669 42.6622 10.0935 42.2354 10.0939 41.8055V37.9362Z"
        fill={color}
      />
      <path
        d="M11.7761 17.7484H13.879V22.3748C13.879 22.6251 13.9535 22.8697 14.0929 23.0776C14.2324 23.2855 14.4305 23.4472 14.6621 23.5422C14.8937 23.6371 15.1484 23.6611 15.3936 23.6109C15.6389 23.5607 15.8636 23.4387 16.0393 23.2604L21.4726 17.7484H28.5992C30.0488 17.7468 31.4385 17.1702 32.4635 16.1452C33.4886 15.1202 34.0651 13.7305 34.0668 12.2809V5.46753C34.0651 4.01796 33.4886 2.62822 32.4636 1.60321C31.4386 0.578205 30.0488 0.00164363 28.5992 0H11.7761C10.3265 0.00164362 8.93681 0.578208 7.91181 1.60321C6.88681 2.62821 6.31024 4.01796 6.30859 5.46753V12.2809C6.31024 13.7305 6.88681 15.1202 7.91181 16.1452C8.93681 17.1702 10.3265 17.7468 11.7761 17.7484ZM12.6173 5.13104H27.7581C28.0921 5.13198 28.4121 5.26537 28.648 5.50189C28.8838 5.73841 29.0163 6.0588 29.0163 6.39282C29.0163 6.72684 28.8838 7.04723 28.648 7.28375C28.4121 7.52027 28.0921 7.6536 27.7581 7.65454H12.6173C12.4513 7.65501 12.2868 7.62271 12.1333 7.55951C11.9798 7.49631 11.8403 7.40346 11.7228 7.28625C11.6053 7.16905 11.512 7.02978 11.4484 6.87646C11.3847 6.72315 11.352 6.55882 11.352 6.39282C11.352 6.22683 11.3847 6.06243 11.4484 5.90912C11.512 5.75581 11.6053 5.61654 11.7228 5.49933C11.8403 5.38212 11.9798 5.28927 12.1333 5.22607C12.2868 5.16288 12.4513 5.13058 12.6173 5.13104ZM12.6173 10.178H25.2346C25.5686 10.1789 25.8887 10.3123 26.1245 10.5488C26.3604 10.7853 26.4928 11.1057 26.4928 11.4398C26.4928 11.7738 26.3604 12.0942 26.1245 12.3307C25.8887 12.5672 25.5686 12.7005 25.2346 12.7015H12.6173C12.4513 12.7019 12.2868 12.6696 12.1333 12.6064C11.9798 12.5432 11.8403 12.4504 11.7228 12.3332C11.6053 12.216 11.512 12.0767 11.4484 11.9234C11.3847 11.7701 11.352 11.6058 11.352 11.4398C11.352 11.2738 11.3847 11.1094 11.4484 10.9561C11.512 10.8027 11.6053 10.6635 11.7228 10.5463C11.8403 10.4291 11.9798 10.3362 12.1333 10.273C12.2868 10.2098 12.4513 10.1775 12.6173 10.178Z"
        fill={color}
      />
      <path
        d="M32.8051 34.1509C35.3601 34.1509 37.4314 32.0797 37.4314 29.5246C37.4314 26.9695 35.3601 24.8983 32.8051 24.8983C30.25 24.8983 28.1787 26.9695 28.1787 29.5246C28.1787 32.0797 30.25 34.1509 32.8051 34.1509Z"
        fill={color}
      />
      <path
        d="M7.57045 34.1509C10.1255 34.1509 12.1968 32.0797 12.1968 29.5246C12.1968 26.9695 10.1255 24.8983 7.57045 24.8983C5.01538 24.8983 2.94409 26.9695 2.94409 29.5246C2.94409 32.0797 5.01538 34.1509 7.57045 34.1509Z"
        fill={color}
      />
      <path
        d="M14.3319 33.6193C13.2301 34.7865 12.6167 36.3311 12.6174 37.9362V41.8055C12.6174 42.1401 12.7504 42.461 12.987 42.6976C13.2236 42.9343 13.5445 43.0672 13.8792 43.0672H26.4965C26.8311 43.0672 27.1521 42.9343 27.3887 42.6977C27.6253 42.4611 27.7582 42.1401 27.7583 41.8055V37.9362C27.759 36.3311 27.1456 34.7865 26.0438 33.6193C25.3856 34.5622 24.5096 35.3322 23.49 35.8638C22.4705 36.3955 21.3377 36.6732 20.1878 36.6732C19.038 36.6732 17.9052 36.3955 16.8856 35.8638C15.8661 35.3322 14.99 34.5622 14.3319 33.6193Z"
        fill={color}
      />
      <path
        d="M20.1876 34.1509C22.7427 34.1509 24.814 32.0797 24.814 29.5246C24.814 26.9695 22.7427 24.8983 20.1876 24.8983C17.6326 24.8983 15.5613 26.9695 15.5613 29.5246C15.5613 32.0797 17.6326 34.1509 20.1876 34.1509Z"
        fill={color}
      />
    </svg>
  );
};

export default GroupIcon;
