import { QueryClient } from "react-query";
import { toast } from "react-toastify";

const queryClientConfig = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
    mutations: {
      onSuccess() {},
      onError(response: any) {
        if (response?.response?.status === 422) {
          if (response?.response?.data?.message === "invalidToken") {
            toast("توکن نامعتبر است.", {
              type: "error",
            });
          } else {
            toast("ایمیل یا رمز عبور معتبر نمیباشد", {
              type: "error",
            });
          }
          return;
        }

        toast("عملیات انجام نشد", {
          type: "error",
        });
      },
    },
  },
});

export default queryClientConfig;
