import { ErrorMessage } from "@hookform/error-message";
import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";

import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IRHFSwitchProps, type ISwitchProps } from "./types";

const Switch = forwardRef(
  ({ className = "", handleSwitch, enabled, ...props }: ISwitchProps, ref) => {
    return (
      <div
        className={`${className} ${styles.wrapper} ${
          enabled ? styles.wrapperActive : ""
        }`}
        onClick={() => {
          if (typeof handleSwitch !== "function") return;

          handleSwitch(!enabled);
        }}
        ref={ref as any}
        {...props}
      />
    );
  }
);

export const RHFSwitch = ({
  name,
  handleSwitch,
  label,
  ...props
}: IRHFSwitchProps) => {
  const methods = useFormContext();

  return (
    <div>
      {typeof label === "string" && (
        <div className={styles.label}>
          <Typography variant="subtitle2" component="label" htmlFor={props.id}>
            {label}
          </Typography>
        </div>
      )}

      <Switch
        enabled={methods.watch(name)}
        handleSwitch={(enabled) => {
          methods.setValue(name, enabled);

          if (typeof handleSwitch !== "function") return;

          handleSwitch(enabled);
        }}
        {...props}
      />

      <ErrorMessage
        errors={methods.formState.errors}
        name={name}
        render={({ message }) => (
          <div style={{ marginTop: 5 }}>
            <Typography
              variant="text1"
              className={styles.error}
              color="danger1"
            >
              {message}
            </Typography>
          </div>
        )}
      />
    </div>
  );
};

export default Switch;
