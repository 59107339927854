import { useMutation } from "react-query";

import { settingsTypeCreatePost } from "../../requests";
import { type ISettingsSubjectCreateReq } from "../../requests/types";

export const useCreateTypeMutation = () => {
  return useMutation(
    async (variables: ISettingsSubjectCreateReq) =>
      await settingsTypeCreatePost(variables)
  );
};

export default useCreateTypeMutation;
