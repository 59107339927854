import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { useOutsideClick } from "../../hooks";
import Button from "../button";
import Select from "../select";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IRHFTimePickerProps, type ITimePickerProps } from "./types";

export const TimePickerField = ({
  placeholder,
  value,
  label,
  handleChange,
  startFromZero,
}: ITimePickerProps) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [temporaryTime, setTemporaryTime] = useState<string>("");

  const splitTime = temporaryTime.split(":");
  const hours = splitTime[0] || "";
  const minutes = splitTime[1] || "";

  useEffect(() => {
    setTemporaryTime(value);
  }, [value]);

  useEffect(() => {
    if (temporaryTime.split(":").some((i) => i === "")) return;

    handleChange(temporaryTime);
  }, [temporaryTime]);

  const wrapperRef = useRef<HTMLButtonElement>();

  useOutsideClick(wrapperRef, () => {
    if (!isShow) return;

    if (hours !== "" && minutes !== "") {
      handleChange(temporaryTime);
    }

    setIsShow(false);
  });

  const hourOptions = Array(startFromZero ? 25 : 24)
    .fill(null)
    .map((_, i) => i + (startFromZero ? 0 : 1))
    .map((value) =>
      value.toString().length < 2 ? `0${value}` : value.toString()
    );
  const minuteOptions = Array(60)
    .fill(null)
    .map((_, i) => i)
    .map((value) =>
      value.toString().length < 2 ? `0${value}` : value.toString()
    );

  return (
    <div className={styles.wrapper}>
      {typeof label === "string" && (
        <div className={styles.label}>
          <Typography
            variant="subtitle2"
            component="label"
            onClick={() => {
              setIsShow(true);
            }}
          >
            {label}
          </Typography>
        </div>
      )}

      <div ref={wrapperRef as any}>
        <Button
          className={styles.button}
          color="secondary1"
          type="button"
          buttonSize="medium2"
          onClick={() => {
            setIsShow(true);
          }}
        >
          {value === "" ? placeholder : value}
        </Button>

        {isShow && (
          <div className={styles.items}>
            <Select
              options={minuteOptions.map((value) => ({
                label: value,
                value,
              }))}
              value={minutes}
              placeholder="دقیقه"
              handleChange={(option) => {
                setTemporaryTime(`${hours}:${option.value}`);
              }}
            />

            <Typography component="span" variant="subtitle1">
              :
            </Typography>

            <Select
              options={hourOptions.map((value) => ({
                label: value,
                value,
              }))}
              value={hours}
              placeholder="ساعت"
              handleChange={(option) => {
                setTemporaryTime(`${option.value}:${minutes}`);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const RHFTimePickerField = ({ name, ...props }: IRHFTimePickerProps) => {
  const methods = useFormContext();

  return (
    <div>
      <TimePickerField
        value={methods.watch(name)}
        handleChange={(newValue) => {
          methods.clearErrors(name);
          methods.setValue(name, newValue);
        }}
        {...props}
      />

      <ErrorMessage
        errors={methods.formState.errors}
        name={name}
        render={({ message }) => (
          <Typography variant="text1" className={styles.error} color="danger1">
            {message}
          </Typography>
        )}
      />
    </div>
  );
};
