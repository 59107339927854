import "react-loading-skeleton/dist/skeleton.css";

import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";

import router from "./router";

const App = (): any => {
  return (
    <Suspense fallback={<></>}>
      <RouterProvider router={router} fallbackElement={<div>tester</div>} />
    </Suspense>
  );
};

export default App;
