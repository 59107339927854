import { ErrorMessage } from "@hookform/error-message";
import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";

import { ArrowBottomIcon } from "../../icons";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IRHFSelectProps, type ISelectProps } from "./types";

const Select = forwardRef(
  (
    {
      options,
      value,
      placeholder,
      className = "",
      boxClassName = "",
      handleChange,
      ...props
    }: ISelectProps,
    ref
  ) => {
    const hasValue = value !== "" && typeof value !== "undefined";
    return (
      <div className={styles.select} ref={ref as any}>
        <span className={styles.arrow}>
          <ArrowBottomIcon />
        </span>

        <select
          className={`${boxClassName} ${className} ${styles.select} ${
            !hasValue ? styles.placeholder : ""
          }`}
          value={typeof value === "undefined" ? "" : value}
          {...(props as any)}
          onChange={(event) => {
            if (typeof handleChange === "undefined") return;

            const findItem = options.find(
              (o) => `${o.value}` === `${event.target.value}`
            );

            if (typeof findItem === "undefined") {
              return;
            }

            handleChange(findItem);
          }}
        >
          <option selected={true} value="" disabled>
            {typeof placeholder !== "string" || placeholder === ""
              ? "انتخاب کنید"
              : placeholder}
          </option>

          {options.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
);

export const RHFSelect = ({
  label,
  name,
  handleChange,
  ...props
}: IRHFSelectProps) => {
  const { watch, formState, setValue, clearErrors } = useFormContext();

  return (
    <div>
      {typeof label === "string" && (
        <div className={styles.label}>
          <Typography variant="subtitle2" component="label" htmlFor={props.id}>
            {label}
          </Typography>
        </div>
      )}

      <Select
        value={watch(name)}
        {...props}
        handleChange={(option) => {
          setValue(name, option.value);
          clearErrors(name);

          if (typeof handleChange === "function") {
            handleChange(option);
          }
        }}
      />

      <ErrorMessage
        errors={formState.errors}
        name={name}
        render={({ message }) => (
          <div style={{ marginTop: 5 }}>
            <Typography
              variant="text1"
              className={styles.error}
              color="danger1"
            >
              {message}
            </Typography>
          </div>
        )}
      />
    </div>
  );
};

export default Select;
