import axios, { HttpStatusCode } from "axios";

import { requestConfig } from "../configs";
import { localStorageKeys } from "../data";

const requestService = axios.create(requestConfig);

requestService.interceptors.request.use((config) => {
  const authorization = localStorage.getItem(localStorageKeys.apiAuthorization);

  config.headers.Authorization =
    authorization === null ? undefined : `Bearer ${authorization}`;

  return config;
});

requestService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === HttpStatusCode.Unauthorized) {
      localStorage.removeItem(localStorageKeys.apiAuthorization);
      window.location.href = "/login";
    }

    return await Promise.reject(error);
  }
);

export default requestService;
