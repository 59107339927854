const queryKeys = {
  user: "user",
  subjects: "subjects",
  notifications: "notifications",
  detailsNotification: "details-notification",
  groups: "groups",
  detailsGroup: "details-group",
  users: "users",
  settingsGroup: "setting-groups",
  items: "items",
  detailsItem: "details-item",
  userNotifications: "user-notifications",
  shiftsPlanGroup: "shifts-plan-group",
  chatUsers: "chat-users",
  chatMessages: "chat-messages",
  search: "search",
};

export default queryKeys;
