import { forwardRef, useState } from "react";

import { useGlobalContext } from "../../context";
import { usePagination } from "../../hooks";
import { useSettingUsersQuery, useUserDeleteMutation } from "../../queries";
import { normalizeDate } from "../../utils";
import Button from "../button";
import DeleteModal from "../deleteModal";
import Pagination from "../pagination";
import Select from "../select";
import Table from "../table";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IUserSettingTable } from "./types";

const UserSettingTable = forwardRef(
  ({ className = "", ...props }: IUserSettingTable, ref) => {
    const usersQuery = useSettingUsersQuery({ limit: 10000, offset: 0 });
    const deleteMutation = useUserDeleteMutation();
    const [deleteId, setDeleteId] = useState<number>(-1);
    const { handleSetActiveUserEditItem } = useGlobalContext();
    const { activePage, handleSetLimit, handleSetOffset, limit, offset } =
      usePagination();
    const items = usersQuery?.data?.response?.users ?? [];

    const thList = [
      "شناسه",
      "نام کابری",
      "ایمیل",
      "نوع کاربری",
      "گروه",
      "زمان عضویت",
      "وضعیت",
      "عملیات",
    ];

    return (
      <div>
        <DeleteModal
          title="حذف کاربر"
          description="آیا از حذف کاربر مطمئن هستید"
          isOpened={deleteId !== -1}
          handleClose={() => {
            setDeleteId(-1);
          }}
          isLoading={deleteMutation.isLoading}
          handleSubmit={() => {
            deleteMutation.mutate(
              { id: deleteId },
              {
                onSuccess() {
                  void usersQuery.refetch();

                  setDeleteId(-1);
                },
              }
            );
          }}
        />

        <Table
          thList={thList}
          className={`${styles.wrapper} ${className}`}
          ref={ref}
          {...props}
        >
          {items.slice(offset * limit, limit * (offset + 1)).map((item) => (
            <tr key={item.id}>
              <Typography variant="body1" component="td">
                {item.id}
              </Typography>
              <Typography variant="body1" component="td">
                {item?.name}
              </Typography>
              <Typography
                variant="body1"
                component="td"
                style={{ direction: "ltr" }}
              >
                {item.email}
              </Typography>
              <Typography variant="body1" component="td">
                {item.is_admin ? "مدیر" : "کاربر"}
              </Typography>
              <Typography variant="body1" component="td">
                {item.groups.length >= 1 ? (
                  <Select
                    options={item.groups?.map((g) => ({
                      label: g?.name,
                      value: g?.id,
                    }))}
                    placeholder="گروه ها"
                    value={
                      item.groups.length >= 1 ? item.groups[0]?.name : undefined
                    }
                    boxClassName={styles.select}
                  />
                ) : (
                  item.groups[0]?.name
                )}
              </Typography>
              <Typography variant="body1" component="td">
                <span>{normalizeDate(item.created_at)}</span>
              </Typography>
              <Typography variant="body1" component="td">
                {item.active ? "فعال" : "غیرفعال"}
              </Typography>
              <Typography
                variant="body1"
                component="td"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  type="button"
                  buttonSize="small1"
                  color="secondary1"
                  style={{ minWidth: "80px", height: "40px" }}
                  onClick={() => {
                    handleSetActiveUserEditItem(item.id);
                  }}
                >
                  ویرایش
                </Button>

                <div style={{ width: 10 }} />

                <Button
                  type="button"
                  buttonSize="small1"
                  color="pink1"
                  style={{ minWidth: "80px", height: "40px" }}
                  onClick={() => {
                    setDeleteId(item.id);
                  }}
                >
                  حذف
                </Button>
              </Typography>
            </tr>
          ))}
        </Table>

        <div
          style={{
            marginTop: "15px",
          }}
        >
          <Pagination
            activePage={activePage}
            handleChange={handleSetOffset}
            handleChangeLimit={handleSetLimit}
            limit={limit}
            totalItems={items.length}
          />
        </div>
      </div>
    );
  }
);

export default UserSettingTable;
