import { type IIconProps } from "./types";

const CheckIcon = ({ color = "#fff", ...props }: IIconProps) => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.47205 0.892959C7.87055 0.883991 8.25626 1.03368 8.54443 1.30908C8.8326 1.58449 8.99959 1.96307 9.00867 2.36157C9.00903 2.38023 9.00905 2.39873 9.00867 2.41739V6.9089H13.5247C13.918 6.915 14.2931 7.07554 14.5691 7.35583C14.8451 7.63613 14.9998 8.01371 14.9998 8.40707C14.9998 8.80044 14.8451 9.17802 14.5691 9.45831C14.2931 9.73861 13.918 9.89913 13.5247 9.90523H9.00867V14.3938C9.00615 14.7923 8.84615 15.1736 8.56348 15.4545C8.2808 15.7354 7.89839 15.8931 7.49988 15.8931C7.10137 15.8931 6.71908 15.7354 6.4364 15.4545C6.15373 15.1736 5.99372 14.7923 5.99121 14.3938V9.90523H1.4751C1.08178 9.89913 0.706649 9.73861 0.430664 9.45831C0.154679 9.17802 0 8.80044 0 8.40707C0 8.01371 0.154679 7.63613 0.430664 7.35583C0.706649 7.07554 1.08178 6.915 1.4751 6.9089H5.99121V2.41739C5.98831 2.22006 6.0242 2.02409 6.09705 1.84068C6.16989 1.65727 6.27815 1.49001 6.41565 1.34845C6.55315 1.20689 6.71723 1.09379 6.89844 1.01564C7.07965 0.937486 7.27472 0.895801 7.47205 0.892959Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckIcon;
