import { forwardRef } from "react";

import { AllUsersIcon, CheckingItemsIcon, OnlineUsersIcon } from "../../icons";
import Card from "../card";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type INumberInformationCardProps } from "./types";

const NumberInformationCard = forwardRef(
  (
    { className = "", count, type, ...props }: INumberInformationCardProps,
    ref
  ) => {
    return (
      <Card
        ref={ref as any}
        className={`${styles.wrapper} ${className}`}
        {...props}
      >
        <div className={styles.title}>
          <div className={`${styles.icon} ${styles[`icon${type}`]}`}>
            {type === "AllUsers" && <AllUsersIcon />}
            {type === "OnlineUsers" && <OnlineUsersIcon />}
            {type === "PendingItems" && <CheckingItemsIcon />}
          </div>

          <Typography variant="subtitle2" component="h4">
            {type === "AllUsers" && "تعداد کل کاربران"}
            {type === "OnlineUsers" && "تعداد کاربران آنلاین"}
            {type === "PendingItems" && "موارد در حال بررسی"}
          </Typography>
        </div>

        <Typography variant="text2" className={styles.count}>
          {count}
        </Typography>
      </Card>
    );
  }
);

export default NumberInformationCard;
