import { forwardRef, Fragment, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { FadeLoader } from "react-spinners";

import { queryKeys } from "../../data";
import { ArrowBottomIcon } from "../../icons";
import { useDetailsNotificationQuery, useUserQuery } from "../../queries";
import { normalizeDate } from "../../utils";
import DetailsAnnouncementCard from "../detailsAnnouncementCard";
import Select from "../select";
import Table from "../table";
import Typography from "../typography";
import styles from "./styles.module.scss";
import {
  type IAnnouncementCardProps,
  type IAnnouncementDetailsCardProps,
} from "./types";

export const AnnouncementDetailsCard = ({
  handleClickExpirationTime,
  handleClickNew,
  handleClickNext,
  item,
}: IAnnouncementDetailsCardProps) => {
  const detailsNotificationQuery = useDetailsNotificationQuery({
    notificationId: item.id,
  });
  const userQuery = useUserQuery();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (detailsNotificationQuery.isFetching) return;

    void queryClient.refetchQueries({ queryKey: queryKeys.user });
    void queryClient.refetchQueries({ queryKey: queryKeys.notifications });
  }, [detailsNotificationQuery.isFetching]);

  if (detailsNotificationQuery.isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <FadeLoader color="#29b9fc" />
      </div>
    );
  }
  const descriptions =
    detailsNotificationQuery.data?.response?.notification?.descriptions;
  const seenUsers =
    detailsNotificationQuery?.data?.response?.notification?.seen_by_users ?? [];

  let newIndex = 0;

  return (
    <>
      {descriptions?.map((desc, index) => {
        const isEnd = descriptions.length <= index + 1;
        const isNew = desc.step !== descriptions?.[index + 1]?.step;
        const isSamePrev = desc.step !== descriptions?.[index - 1]?.step;
        const attrs =
          detailsNotificationQuery?.data?.response?.notification?.attributes;

        if (isSamePrev && index !== 0) {
          newIndex += 1;
        }

        return (
          <div
            key={desc.id.toString()}
            style={{
              marginTop: index === 0 ? 0 : "3px",
            }}
          >
            <DetailsAnnouncementCard
              seenUsers={seenUsers.map((u) => u.name)}
              text={desc.description}
              attributes={index === 0 && Array.isArray(attrs) ? attrs : []}
              index={newIndex}
              isNew={isNew && !isEnd}
              isSamePrev={
                isSamePrev &&
                descriptions
                  .filter((_, i) => i !== 0)
                  .some((i) => i.step !== descriptions[0].step)
              }
              isFirstItemOfStep={index === 0}
              customers={item.customers}
              voice={desc.voice}
              hasExpiration={item.hasExpiration}
              isExpired={item.isExpired}
              isEnd={isEnd}
              time={isEnd ? item.time : undefined}
              files={desc.media}
              createdAt={desc.created_at}
              notificationId={item.id}
              isShowButtons={
                index === 0 && item.creator === userQuery?.data?.user?.name
              }
              handleClickNew={() => {
                handleClickNew();
              }}
              handleClickNext={() => {
                handleClickNext();
              }}
              handleClickExpirationTime={() => {
                handleClickExpirationTime();
              }}
            />
          </div>
        );
      })}

      <div
        className={styles.seenUsers}
        style={{
          top: item.hasExpiration && !item.isExpired ? "-12px" : 0,
        }}
      >
        {seenUsers.map((user, index) => (
          <Typography variant="text1" key={index}>
            {user.name}
          </Typography>
        ))}
      </div>
    </>
  );
};

const AnnouncementCard = forwardRef(
  ({
    className = "",
    items,
    handleClickExpirationTime,
    handleClickNew,
    handleClickNext,
    ...props
  }: IAnnouncementCardProps) => {
    const [openedItemsId, setOpenedItemsId] = useState<number[]>([]);
    const tableRef = useRef<HTMLTableElement>();

    const thList = [
      "شناسه",
      "نوع",
      "موضوع",
      "ایجاد کننده",
      "گروه",
      "زمان ایجاد",
      "وضعیت",
      "",
    ];

    return (
      <div>
        <Table
          thList={thList}
          className={`${styles.wrapper} ${className}`}
          ref={tableRef as any}
          {...props}
        >
          {items.map((item, index) => {
            const isShow = openedItemsId.some((id) => id === item.id);

            return (
              <Fragment key={item.id}>
                <tr style={{ marginTop: 0 }}>
                  <Typography
                    component="td"
                    variant="body1"
                    style={{ borderRadius: isShow ? "0 40px 0 0" : undefined }}
                  >
                    {item.id}
                  </Typography>

                  <Typography component="td" variant="body1">
                    {item.type}
                  </Typography>

                  <Typography component="td" variant="body1">
                    {item.subject}
                  </Typography>

                  <Typography component="td" variant="body1">
                    {item.creator}
                  </Typography>

                  <Typography component="td" variant="body1">
                    <Select
                      placeholder="گروه ها"
                      position={index < 2 ? "bottom" : "top"}
                      value={item.groups[0]}
                      boxClassName={styles.select}
                      options={item.groups.map((g) => ({
                        label: g,
                        value: g,
                      }))}
                    />
                  </Typography>

                  <Typography component="td" variant="body1">
                    {normalizeDate(item.createdAt)}
                  </Typography>

                  <Typography component="td" variant="body1">
                    {!item.hasExpiration
                      ? "دائمی"
                      : item.isExpired
                      ? "منقضی"
                      : "فعال"}
                  </Typography>

                  <Typography
                    component="td"
                    variant="body1"
                    style={{ borderRadius: isShow ? "40px 0 0 0" : undefined }}
                  >
                    <span
                      style={{
                        cursor: "pointer",
                        backgroundColor: item.isSeen
                          ? "transparent"
                          : "#f283b4",
                        width: "25px",
                        height: "25px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                      }}
                      onClick={() => {
                        setOpenedItemsId((prevState) => {
                          if (isShow) {
                            return [
                              ...prevState.filter((id) => id !== item.id),
                            ];
                          }

                          return [...prevState, item.id];
                        });
                      }}
                    >
                      <ArrowBottomIcon
                        style={{
                          transition: "0.3s",
                          transform: isShow ? "rotate(180deg)" : undefined,
                        }}
                      />
                    </span>
                  </Typography>
                </tr>

                {isShow && (
                  <tr style={{ borderTopWidth: 0 }}>
                    <td
                      colSpan={9}
                      style={{
                        borderRadius: "0 0 40px 40px",
                        marginTop: 0,
                      }}
                    >
                      <AnnouncementDetailsCard
                        item={item}
                        handleClickNew={() => {
                          handleClickNew(item.id);
                        }}
                        handleClickNext={() => {
                          handleClickNext(item.id);
                        }}
                        handleClickExpirationTime={() => {
                          handleClickExpirationTime(item.id);
                        }}
                      />
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </Table>
      </div>
    );
  }
);

export default AnnouncementCard;
