import { apiUrls } from "../data";
import requestService from "../services/request";
import {
  type IUserNotificationGetReq,
  type IUserNotificationGetRes,
} from "./types";

export const userNotificationsGetRequest = async (
  params: IUserNotificationGetReq
): Promise<IUserNotificationGetRes> => {
  const { data } = await requestService.get(
    apiUrls.userNotifications.userNotificationsGet,
    {
      params,
    }
  );

  return data;
};

export const userNotificationsSeenPostRequest = async () => {
  const { data } = await requestService.get(
    apiUrls.userNotifications.userNotificationSeenPost
  );

  return data;
};
