import { apiUrls } from "../data";
import requestService from "../services/request";
import {
  type ICheckLoginTokenUserPostReq,
  type ILoginUserPostReq,
  type ILoginUserPostRes,
  type IUserGetRes,
} from "./types";

export const loginUserPostRequest = async (
  reqData: ILoginUserPostReq
): Promise<ILoginUserPostRes> => {
  const { data } = await requestService.post(
    apiUrls.auth.loginPost,
    undefined,
    { params: reqData }
  );

  return data;
};

export const checkLoginTokenUserPostRequest = async (
  reqData: ICheckLoginTokenUserPostReq
): Promise<ILoginUserPostRes> => {
  const { data } = await requestService.post(
    apiUrls.auth.checkLoginToken,
    undefined,
    { params: reqData }
  );

  return data;
};

export const userGetRequest = async (): Promise<IUserGetRes> => {
  const { data } = await requestService.get(apiUrls.auth.userGet);

  return data;
};

export const userLogoutPostRequest = async (): Promise<any> => {
  const { data } = await requestService.post(apiUrls.auth.logout);

  return data;
};
