import { useSearchParams } from "react-router-dom";

import configs from "../configs";

const usePagination = (
  defaultLimit: number = configs.paginationDefaultLimit
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchOffset = searchParams.get("offset") ?? "0";
  const searchLimit = searchParams.get("limit") ?? defaultLimit.toString();
  const offset = isNaN(parseInt(searchOffset)) ? 0 : parseInt(searchOffset);
  const limit = isNaN(parseInt(searchLimit))
    ? defaultLimit
    : parseInt(searchLimit);

  const handleSetOffset = (offset: number) => {
    setSearchParams((prevState) => ({
      ...prevState,
      limit,
      offset,
    }));
  };

  const handleSetLimit = (limit: number) => {
    setSearchParams((prevState) => ({
      ...prevState,
      offset,
      limit,
    }));
  };

  return { offset, limit, activePage: offset, handleSetOffset, handleSetLimit };
};

export default usePagination;
