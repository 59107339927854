import { ErrorMessage } from "@hookform/error-message";
import { useFieldArray, useFormContext } from "react-hook-form";

import { useSettingGroupsQuery } from "../../queries";
import ChooseBox from "../chooseBox";
import Typography from "../typography";

const ChooseGroup = ({
  filterGroup,
  name = "activeGroupsId",
  multiple = true,
  myGroup = true,
}: {
  multiple?: boolean;
  filterGroup?: string;
  myGroup?: boolean;
  name?: string;
}) => {
  const groupsQuery = useSettingGroupsQuery({
    limit: 10000,
    offset: 0,
    my_groups: myGroup,
  });

  const methods = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name,
  });

  return (
    <div>
      <ChooseBox
        items={
          groupsQuery.data?.response.groups.groups
            .map((group) => ({
              id: group.id,
              name: group.name,
            }))
            ?.filter((g) => g.name !== filterGroup) ?? []
        }
        handleClick={(id) => {
          const findIndexField = fields.findIndex((i: any) => i.value === id);
          const hasId = findIndexField !== -1;

          methods.clearErrors(name);

          if (!multiple) {
            remove(0);
            append({ value: id });
            return;
          }

          if (!hasId) {
            append({ value: id });
            return;
          }

          remove(findIndexField);
        }}
        activeItemsId={fields.map((item: any) => item.value)}
      />

      <ErrorMessage
        errors={methods.formState.errors}
        name="activeGroupsId"
        render={({ message }) => (
          <div style={{ marginTop: 5 }}>
            <Typography variant="text1" color="danger1">
              {message}
            </Typography>
          </div>
        )}
      />
    </div>
  );
};

export default ChooseGroup;
