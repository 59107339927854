import { useMutation } from "react-query";

import { setExpirationTimeNotificationPostRequest } from "../../../requests";
import { type ISetExpirationTimeNotificationPostReq } from "../../../requests/types";

const useSetExpirationTimeNotificationMutation = () => {
  return useMutation(
    async (variables: ISetExpirationTimeNotificationPostReq) =>
      await setExpirationTimeNotificationPostRequest(variables)
  );
};

export default useSetExpirationTimeNotificationMutation;
