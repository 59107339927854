import { useMutation } from "react-query";

import { groupDeleteRequest } from "../../requests";

const useGroupDeleteMutation = () => {
  return useMutation(
    async (variables: { id: number }) => await groupDeleteRequest(variables.id)
  );
};

export default useGroupDeleteMutation;
