import { UserSettingTable } from "../../../../components";
import { useSettingUsersQuery } from "../../../../queries";

const User = () => {
  const usersQuery = useSettingUsersQuery({ limit: 10000, offset: 0 });

  const users = usersQuery?.data?.response?.users ?? [];

  if (users.length < 1) return null;
  return <UserSettingTable />;
};

export default User;
