import { forwardRef } from "react";

import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IOnlineStatusProps } from "./types";

const OnlineStatus = forwardRef(
  ({ className = "", isOnline, text, ...props }: IOnlineStatusProps, ref) => {
    return (
      <div
        ref={ref as any}
        className={`${className} ${styles["online-status"]} ${
          isOnline
            ? styles["online-status-color-online"]
            : styles["online-status-color-offline"]
        }`}
        {...props}
      >
        {typeof text === "string" && (
          <Typography variant="text2" color="white" noneSelection>
            {text}
          </Typography>
        )}

        {typeof text !== "string" && (
          <Typography variant="body2" color="white">
            {isOnline ? "آنلاین" : "آفلاین"}
          </Typography>
        )}
      </div>
    );
  }
);

export default OnlineStatus;
