import { useQuery } from "react-query";

import { queryKeys } from "../data";
import { searchGetRequest } from "../requests";
import { type ISearchGetReq } from "../requests/types";

const useSearchQuery = (payload: ISearchGetReq) => {
  return useQuery(
    [queryKeys.search, payload],
    async () => await searchGetRequest(payload)
  );
};

export default useSearchQuery;
