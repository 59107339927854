import moment from "moment-jalaali";
import { useMemo } from "react";
import { FadeLoader } from "react-spinners";

import useShiftsPlanGroupQuery from "../../queries/groups/useShiftsPlanGroupQuery";
import Modal from "../modal";
import Table from "../table";
import Typography from "../typography";
import { type IShiftListModal } from "./types";

const ShiftListModal = ({ id, isOpened, handleClose }: IShiftListModal) => {
  const shiftsPlanQuery = useShiftsPlanGroupQuery(id);
  const plans = shiftsPlanQuery.data?.response?.plans ?? [];

  const thList = shiftsPlanQuery.isLoading
    ? []
    : [
        "ساعت",
        ...plans?.map((p) => moment(p.date, "jYYYY/jMM/jDD").format("jMM/jDD")),
      ];

  const customPlans = useMemo(() => {
    const data: Array<{
      end_at: string;
      start_at: string;
      items: Array<{
        date: string;
        users: string[];
      } | null>;
    }> = [];

    plans.forEach((plan, planIndex) => {
      plan.data.forEach((planItem) => {
        const findIndexData = data.findIndex(
          (d) => d.start_at === planItem.start_at
        );

        if (findIndexData === -1) {
          data.push({
            start_at: planItem.start_at,
            end_at: planItem.end_at,
            items: Array(plans.length).fill(null),
          });
        }

        const activeIndex =
          findIndexData === -1 ? data.length - 1 : findIndexData;

        data[activeIndex].items[planIndex] = {
          date: plan.date,
          users: planItem.users,
        };
      });
    });

    return data;
  }, [plans]);

  return (
    <Modal
      title="برنامه کاری اعضا"
      isOpened={isOpened}
      handleClose={handleClose}
      isShowCloseButton
    >
      {shiftsPlanQuery.isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FadeLoader color="#29b9fc" />
        </div>
      )}

      {!shiftsPlanQuery.isLoading && (
        <div
          style={{
            background: "#f0f6ff",
            padding: "20px 10px",
            borderRadius: 16,
          }}
        >
          <Table thList={thList}>
            {customPlans.map((plan, index) => {
              return (
                <tr key={index}>
                  <Typography component="td" variant="body1" textAlgin="center">
                    {plan.start_at}
                  </Typography>

                  {plan.items.map((item, itemIndex) => {
                    return (
                      <Typography
                        key={itemIndex}
                        component="td"
                        variant="body1"
                        textAlgin="center"
                      >
                        {item === null ? "" : item.users.join(" ، ")}
                      </Typography>
                    );
                  })}
                </tr>
              );
            })}
          </Table>
        </div>
      )}
    </Modal>
  );
};

export default ShiftListModal;
