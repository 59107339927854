import Lottie from "react-lottie";

import { LottieLoadingData } from "../../assets";
import { Typography } from "../../components";
import styles from "./styles.module.scss";

const Loading = ({
  position = "fixed",
}: {
  position?: "absolute" | "fixed";
}) => {
  return (
    <div className={`${styles.wrapper} ${styles[`wrapper-${position}`]}`}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: LottieLoadingData,
        }}
        width={250}
        height={250}
      />

      <Typography variant="title" component="h3" textAlgin="center">
        پنل کاری
      </Typography>

      <Typography variant="subtitle2" textAlgin="center">
        در حال دریافت اطلاعات (لطفا منتظر بمانید)
      </Typography>
    </div>
  );
};

export default Loading;
