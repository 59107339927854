import { useQuery } from "react-query";

import { localStorageKeys, queryKeys } from "../../data";
import { userNotificationsGetRequest } from "../../requests";
import { type IUserNotificationGetReq } from "../../requests/types";

const useUserNotificationsQuery = (params: IUserNotificationGetReq) => {
  return useQuery(
    [queryKeys.userNotifications, params.offset, params.offset],
    async () => await userNotificationsGetRequest(params),
    {
      enabled:
        typeof localStorage.getItem(localStorageKeys.apiAuthorization) ===
        "string",
    }
  );
};

export default useUserNotificationsQuery;
