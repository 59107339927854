import { useMutation } from "react-query";

import { settingsGroupCreatePost } from "../../requests";
import { type ISettingsProfileCreateReq } from "../../requests/types";

export const useCreateGroupMutation = () => {
  return useMutation(
    async (variables: ISettingsProfileCreateReq) =>
      await settingsGroupCreatePost(variables)
  );
};

export default useCreateGroupMutation;
