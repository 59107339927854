import { digitsFaToEn } from "@persian-tools/persian-tools";
import moment from "moment-jalaali";

const normalizeDate = (date: string): string => {
  if (typeof date !== "string") return "";

  return date.split(" ").reverse().join(" ");
};

export const convertJalaliToMiladi = (value: string): string => {
  if (typeof value === "undefined" || value === null || value === "") return "";

  return moment(digitsFaToEn(value.toString()), "jYYYY/jMM/jDD").format(
    "YYYY/MM/DD"
  );
};

export default normalizeDate;
