import Button from "../button";
import Modal from "../modal";
import Typography from "../typography";
import { type IDeleteModalProps } from "./types";

const DeleteModal = ({
  title,
  description,
  handleClose,
  handleSubmit,
  isOpened,
  isLoading,
}: IDeleteModalProps) => {
  return (
    <Modal title={title} isOpened={isOpened} handleClose={handleClose}>
      <Typography variant="subtitle1">{description}</Typography>

      <div style={{ display: "flex", marginTop: "15px" }}>
        <Button
          type="button"
          buttonSize="small1"
          color="pink1"
          disabled={isLoading}
          loading={isLoading}
          onClick={handleSubmit}
          style={{ width: "100px" }}
        >
          حذف
        </Button>

        <Button
          type="button"
          buttonSize="small1"
          color="secondary1"
          onClick={handleClose}
          style={{ width: "80px", marginRight: "10px" }}
        >
          لغو
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
