import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import {
  AvatarUploader,
  Button,
  Modal,
  RHFSelect,
  RHFTimePickerField,
  ShiftList,
  Switch,
  TextField,
  Typography,
} from "../../../../components";
import { useGlobalContext } from "../../../../context";
import {
  useCreateGroupMutation,
  useSettingGroupsQuery,
  useSettingUsersQuery,
  useUploadFileMutation,
  useUserQuery,
} from "../../../../queries";
import { type IGroupUser } from "../../../../requests/types";
import { type ISettingsAddModalProps } from "../../types";
import styles from "./styles.module.scss";

const resolverFn = (isShiftActive: boolean) => {
  let obj: any = {
    pictureFile: yup.mixed().notRequired(),
    pictureUrl: yup.string().notRequired(),
    name: yup.string().required("نام گروه را وارد کنید"),
    active: yup.boolean().required("وضعیت گروه را انتخاب کنید"),
  };

  if (isShiftActive) {
    obj = {
      ...obj,
      perShiftHour: yup.string().required("شیفت چند ساعته را وارد کنید"),
      shiftStartTime: yup.string().required("شروع شیفت را وارد کنید"),
      shiftEndTime: yup.string().required("پایان شیفت را وارد کنید"),
      usersCount: yup.number().required("تعداد کاربران را انتخاب کنید کنید"),
      concurrentUsersCount: yup
        .number()
        .required("تعداد کاربران همزمان را انتخاب کنید"),
      shifts: yup.array().of(
        yup.object().shape({
          userIds: yup
            .array()
            .of(
              yup
                .number()
                .not([-1], "کاربر این شیفت را وارد کنید")
                .required("کاربر این شیفت را وارد کنید")
            ),
        })
      ),
    };
  }

  return yupResolver(yup.object().shape(obj));
};

const AddGroup = ({ handleClose, isOpened }: ISettingsAddModalProps) => {
  const settingMyGroupsQuery = useSettingGroupsQuery({
    offset: 0,
    limit: 10000,
    my_groups: true,
  });
  const settingGroupsQuery = useSettingGroupsQuery({
    offset: 0,
    limit: 10000,
    my_groups: false,
  });
  const uploadMutation = useUploadFileMutation();
  const { activeGroupEditId } = useGlobalContext();

  const userQuery = useUserQuery();
  const usersQuery = useSettingUsersQuery({ limit: 10000, offset: 0 });

  const isEdit = activeGroupEditId !== -1;

  const findGroup = settingGroupsQuery?.data?.response?.groups?.groups?.find(
    (g) => g.id === activeGroupEditId
  );

  const createGroupMutation = useCreateGroupMutation();

  function handleTransfersUserForFront(
    items: IGroupUser[]
  ): Array<{ userIds: number[] }> {
    const transferItems: Array<{ userIds: number[] }> = [];

    const filteredItems = items
      .filter(
        (item) => typeof item.row === "number" && typeof item.shift === "number"
      )
      .sort((a, b) => a.row - b.row)
      .sort((a, b) => a.shift - b.shift);

    filteredItems.forEach((item) => {
      if (typeof transferItems[item.row - 1] === "undefined") {
        transferItems.push({ userIds: [] });
      }

      transferItems[item.row - 1].userIds.push(item.user.id);
    });

    return transferItems;
  }

  const [isShiftActive, setIsShiftActive] = useState<boolean>(
    isEdit &&
      typeof findGroup?.concurrent_users_count === "number" &&
      findGroup.concurrent_users_count >= 1
  );
  const methods = useForm({
    defaultValues: {
      pictureFile: undefined,
      pictureUrl: findGroup?.profile ?? undefined,
      name: findGroup?.name ?? "",
      active: findGroup?.active ?? undefined,
      perShiftHour: findGroup?.per_shift_hour ?? undefined,
      shiftStartTime: findGroup?.shift_start_time ?? "",
      shiftEndTime: findGroup?.shift_end_time ?? "",
      concurrentUsersCount: findGroup?.concurrent_users_count
        ? parseInt(findGroup?.concurrent_users_count)
        : undefined,
      usersCount: findGroup
        ? handleTransfersUserForFront(findGroup?.users ?? [])?.length
        : undefined,
      shifts: handleTransfersUserForFront(findGroup?.users ?? []),
    },
    resolver: resolverFn(isShiftActive),
  });
  const columnLength = methods.watch("usersCount");
  const rowLength = methods.watch("concurrentUsersCount");

  useEffect(() => {
    if (isOpened) return;

    methods.reset();
  }, [isOpened]);

  useEffect(() => {
    if (isShiftActive) return;

    methods.setValue("perShiftHour", findGroup?.per_shift_hour ?? undefined);
    methods.setValue("shiftStartTime", findGroup?.shift_start_time ?? "");
    methods.setValue("shiftEndTime", findGroup?.shift_end_time ?? "");
    methods.setValue(
      "concurrentUsersCount",
      findGroup?.concurrent_users_count
        ? parseInt(findGroup?.concurrent_users_count)
        : undefined
    );
    methods.setValue(
      "usersCount",
      findGroup
        ? handleTransfersUserForFront(findGroup?.users ?? [])?.length
        : undefined
    );
    methods.setValue(
      "shifts",
      handleTransfersUserForFront(findGroup?.users ?? [])
    );
  }, [isShiftActive]);

  const handleSubmit = async () => {
    const values = methods.getValues();

    let imageUrl;

    if (typeof values.pictureFile !== "undefined") {
      const file = await uploadMutation.mutateAsync({
        file: values.pictureFile as any,
        type: "file",
      });

      imageUrl = file.data.token;
    }

    createGroupMutation.mutate(
      {
        group_id: !isEdit ? undefined : activeGroupEditId,
        name: values.name,
        profile_image: imageUrl,
        active: values.active,
        concurrent_users_count: isShiftActive
          ? values.concurrentUsersCount
          : undefined,
        per_shift_hour:
          typeof values.perShiftHour === "undefined" ||
          isNaN(parseInt(values.perShiftHour)) ||
          !isShiftActive
            ? undefined
            : parseInt(values.perShiftHour),
        shift_start_time: isShiftActive ? values.shiftStartTime : undefined,
        shift_end_time: isShiftActive ? values.shiftEndTime : undefined,
        rows: isShiftActive
          ? (values.shifts?.map((shift: any) => ({
              user_ids: shift.userIds,
            })) as any)
          : [],
      },
      {
        onSuccess() {
          void settingGroupsQuery.refetch();
          void settingMyGroupsQuery.refetch();
          void usersQuery.refetch();
          void userQuery.refetch();

          toast("گروه جدید ایجاد شد", { type: "success" });

          handleClose();
        },
      }
    );
  };

  return (
    <Modal
      title={!isEdit ? "ایجاد گروه جدید" : "ویرایش گروه"}
      handleClose={handleClose}
      isOpened={isOpened}
      isShowCloseButton
    >
      <FormProvider {...methods}>
        <form
          className={styles.wrapper}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <div className={styles.generalFields}>
            <AvatarUploader />

            <div>
              <TextField
                placeholder="نام گروه"
                label="نام گروه"
                name="name"
                id="groupName"
              />
            </div>

            <div>
              <RHFSelect
                options={[
                  {
                    label: "فعال",
                    value: true,
                  },
                  {
                    label: "غیرفعال",
                    value: false,
                  },
                ]}
                placeholder="وضعیت"
                label="وضعیت"
                name="active"
                id="active"
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <Switch
              enabled={isShiftActive}
              handleSwitch={() => {
                setIsShiftActive((prevState) => !prevState);
              }}
            />

            <div style={{ width: 8 }} />

            <Typography variant="body1">دارای برنامه کاری</Typography>
          </div>

          {isShiftActive && (
            <>
              <div className={styles.furtherFields}>
                <div>
                  <TextField
                    type="number"
                    placeholder="شیفت چند ساعته"
                    label="شیفت چند ساعته"
                    name="perShiftHour"
                    id="perShiftHour"
                  />
                </div>

                <div>
                  <RHFTimePickerField
                    placeholder="شروع شیفت"
                    label="شروع شیفت"
                    name="shiftStartTime"
                    startFromZero
                  />
                </div>

                <div>
                  <RHFTimePickerField
                    placeholder="پایان شیفت"
                    label="پایان شیفت"
                    name="shiftEndTime"
                    startFromZero
                  />
                </div>

                <div>
                  <RHFSelect
                    name="usersCount"
                    options={Array(11)
                      .fill(null)
                      .map((_, index) => ({
                        label: `${index}`,
                        value: index,
                      }))}
                    label="تعداد کاربران"
                    placeholder="تعداد کاربران"
                    handleChange={(option) => {
                      methods.setValue(
                        "shifts",
                        Array(rowLength)
                          .fill(null)
                          .map(() => ({
                            userIds: Array(option.value)
                              .fill(null)
                              .map(() => -1),
                          }))
                      );
                    }}
                  />
                </div>

                <div>
                  <RHFSelect
                    name="concurrentUsersCount"
                    options={Array(11)
                      .fill(null)
                      .map((_, index) => ({
                        label: `${index}`,
                        value: index,
                      }))}
                    label="تعداد کاربر همزمان"
                    placeholder="تعداد کاربر همزمان"
                    handleChange={(option) => {
                      methods.setValue(
                        "shifts",
                        Array(option.value)
                          .fill(null)
                          .map(() => ({
                            userIds: Array(columnLength)
                              .fill(null)
                              .map(() => -1),
                          }))
                      );
                    }}
                  />
                </div>
              </div>

              {typeof columnLength === "number" &&
                typeof rowLength === "number" &&
                columnLength !== 0 &&
                rowLength !== 0 && (
                  <div className={styles.shifts}>
                    <Typography variant="subtitle2" component="h3">
                      اولین شیفت کاربران
                    </Typography>

                    {typeof activeGroupEditId === "number" && (
                      <ShiftList groupId={activeGroupEditId} />
                    )}
                  </div>
                )}
            </>
          )}

          <div className={styles.button}>
            <Button
              buttonSize="medium1"
              type="submit"
              color="info1"
              radius="high"
              loading={
                createGroupMutation.isLoading || uploadMutation.isLoading
              }
              disabled={
                createGroupMutation.isLoading || uploadMutation.isLoading
              }
            >
              {!isEdit ? "ایجاد گروه" : "ویرایش گروه"}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddGroup;
