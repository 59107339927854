import { ErrorMessage } from "@hookform/error-message";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { useSettingUsersQuery, useUserQuery } from "../../queries";
import ChooseBox from "../chooseBox";
import Typography from "../typography";

const ChooseUser = ({ multiple = true }: { multiple?: boolean }) => {
  const settingUsersQuery = useSettingUsersQuery({ limit: 10000, offset: 0 });

  const methods = useFormContext();
  const userQuery = useUserQuery();
  const groupsWatcher = methods.watch("activeGroupsId");
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "usersId",
  });

  useEffect(() => {
    methods.setValue("usersId", []);
  }, [groupsWatcher]);

  const filteredUsers =
    settingUsersQuery.data?.response.users?.filter((user) => {
      if (user.id === userQuery?.data?.user?.id) return false;

      let isValid: boolean = true;

      groupsWatcher.forEach((group: { value: number }) => {
        if (!isValid) return;

        isValid = user.groups.some((g) => g.id === group.value);
      });

      return isValid;
    }) ?? [];
  return (
    <div>
      {groupsWatcher.length >= 1 && filteredUsers.length < 1 && (
        <Typography variant="text1" textAlgin="center">
          کاربری در این گروه وجود ندارد
        </Typography>
      )}

      {groupsWatcher.length >= 1 ? (
        <ChooseBox
          items={
            filteredUsers.map((user) => ({
              id: user.id,
              name: user.name,
            })) ?? []
          }
          handleClick={(id) => {
            const findIndexField = fields.findIndex((i: any) => i.value === id);
            const hasId = findIndexField !== -1;

            methods.clearErrors("usersId");

            if (!multiple) {
              remove(0);
              append({ value: id });
              return;
            }

            if (!hasId) {
              append({ value: id });
              return;
            }

            remove(findIndexField);
          }}
          activeItemsId={fields.map((item: any) => item.value)}
        />
      ) : (
        <Typography variant="text1" textAlgin="center">
          برای نمایش کاربران ابتدا گروه را انتخاب کنید
        </Typography>
      )}

      <ErrorMessage
        errors={methods.formState.errors}
        name="usersId"
        render={({ message }) => (
          <div style={{ marginTop: 5 }}>
            <Typography variant="text1" color="danger1">
              {message}
            </Typography>
          </div>
        )}
      />
    </div>
  );
};

export default ChooseUser;
