import { useMutation } from "react-query";

import { uploadFilePostReq } from "../../requests";
import { type IUploadFilePostReq } from "../../requests/types";

const useUploadFileMutation = () => {
  return useMutation(
    async (variables: IUploadFilePostReq) => await uploadFilePostReq(variables)
  );
};

export default useUploadFileMutation;
