import { forwardRef } from "react";

import {
  AnnouncementIcon,
  CheckIcon,
  GroupIcon,
  ItemIcon,
  SettingsIcon,
  VacationIcon,
} from "../../icons";
import CountBadge from "../countBadge";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IMenuItemProps } from "./types";

const MenuItem = forwardRef(
  (
    { className = "", type, count, isActive, ...props }: IMenuItemProps,
    ref
  ) => {
    return (
      <div
        ref={ref as any}
        className={`${className} ${styles["menu-item"]} ${
          isActive ? `${styles["menu-item-active"]}` : ""
        }`}
        {...props}
      >
        <div className={styles.content}>
          <div className={styles["icon-wrapper"]}>
            {typeof count === "number" && (
              <div className={styles.countBadge}>
                <CountBadge color="pink1" count={count} notShowOnZeroValue />
              </div>
            )}

            {type === "item" && (
              <ItemIcon
                color={isActive ? "#2a3a65" : "#ffffff"}
                width="26"
                height="26"
              />
            )}
            {type === "announcement" && (
              <AnnouncementIcon
                color={isActive ? "#2a3a65" : "#ffffff"}
                width="26"
                height="26"
              />
            )}
            {type === "group" && (
              <GroupIcon
                color={isActive ? "#2a3a65" : "#ffffff"}
                width="26"
                height="26"
              />
            )}
            {type === "salary" && (
              <CheckIcon
                color={isActive ? "#2a3a65" : "#ffffff"}
                width="26"
                height="26"
              />
            )}
            {type === "vacation" && (
              <VacationIcon
                color={isActive ? "#2a3a65" : "#ffffff"}
                width="26"
                height="26"
              />
            )}
            {type === "setting" && (
              <SettingsIcon
                color={isActive ? "#2a3a65" : "#ffffff"}
                width="26"
                height="26"
              />
            )}
          </div>
          <Typography
            variant="subtitle2"
            color={isActive ? "dark1" : "white"}
            textAlgin="center"
          >
            {type === "item" && "موارد"}
            {type === "announcement" && "اعلانات"}
            {type === "group" && "گروه ها"}
            {type === "salary" && "برداشت"}
            {type === "vacation" && "مرخصی"}
            {type === "setting" && "تنظیمات"}
          </Typography>
        </div>
      </div>
    );
  }
);

export default MenuItem;
