import { forwardRef } from "react";
import { useQueryClient } from "react-query";

import { queryKeys } from "../../data";
import { useExpireAt } from "../../hooks";
import { LineIcon, NormalLine } from "../../icons";
import { normalizeDate } from "../../utils";
import Button from "../button";
import Typography from "../typography";
import styles from "./styles.module.scss";
import { type IDetailsAnnouncementCardProps } from "./types";

const DetailsAnnouncementCard = forwardRef(
  (
    {
      className = "",
      text,
      customers,
      time,
      isNew,
      hasExpiration,
      index,
      isExpired,
      isShowButtons,
      attributes,
      createdAt,
      notificationId,
      files,
      isEnd,
      voice,
      isSamePrev,
      handleClickExpirationTime,
      handleClickNew,
      handleClickNext,
      ...props
    }: IDetailsAnnouncementCardProps,
    ref
  ) => {
    const queryClient = useQueryClient();
    const expireAt = useExpireAt(
      time,
      () => {
        void queryClient.refetchQueries({ queryKey: queryKeys.notifications });
        void queryClient.refetchQueries({
          queryKey: [queryKeys.detailsNotification, notificationId],
        });
      },
      true
    );

    return (
      <div
        className={`${className} ${styles.wrapper}`}
        ref={ref as any}
        {...props}
      >
        <div className={styles.card}>
          <div className={styles.text}>
            {customers.length >= 1 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                {customers.map((user, index) => (
                  <div
                    key={index}
                    style={{
                      height: 28,
                      paddingLeft: 16,
                      paddingRight: 16,
                      background: "#82D6F7",
                      color: "#2A3A65",
                      fontSize: 14,
                      marginRight: index === 0 ? 0 : 13,
                      borderRadius: 25,
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {user}
                  </div>
                ))}
              </div>
            )}

            <Typography variant="body1" color="white" textAlgin="right">
              {isSamePrev && (
                <Typography
                  variant="body1"
                  component="span"
                  color="white"
                  style={{ fontWeight: "bold" }}
                  textAlgin="right"
                >
                  مرحله {index + 1}:
                </Typography>
              )}{" "}
              <Typography
                variant="body1"
                color="white"
                component="span"
                textAlgin="right"
                dangerouslySetInnerHTML={{
                  __html: text.replace(/\n/g, "<br />"),
                }}
              />
            </Typography>

            {attributes.length >= 1 && (
              <ul style={{ marginTop: 10, textAlign: "right" }}>
                {attributes.map((attr) => (
                  <Typography
                    variant="body2"
                    component="li"
                    color="white"
                    key={attr.key}
                  >
                    {attr.name}: {attr.value}
                  </Typography>
                ))}
              </ul>
            )}

            <Typography
              variant="body1"
              color="white"
              textAlgin="left"
              style={{ fontWeight: 200 }}
            >
              {normalizeDate(createdAt)}
            </Typography>
          </div>

          <div className={styles.buttons}>
            <div className={styles.files}>
              {files
                .filter((item) => typeof item === "string" && item !== "")
                .map((file, fileIndex) => (
                  <a
                    href={file}
                    key={fileIndex}
                    download={true}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button type="button" buttonSize="medium2" color="white">
                      فایل {typeof fileIndex === "number" ? fileIndex + 1 : ""}
                    </Button>
                  </a>
                ))}

              {typeof voice === "string" && (
                <a
                  href={voice}
                  download={true}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button type="button" buttonSize="medium2" color="white">
                    صدا
                  </Button>
                </a>
              )}
            </div>

            {isShowButtons && (
              <div className={styles.buttonsGeneral}>
                <Button
                  type="button"
                  buttonSize="medium2"
                  color="pink1"
                  onClick={handleClickExpirationTime}
                >
                  زمان
                </Button>

                <Button
                  type="button"
                  buttonSize="medium2"
                  color="gold1"
                  onClick={handleClickNext}
                >
                  بعدی
                </Button>

                <Button
                  type="button"
                  buttonSize="medium2"
                  color="info2"
                  onClick={handleClickNew}
                >
                  جدید
                </Button>
              </div>
            )}
          </div>
        </div>

        {isNew && (
          <div
            style={{
              display: "flex",
              transform: "translateX(-22px) translateY(3px)",
            }}
          >
            <NormalLine />
          </div>
        )}

        {hasExpiration && isEnd && !isExpired && (
          <div className={styles.info}>
            {expireAt !== "" && (
              <>
                <LineIcon className={styles.line} />

                <div
                  className={`${styles.badgeTime} ${
                    !isExpired ? styles.badgeTimeSuccess : ""
                  }`}
                >
                  {isExpired ? "منقضی" : "فعال"}
                </div>

                <div className={styles.infoContent}>
                  {hasExpiration &&
                  !isExpired &&
                  typeof time !== "undefined" ? (
                    <div className={styles.badgeFinishedTime}>
                      <div>
                        <Typography variant="text1">
                          زمان فعال بودن
                          <Typography
                            variant="text1"
                            component="span"
                            style={{
                              paddingRight: "10px",
                              width: 85,
                              display: "inline-block",
                            }}
                          >
                            {expireAt}
                          </Typography>
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <div style={{ width: 230 }} />
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default DetailsAnnouncementCard;
