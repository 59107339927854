import { Button, ChatBox, Typography } from "../../components";
import { useGlobalContext } from "../../context";
import { useChatUsersQuery } from "../../queries";
import styles from "./styles.module.scss";

const ChatsSidebar = () => {
  const { handleChangeSidebarType, handleSetActiveChatId } = useGlobalContext();
  const chatUsersQuery = useChatUsersQuery();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography component="h3" variant="title">
          چت با کاربران
        </Typography>

        <Button
          buttonSize="medium2"
          type="button"
          color="secondary1"
          radius="high"
          onClick={() => {
            handleChangeSidebarType("main");
          }}
        >
          بستن
        </Button>
      </div>

      <div className={styles.content}>
        {chatUsersQuery.data?.response.users.map((item) => (
          <ChatBox
            key={item.id.toString()}
            username={item.name}
            description={item?.group?.name ?? ""}
            isOnline={item.online}
            profile={item.profile}
            status={item.online ? undefined : item.last_online}
            unseenMessagesCount={item.unseen_messages_count}
            onClick={() => {
              handleSetActiveChatId(item.id);
              handleChangeSidebarType("details-chat");
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ChatsSidebar;
