import { forwardRef } from "react";

import Typography from "../typography";
import styles from "./styles.module.scss";
import { type ITableProps } from "./types";

const Table = forwardRef(
  ({ className = "", thList, children, ...props }: ITableProps, ref) => {
    return (
      <div className={styles.box}>
        <table
          className={`${className} ${styles.wrapper}`}
          ref={ref as any}
          {...props}
        >
          <thead>
            <tr>
              {thList.map((item, index) => {
                return (
                  <Typography component="th" variant="subtitle2" key={index}>
                    {item}
                  </Typography>
                );
              })}
            </tr>
          </thead>

          <tbody>{children}</tbody>
        </table>
      </div>
    );
  }
);

export default Table;
