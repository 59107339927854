import { useState } from "react";
import { type UseFormReturn } from "react-hook-form";

import { useUploadFileMutation } from "../queries";
import { type IUploadFilePostRes } from "../requests/types";

const useTextEditorUploader = (form: UseFormReturn) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const uploadFileMutation = useUploadFileMutation();

  const handleUpload = async () => {
    const { file1, file2, file3, file4, voice } = form.getValues();
    const fileList = [file1, file2, file3, file4].filter((f) => f !== null);

    if (fileList.length < 1 && voice === null) {
      return {
        file_1: null,
        file_2: null,
        file_3: null,
        file_4: null,
        voice: null,
      };
    }

    setIsUploading(true);

    const result = await Promise.all([
      ...fileList.map(async (file: any) => {
        return await uploadFileMutation.mutateAsync({
          file,
          type: "file",
        });
      }),
      voice === null
        ? undefined
        : uploadFileMutation.mutateAsync({
            file: voice as any,
            type: "voice",
          }),
    ]);
    const transferResult: IUploadFilePostRes[] = result.filter(
      (i) => typeof i !== "undefined"
    ) as any;

    setIsUploading(false);

    return {
      file_1: file1 === null ? null : transferResult?.[0]?.data ?? null,
      file_2: file1 === null ? null : transferResult?.[1]?.data ?? null,
      file_3: file1 === null ? null : transferResult?.[2]?.data ?? null,
      file_4: file1 === null ? null : transferResult?.[3]?.data ?? null,
      voice:
        voice === null
          ? null
          : transferResult?.[transferResult.length - 1]?.data ?? null,
    };
  };

  return {
    handleUpload,
    isUploading,
  };
};

export default useTextEditorUploader;
