import { useMutation } from "react-query";

import { referItemPostRequest } from "../../requests";
import { type IReferItemPostReq } from "../../requests/types";

const useReferItemMutation = () => {
  return useMutation(
    async (variables: IReferItemPostReq) =>
      await referItemPostRequest(variables)
  );
};

export default useReferItemMutation;
