import { useQuery } from "react-query";

import { queryKeys } from "../../../data";
import { notificationsGetRequest } from "../../../requests";
import { type INotificationsGetReq } from "../../../requests/types";

const useNotificationsQuery = (params: INotificationsGetReq) => {
  return useQuery(
    [queryKeys.notifications, params],
    async () => await notificationsGetRequest(params)
  );
};

export default useNotificationsQuery;
