import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";

import Typography from "../typography";
import styles from "./styles.module.scss";
import { type ITextFieldProps } from "./types";

const TextField = ({
  className = "",
  name,
  label,
  ...props
}: ITextFieldProps) => {
  const { register, formState } = useFormContext();

  return (
    <div className={styles.wrapper}>
      {typeof label === "string" && (
        <div className={styles.label}>
          <Typography variant="subtitle2" component="label" htmlFor={props.id}>
            {label}
          </Typography>
        </div>
      )}

      <input
        className={`${className} ${styles["text-field"]}`}
        {...props}
        {...register(name)}
      />

      <ErrorMessage
        errors={formState.errors}
        name={name}
        render={({ message }) => (
          <Typography variant="text1" className={styles.error} color="danger1">
            {message}
          </Typography>
        )}
      />
    </div>
  );
};

export default TextField;
