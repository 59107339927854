import { useMutation } from "react-query";

import { sendMessageChatPostReq } from "../../requests";
import { type ISendMessageChatPostReq } from "../../requests/types";

const useChatSendMessageMutation = () => {
  return useMutation(
    async (variables: ISendMessageChatPostReq) =>
      await sendMessageChatPostReq(variables)
  );
};

export default useChatSendMessageMutation;
